import { Component, OnInit } from '@angular/core';

import { Agency } from './agency.model';
import { AgencyService } from './agency.service';


@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.component.html'
})

export class AgenciesComponent implements OnInit {

  agencies: Agency[]
  filterQuery = '';

  constructor( private agencyService: AgencyService) { }

  ngOnInit() {

      this.agencyService.agency()
        .subscribe(agencies => {this.agencies = agencies})
  }

}
