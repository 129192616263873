<div class="row">
  <div class="col-lg-12 col-md-12 animated fadeIn">
    <div class="card">
      <div class="card-header">
        <i class="fa fa-edit"></i> Peças 
        <a [routerLink]="['register']" class="btn btn-primary pull-right">Adicionar</a>
      </div>
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-md-4 offset-md-8">
            <div class="input-group center">
              <span class="input-group-addon"><i class="fa fa-search"></i></span>
              <input type="text" class="form-control" [(ngModel)]="filterQuery" placeholder="Buscar..."/>
            </div>
          </div>
        </div>
        <table class="table table-striped table-bordered datatable" [mfData]="models | appFilter : filterQuery" #mf="mfDataTable" [mfRowsOnPage]="10">
          <thead>
            <tr>

              <th style="width: 20%">
                <mfDefaultSorter by="name">Formato</mfDefaultSorter>
              </th>

              <th style="width: 20%">
                  <mfDefaultSorter by="interval">Intervalo</mfDefaultSorter>
              </th>

              <th style="width: 20%">
                <mfDefaultSorter by="channel.fantasyName">Veículo</mfDefaultSorter>
              </th>

              <th style="width: 10%">
                  <mfDefaultSorter by="price">Preço</mfDefaultSorter>
              </th>

              <th style="width: 10%">Status</th>

              <th style="width: 10%">Opções</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of mf.data">

              <td>{{item.name}}</td>

              <td>{{item.interval}}</td>

              <td>{{item.channel.fantasyName}}</td>

              <td><input readonly currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="item.price"></td>

              <td>
                <span *ngIf="item.active===true" class="badge badge-success">Ativo</span>
                <span *ngIf="item.active===false" class="badge badge-dark">Inativo</span>
              </td>

              <td>
                <a [routerLink]="['edit', item._id]" title="Editar Peça" ><span class="btn btn-primary"><i class="fa fa-edit"></i></span> </a>
              </td>

            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="8">
                <mfBootstrapPaginator [rowsOnPageSet]="[5,10,15]"></mfBootstrapPaginator>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>