import { Component, OnInit } from '@angular/core';
import { UserService } from './user.service';
import { UserComponent } from './user.component';
import { ToasterService } from '../toaster-service.service';

@Component({
    selector: 'user-list',
    templateUrl: './user-list.component.html'
})

export class UserListComponent implements OnInit {

    users: UserComponent[] = [];
    headers: Headers


    filterQuery = '';

        constructor(private service: UserService, private toasterService: ToasterService) {}

        ngOnInit() {

            this.service.get().subscribe(res => {
                this.users = res;
            }, error => console.log(error));

        }

        changeStatus(id: string, status: boolean) {

            this.service
                .changeStatus(id, status)
                    .subscribe(result => {
              
                        for (let user of this.users) {

                            if(user._id == id)
                                user.active = !status;
                        }

                        this.success();
        
                        }, error => this.error(JSON.parse(error._body).map(m => m.message).join('<br>')));
        }

        success() {

            this.toasterService.success('', 'Cadastro atualizado com sucesso.');
        }
        
        error(message?: string) {
            
            this.toasterService.error('', `Ops!</br> ${message}`);
        }
    
}