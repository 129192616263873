
<div class="row">
  <div class="form-group col-lg-2">      
    <app-input errorMessage="Campo obrigatório" label="">
      <select id="months" name="months" required [ngModel]="numberMonth" (ngModelChange)="placementMonth($event)" class="form-control input-lg" size="1">
          <option selected="selected" [ngValue]="0">Selecione</option>
          <option *ngFor= "let month of months" [ngValue]="month.value">{{month.label}}</option>
        </select>
    </app-input>
  </div>
  <div class="form-group col-lg-2">      
    <app-input errorMessage="Campo obrigatório" label="">
      <select id="years" name="years" required [ngModel]="numberYear" (ngModelChange)="placementYear($event)" class="form-control input-lg" size="1">
          <option selected="selected" [ngValue]="0">Selecione</option>
          <option *ngFor= "let year of years" [ngValue]="year.label">{{year.label}}</option>
        </select>
    </app-input>
  </div>
</div>

<div class="row table-wrapper">
  <table class="table table-striped table-bordered" [mfData]="models" #mfCalendar="mfDataTable" [mfRowsOnPage]="10">
    <thead style="font-size: 10px">
      <tr>
        <th class="table-th-wrapper" rowspan="2">
          <span>Peça(s)</span>
        </th>
        <th class="table-th-wrapper"  
          [ngStyle]="{'background-color':getDayOfWeek(day) === 'Dom' || getDayOfWeek(day) === 'Sab' ? '#eceef1' : '#fff' }"  
          *ngFor = "let day of createTableHeader()">
          {{ getDayOfWeek(day) }} 
        </th>
        <th class="table-th-wrapper" rowspan="2"><span>Total <br> Inserção</span></th>
        <th class="table-th-wrapper" rowspan="2"><span>Valor <br> Unit.</span></th>
        <th class="table-th-wrapper" rowspan="2"><span>% Desc.</span></th>
        <th class="table-th-wrapper" rowspan="2"><span>Valor <br> Total</span></th>
      </tr>
      <tr>
        <th class="table-th-wrapper" *ngFor = "let day of createTableHeader()">
          {{day}}  
        </th>
      </tr>
    </thead>
    <tbody style="font-size: 10px !important">
       <tr *ngFor="let item of mfCalendar.data; let i = index">
        <td style="text-align: center; vertical-align: middle">
          {{item.ordem}}
        </td>              
        <td class="table-td-wrapper" *ngFor="let d of item.calendar; let x = index">
          <input size="2" height="5" maxlength="2" type="text" id="{{item._id}}.{{i}}.{{d.day}}" name="{{i}}.day.{{x}}.{{d.day}}" [(ngModel)]= "d.day" (ngModelChange)="onQuantity(item)">
        </td>
        <td class="table-td-wrapper">
          {{item?.days}}
        </td>
        <td class="table-td-wrapper">
          <input *ngIf="type && type === 'Rádio'"  name="{{item._id}}.{{i}}.price" maxlength="13" size="11" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" [(ngModel)]="item.price" (ngModelChange)="onQuantity(item)">
          <input *ngIf="type && type === 'Revista'"  name="{{item._id}}.{{i}}.price" maxlength="13" size="11" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" [ngModel]="item?.price" (ngModelChange)="onQuantity(item)">
          <input *ngIf="type && type === 'Jornal'"  name="{{item._id}}.{{i}}.unitPrice" maxlength="13" size="11" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" [(ngModel)]="item.unitPrice" (ngModelChange)="onQuantity(item)">
          <input *ngIf="type && type === 'OOH'"  name="{{item._id}}.{{i}}.unitPrice" maxlength="13" size="11" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" [ngModel]="item?.unitPrice" (ngModelChange)="onQuantity(item)">
        </td>
        <td class="table-td-wrapper">
          <input name="{{item._id}}.{{i}}.discount" maxlength="3" size="3" [(ngModel)]="item.discount" (ngModelChange)="onQuantity(item)">
        </td>
        <td class="table-td-wrapper">
          {{item?.total | currency:'BRL':'symbol-narrow'}}
        </td>
      </tr> 
    </tbody> 
  </table> 
</div>
