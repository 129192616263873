import { Component } from '@angular/core';
import { UserComponent } from './user.component';
import { UserService } from './user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../toaster-service.service';

@Component({
    selector: 'app-user-register',
    templateUrl: './user-register.component.html'
})

export class UserRegisterComponent {
    user: UserComponent = new UserComponent();
    userForm: FormGroup;
    service: UserService;
    route: ActivatedRoute;
    router: Router;

    constructor(service: UserService, fb: FormBuilder, route: ActivatedRoute, router: Router, private toasterService: ToasterService) {

        this.service = service;
        this.route = route;
        this.router = router;

    }

    register(){
   
        this.service.register(this.user)
            .subscribe(()=> {

                this.success();
                this.user = new UserComponent();
                this.router.navigate(['users']);

            }, error => this.error(JSON.parse(error._body).map(m => m.message).join('<br>')));

    }

    success() {

        this.toasterService.success('', 'Usuário cadastrado com sucesso!');
    }

    error(message?: string) {

        this.toasterService.error('', `Ops!</br> ${message}`);
    }
}
