import { Component, OnInit, Input, ContentChild, AfterContentInit  } from '@angular/core';
import { NgModel } from '@angular/forms';


@Component({
  selector: 'app-input',
  templateUrl: './input.component.html'
})
export class InputComponent implements OnInit, AfterContentInit {

  @Input() label: string
  @Input() errorMessage: string
  @Input() isSearch: boolean = false

  input: any

  @ContentChild(NgModel, {static:true}) model: NgModel

  constructor() { }

  ngOnInit() {
  }

  ngAfterContentInit() {

    this.input = this.model
    if (this.input === undefined) {
      throw new Error('Este componente precisa ser usado como ngModel');
    }

  }


  hasSucess(): boolean {

    return this.input.valid && (this.input.dirty || this.input.touched) && this.isSearch === false;
  }

  hasError(): boolean {
    return this.input.invalid && (this.input.dirty || this.input.touched) && this.isSearch === false;
  }

}
