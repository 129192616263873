
  <div class="col-sm-4" style="margin: 0 auto;padding-top: 10%">
    <div class="card">
      <div class="card-header">
        <img [src]="logo" class="figure-img img-fluid rounded" alt="Logo do sistema.">
      </div>
      <div class="card-body">
        <form novalidate #form="ngForm">
          <div class="form-group">
            <label for="password">Senha</label>
            <div class="input-group">
              <input type="password" [ngModel]="password" name="password" required minlength="6" maxlength="12" class="form-control" placeholder="Entre com a senha">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fa fa-asterisk"></i></span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="confirmedPassword">Confirmação Senha</label>
            <div class="input-group">
              <input type="password" [ngModel]="confirmedPassword" name="confirmedPassword" required required minlength="6" maxlength="12" class="form-control" placeholder="Entre com a confirmação de senha">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fa fa-asterisk"></i></span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <button title="Redefinir senha" (click)= "updatePassword(form.value)" [disabled]='!form.valid' class="btn btn-lg btn-primary form-control">Confirmar senha</button>
          </div>
        </form>
        <div class="row">
          <a [routerLink] = "['/login']" class="btn btn-default">Voltar para o login</a>
        </div>
      </div>
    </div>
  </div>
