
<div class="col-sm-4" style="margin: 0 auto; padding-top: 10%">
  <div class="card">
    <div class="card-header">
        <img [src]="logo" class="figure-img img-fluid rounded" alt="Logo do sistema.">
    </div>
    <div class="card-body">    
      <form novalidate #form="ngForm">
        <div class="form-group">
          <label for="email">E-mail</label>
          <div class="input-group">
            <input type="email" name="email" [ngModel]="login?.email" required pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$" class="form-control">
            <div class="input-group-append">
              <span class="input-group-text"><i class="fa fa-envelope"></i></span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="password">Senha</label>
          <div class="input-group">
            <input type="password" [ngModel]="login?.password" name="password" required class="form-control" placeholder="Entre com a senha">
            <div class="input-group-append">
              <span class="input-group-text"><i class="fa fa-asterisk"></i></span>
            </div>
          </div>
        </div>
        <div class="form-group">
            <button title="Entrar no sistema" (click)="authUser(form.value)" [disabled]="!form.valid" class="btn btn-lg btn-primary form-control">Login</button>
        </div>
      </form>
      <div class="row">
        <a [routerLink] = "['/reset-password']" class="btn btn-default">Esqueci a senha</a>
      </div>
    </div>
  </div>
  </div>