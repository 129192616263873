
<div class="row">
    <button title="filtro" (click)="animateOrder()" class="btn btn-info ">
        <i class="fa fa fa-filter"></i>
    </button>
</div>
<div class="card filter-toggle" [@animateFilter]='state'>
    <div>
        <form novalidate #orderForm="ngForm" name="frmFilterOrder" >
            <div class="row">
                <div class="form-group col-lg-3">    
                    <app-input errorMessage="" label="Data de emissão" isSearch="true">             
                        <input type="date" class="form-control" name="dateOfIssueOn" [(ngModel)]="appFilterSearch.dateOfIssueOn" autocomplete="off" placeholder="Início: ">              
                    </app-input>
                </div>
                <div class="form-group col-lg-3">    
                    <app-input errorMessage="" label="Data de emissão" isSearch="true">             
                        <input type="date" class="form-control" name="dateOfIssueEnd" [(ngModel)]="appFilterSearch.dateOfIssueEnd" autocomplete="off" placeholder="Fim: ">              
                    </app-input>
                </div>
                <div class="form-group col-lg-2">                 
                    <app-input errorMessage="" label="Pedido de inserção" isSearch="true">
                        <input type="text" class="form-control" name="numberOrder" [(ngModel)]="appFilterSearch.numberOrder" autocomplete="off">              
                    </app-input>
                </div>


                <!--Clientes-->
                <div class="form-group col-lg-4">
                    <app-input errorMessage="" label="Clientes" isSearch="true">
                        <select name="customerId" required [ngModel]="appFilterSearch.customerId" (ngModelChange)="onChangeCustomer($event)" class="form-control input-lg" size="1">
                            <option selected="selected" [ngValue]="''">Selecione</option>
                            <option *ngFor="let c of customers" [ngValue]="c._id">
                                {{c.fantasyName}} - {{c.state}}
                            </option>
                        </select>
                    </app-input>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-2">
                    <app-input errorMessage="" label="Tipo de PI" isSearch="true">
                        <select id="typeOrder" name="type" [(ngModel)]="appFilterSearch.type" (ngModelChange)= "onChangeTypeOrder($event)" class="form-control input-lg" size="1">
                            <option selected="selected" [ngValue]="''">Selecione</option>
                            <option [ngValue]="1">P.I. - Comissionado</option>
                            <option [ngValue]="2">P.I. - Faturar</option>
                        </select>
                    </app-input>
                </div>

                <!-- Tipo de veiculos -->
                <div class="form-group col-lg-2">
                    <app-input errorMessage="" label="Tipo de veículo" isSearch="true">
                        <select id="channelType" name="channelType" [(ngModel)]="appFilterSearch.channelType" (ngModelChange)= "onChangeTypeChannel($event)" class="form-control input-lg" size="1">
                            <option selected="selected" [ngValue]="''">Selecione</option>
                            <option [ngValue]="'Jornal'">Jornal</option>
                            <option [ngValue]="'Rádio'">Rádio</option>
                            <option [ngValue]="'Revista'">Revista</option>
                            <option [ngValue]="'OOH'">OOH</option>
                        </select>
                    </app-input>
                </div>  

                <!--Agências-->
                <div class="form-group col-lg-4">
                    <app-input errorMessage="" label="Agência" isSearch="true">
                        <select name="agencyId" required [ngModel]="appFilterSearch.agencyId" (ngModelChange)="onChangeAgency($event)" class="form-control input-lg" size="1">
                            <option selected="selected" [ngValue]="''">Selecione</option>
                            <option *ngFor="let agency of agencies" [ngValue]="agency?._id">{{agency.fantasyName}}</option>
                        </select>
                    </app-input>
                </div>

                <!--Veículos-->
                <div class="form-group col-lg-4">
                    <app-input errorMessage="" label="Veículos" isSearch="true">
                        <select name="channelId" required [ngModel]="appFilterSearch.channelId" (ngModelChange)="onChangeChannel($event)" class="form-control input-lg" size="1">
                            <option selected="selected" [ngValue]="''">Selecione</option>
                            <option *ngFor="let channel of channels" [ngValue]="channel?._id">
                                 {{channel.fantasyName}} - {{channel.city}} - {{channel.state}}
                            </option>
                        </select>
                    </app-input>
                </div>

            </div>
            
        </form>

        <div class="row pull-right">
            <button title="Pesquisar" (click)="onFind(orderForm.value)" class="btn btn-primary">
                Pesquisar
            </button>
        </div>
        <div class="row pull-right">
            <button title="Limpar" (click)="onclear(orderForm)" class="btn btn-success">
                Limpar
            </button>
        </div>
    </div>
</div>


