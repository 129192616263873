import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { typeOrder } from './app-filter-search-enum';

import {AppFilterSearch} from './app-filter-search.model'
import { Customer } from '../../customers/customer.model';
import { Channel } from '../../channels/channel.model';
import { Agency } from '../../agencies/agency.model';
import { ChannelService } from '../../channels/channel.service';
import { CustomerService } from '../../customers/customer.service';
import { AgencyService } from '../../agencies/agency.service';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-filter-search',
  templateUrl: './app-filter-search.component.html',
  styleUrls: ['./app-filter-search.component.scss'],
  animations: [
    trigger('animateFilter',[
      state('small', style({height : '0px', border: '1px solid #fff'})),
      state('large', style({height : '300px', border: '1px solid #e1e6ef' })),

      transition('small <=> large', animate('400ms ease-in'))
    ])
  ]
})
export class AppFilterSearchComponent implements OnInit {

  customers: Customer[]
  channels: Channel[]
  agencies: Agency[]
  state: string = 'small'
  appFilterSearch: AppFilterSearch


  @Output() changeFilter = new EventEmitter<AppFilterSearch>()

  constructor(private channelService: ChannelService,
              private customeService: CustomerService,
              private agencyService: AgencyService) { }

  ngOnInit() {

    this.listChannels()
    this.listCustomers()
    this.listAgencies()

    this.appFilterSearch = {
      dateOfIssueOn:null, 
      dateOfIssueEnd:null, 
      expirationDateOn: null,
      expirationDateEnd: null,
      channelId:null, 
      customerId:null,
      numberOrder:null, 
      companyId:null, 
      agencyId:null, 
      channelType:null, 
      type:null
    }
  }

  
  listChannels() {
    this.channelService
        .get()
        .subscribe(channels => this.channels = channels)
  }

  listCustomers() {
    this.customeService
        .customer()
        .subscribe(customers => this.customers = customers)
  }

  listAgencies() {
    this.agencyService
        .agency()
        .subscribe(agencies => this.agencies = agencies)
  }


  onChangeAgency(id: string){
    this.appFilterSearch.agencyId = id
  }

  onChangeTypeOrder(event) {

    switch(event) {
      case 1:
        this.appFilterSearch.type = typeOrder.Comissionado
        break;
      case 2:
        this.appFilterSearch.type = typeOrder.Faturar
        break;
    }
  }

  onChangeTypeChannel(event) {

    this.appFilterSearch.channelType = event
  }

  onChangeChannel(id:string) {
    this.appFilterSearch.channelId = id
  }

  onChangeCustomer(id:string) {
    this.appFilterSearch.customerId = id
  }

  animateOrder() {
    this.state = (this.state === 'small'? 'large':'small')
  }

  onFind(form: AppFilterSearch ) {
    
    this.changeFilter.emit(form)
  }

  onclear(form: NgForm) {
      form.resetForm();
  }

}
