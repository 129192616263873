import { Input, Directive } from '@angular/core';


@Directive()
export class Order {

    _id: string
    @Input() numberOrder: string;
    @Input() companyFantasyName: string;
    @Input() dateOfIssue: string;
    @Input() placementMonth: number;
    @Input() placementYear: number;
    @Input() expirationDate: string;
    @Input() companyId: string;
    @Input() agencyId: string;
    @Input() agencyFantasyName: string;
    @Input() agencyEnd: string;
    @Input() agencyIe: string;
    @Input() agencyCnpj: string;
    @Input() agencyRazaoSocial: string;
    @Input() revenuesObservation: string;
    @Input() observation: string;
    @Input() vComissao: number;
    @Input() vComissaoPercent: number;
    @Input() vLiqRadio: number;
    @Input() vTotalBruto: number;
    @Input() customerId: string;
    @Input() customerFantasyName: string;
    @Input() customerSocialName: string;
    @Input() customerEnd: string;
    @Input() customerIe: string;
    @Input() customerCnpj: string;
    @Input() customerCampaign: string;
    @Input() channelId: string;
    @Input() channelType: string;
    @Input() channelFantasyName: string;
    @Input() models: any[];
    @Input() vComissaoAgency: number;
    @Input() vLiqFaturar: number;
    @Input() vComissaoPercentAgency: number;
    @Input() type: number;
    @Input() billedCompany: boolean;
    @Input() extraComission: number;
    @Input() userId: string;

}
