
import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';

import { AgencyService } from './../../../agencies/agency.service';
import { Agency } from './../../../agencies/agency.model';



@Component({
  selector: 'app-order-agency',
  templateUrl: './order-agency.component.html'
})
export class OrderAgencyComponent implements OnChanges {

  agencies: Agency[]
  agency: Agency
  @Input() agencyId: string
  @Output() updateAgency = new EventEmitter<Agency>()

  constructor(private agencyService: AgencyService) { }


  ngOnChanges(change: SimpleChanges) {

    this.agencyService
      .agency()
      .subscribe(agencies => {
        this.agencies = agencies
        if (change.agencyId.currentValue) {
          this.getAgencyById(this.agencyId)
        }
      })
  }

  change(id: string) {

    this.getAgencyById(id)
  }

  getAgencyById(id: string) {

    this.agencies
      .filter(agency => agency._id === id)
      .map( (agency) => {
        this.agency = agency
        this.updateAgency.emit(agency)
      })
  }

}
