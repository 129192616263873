
<div class="row">
  <div class="col-lg-10 col-md-10">
    <div class="card">
      <div class="card-body">
          <nav aria-label="breadcrumb" role="navigation">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [routerLink] = "['/users']">Usuários</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Editar</li>
              </ol>
            </nav>
      </div>
      <div class="card-header">
        <strong>Usuário</strong>
        <small>Edição</small>
      </div>
      <div class="card-body">    

        <form novalidate #form="ngForm" (submit)="edit()">

        <div class="row">
          <div class="form-group col-sm-6 ">
            <app-input errorMessage="Nome inválido." label="Nome completo" > 
                <input type="text" class="form-control" [(ngModel)]="user.name" name="name" placeholder="Preencha o nome completo." required minlength="3" maxlength="256">
            </app-input>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-sm-6">
            <app-input errorMessage="E-mail inválido." label="E-mail"> 
                <input type="text" name="email" class="form-control" [(ngModel)]="user.email" id="email"  size="16" placeholder="Ex: nome@email.com.br" required minlength="3" maxlength="256">
            </app-input>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-sm-4">
              <app-input errorMessage="Perfil inválido." label="Perfil"> 
                <select  name="role" [(ngModel)]="user.role" class="col-md-6 form-control" id="role" name="role" required>
                    <option value="Padrão">Padrão</option>
                    <option value="Administrador">Administrador</option>
                </select>
            </app-input>
          </div>        

          <div class="form-group col-sm-2">
            <label class="control-label" for="active">Status</label>
            <select id="active" name="active" [(ngModel)]="user.active" class="form-control input-lg" size="1">
              <option [ngValue]="true">Ativo</option>
              <option [ngValue]="false">Inativo</option>
            </select>
          </div>

        </div>
        
        <div class="form-actions">
          <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Salvar</button>
          <a [routerLink]="['', 'users']" class="btn btn-default">Voltar</a>
        </div>

        </form>

      </div>
    </div>
  </div>
</div>