import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class GuardRouterConfirmedPassword implements CanActivate {

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        if (route.queryParams['token']) {
            const tokenConfirmed = route.queryParams['token']
            localStorage.setItem('tokenConfirmedPassword', tokenConfirmed)
            return true
        }

        this.router.navigate(['login'])
        return false
    }


}
