import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import { HOST_API } from '../app.api';
import { Supplier } from './supplier.model'

@Injectable()
export class SupplierService {

    suppliers: Supplier[] = []

    constructor(private http: HttpClient) {}

    supplier(): Observable<Supplier[]> {

        return this.http.get<Supplier[]>(`${HOST_API}/suppliers` )
    }

    create(supplier: Supplier): Observable<string> {

        return this.http.post<string>(`${HOST_API}/suppliers`, supplier)
    }

    supplierById(id: string): Observable<Supplier> {

        return this.http.get<Supplier>(`${HOST_API}/suppliers/${id}`)
    }

    update(supplier: Supplier): Observable<string> {

        return this.http.put<string>(`${HOST_API}/suppliers/${supplier._id}`, supplier)        
    }


    clear() {
        this.suppliers = []
    }

}
