import { OrderService } from './../../order.service';
import { ModelComponent } from './../../../model/model.component';
import { ModelService } from './../../../model/model.service';
import { ChannelService } from './../../../channels/channel.service';
import { Channel } from './../../../channels/channel.model';

import { Component, OnInit, Output, EventEmitter, TemplateRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { OrderChannelService } from './order-channel.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { SelectModel } from '../../../shared/select-uf/select.model';

@Component({
  selector: 'app-order-channel',
  templateUrl: './order-channel.component.html'
})
export class OrderChannelComponent implements OnInit, OnChanges {

  channels: Observable<Channel[]>
  channel: Channel
  models: Observable<ModelComponent[]>
  model: ModelComponent
  type: String = ''
  states: any
  state: String = ''
  channelId: string

  @Input() orderId: string
  @Output() increaseModel = new EventEmitter<ModelComponent[]>()
  @Output() decreaseModel = new EventEmitter<ModelComponent>()
  @Output() getType = new EventEmitter()
  @Output() updateChannel = new EventEmitter<Channel>()
  @Output() changeNameModel = new EventEmitter<ModelComponent>()

  modalRef: BsModalRef

  constructor(private channelService: ChannelService,
              private modelService: ModelService,
              public orderChannelService: OrderChannelService,
              private modalService: BsModalService,
              private orderService: OrderService) { }

  ngOnInit() {

    this.states = SelectModel
  }

  ngOnChanges(change: SimpleChanges) {

    if (change.orderId.currentValue) {

      this.orderService
        .getById(this.orderId)
        .subscribe(order => {
          this.channelService
            .channelById(order.channelId)
            .subscribe(channel => {
              this.changeUf(channel.state)
              this.type = channel.type
              this.channel = channel
              this.getType.emit(this.type)
              this.updateChannel.emit(channel)
              this.channelId = channel._id
              this.orderChannelService.addChannel(channel)
              this.models = this.modelService.getByChannelId(channel._id)
              this.orderChannelService.addListModels(order.models)
          })
        })
    }

  }

  openModal(template: TemplateRef<any>) {
    
    this.modalRef = this.modalService.show(template)
  }


  changeChannel(id: string) {
    this.channelService
      .channelById(id)
      .subscribe(channel => {
        this.type = channel.type
        this.getType.emit(this.type)
        this.updateChannel.emit(channel)
        this.channelId = channel._id
        this.orderChannelService.addChannel(channel)
        this.models = this.modelService.getByChannelId(channel._id)
      })
  }

  changeModel(model: ModelComponent) {

    this.model = model
  }

  changeUf(state: string) {

    this.state = state
    this.channels = this.channelService.channelByState(this.state)
  }

  addModel() {

    this.orderChannelService.addModel(this.model)
    this.orderChannelService.updateAlphaOrdem()
    this.increaseModel.emit(this.orderChannelService.listModels())
  }

  removeModel(model: ModelComponent) {

    this.orderChannelService.removeModel(model)
    this.orderChannelService.updateAlphaOrdem()
    this.decreaseModel.emit(model)
    this.increaseModel.emit(this.orderChannelService.listModels())

  }

  changeListModel(change: String) {

    if (change.length > 0 && change === 'sucesso') {
      this.models = this.modelService.getByChannelId(this.channelId)
    }
  }

  changeName(model: ModelComponent) {
    this.changeNameModel.emit(model)
  }

}
