import { HOST_API } from './../app.api';
import { HttpClient } from '@angular/common/http';
import { ModelComponent } from './model.component';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';


@Injectable()
export class ModelService {

    http: HttpClient;
    models: ModelComponent[];

    constructor(http: HttpClient) {

        this.http = http;
    }


    get(): Observable<ModelComponent[]> {

        return this.http.get<ModelComponent[]>(`${HOST_API}/models`)
    }

    getById(id: string):  Observable<ModelComponent> {

        return this.http.get<ModelComponent>(`${HOST_API}/models/${id}` )
    }

    getByChannelId(channelId: string): Observable<ModelComponent[]> {

        return this.http.get<ModelComponent[]>(`${HOST_API}/models/channel/${channelId}`)
    }

    register(model: ModelComponent): Observable<string> {

        console.log('teste2')
        return this.http.post<string>(`${HOST_API}/models`, model);
    }

    update(model: ModelComponent): Observable<string> {

        return this.http.put<string>(`${HOST_API}/models/${model._id}` , model);
    }

    changeStatus(id: string, status: boolean) {

        return this.http.put<string>( `${HOST_API}/models/changeStatus/${id}`, ({ active: !status }));
    }

    delete(id: string): Observable<string> {

        return this.http.delete<string>(`${HOST_API}/models/${id}`);
    }

    formatField(valor: any) {

        return (valor) ? valor.toString().trim().replace(',', '.') : null;
    }

    calculateUnitPrice(model: ModelComponent, type: String) {

        if (type === 'Jornal') {

            if (model.height > 0 && model.columns > 0 && model.price > 0) {

                return model.unitPrice = (model.price * (parseFloat(model.columns) * parseFloat(model.height)))
            } else {

                return model.unitPrice = 0.00;
            }

        } else if (type === 'OOH') {

            if (model.minimumCoverage > 0 && model.price > 0) {

                return model.unitPrice = model.price * model.minimumCoverage;

            } else {

                return model.unitPrice = 0.00;
            }

        } else {

            return model.unitPrice = 0.00;
        }

    }


    onGetTotalValue(models: ModelComponent[]) {
        return models
                .filter(x => x.total !== undefined)
                .map(x => x.total)
                .reduce((v, x) => v + x)

    }

    onUpdateCalendar(model: ModelComponent) {

        return model.calendar
            .map(day => parseInt(day['day'], 0))
            .reduce((q, day) => q + day)

    }

    onGetModelTotal(type: string, item: ModelComponent) {

        item.discountValue =  (item.discountValue > 0 ? item.discountValue : 0 )

        if (type === 'Rádio' || type === 'Revista') {

            return (item.days * item.price) - item.discountValue
        } else if (type === 'Jornal' || type === 'OOH') {

            return (item.days * item.unitPrice) - item.discountValue
        }

    }

    onGetDiscountValue(type: string, item: ModelComponent): number {

        if (type === 'Rádio' || type === 'Revista') {

            return (item.discount / 100) * (item.days * item.price)
        } else if (type === 'Jornal' || type === 'OOH') {

            return (item.discount / 100) * (item.days * item.unitPrice)
        }
    }

// tslint:disable-next-line:eofline
}