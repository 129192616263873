import { Component, OnInit } from '@angular/core';

import { Customer } from './customer.model';
import { CustomerService } from './customer.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html'
})
export class CustomersComponent implements OnInit {

  customers: Customer[]
  filterQuery = '';

  constructor(private customerService: CustomerService) { }

  ngOnInit() {

    this.customerService.customer()
      .subscribe(customers => {this.customers = customers}, error => console.log(error))

  }

}
