<div class="row">
    <div class="col-lg-10 col-md-10">
      <div class="card">

          <div class="card-body">
              <nav aria-label="breadcrumb" role="navigation">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink] = "['/users']">Usuários</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Alteração de senha</li>
                  </ol>
                </nav>
          </div>
          
          <div class="card-header">
            <strong>Usuário</strong>
            <small>Alteração de senha</small>
          </div>

        <div class="card-body">
          <form novalidate #form="ngForm"  class="row" (submit)="changePass()" class="form-horizontal">
          <div class="row">
            <div class="form-group col-sm-6">
                <app-input errorMessage="Senha inválida." label="Senha" > 
                  <input type="password" name="password" [(ngModel)]="user.password" id="password" class="col-md-6 form-control" size="16" required minlength="6" maxlength="12" >
                </app-input>
              </div>
          </div>
  
          <div class="row">
            <div class="form-group col-sm-6">
              <app-input errorMessage="Confirmação de senha inválida." label="Confirmação de senha"> 
                  <input type="password" id="confirmPassword" class="col-md-6 form-control" name="confirmPassword" [(ngModel)]="user.confirmPassword" size="16" required minlength="6" maxlength="12">
              </app-input>
            </div>
          </div>

          <div class="form-actions">
            <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Salvar</button>
            <a [routerLink]="['', 'users']" class="btn btn-default">Voltar</a>
          </div>
        </form>
        </div>
      </div>
    </div>
  </div>