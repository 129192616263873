import { Router, ActivatedRoute, NavigationEnd, Params } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Breadcrumb } from './breadcrumb';

import { filter, map} from 'rxjs/operators'

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  encapsulation: ViewEncapsulation.None
})

export class BreadcrumbComponent implements OnInit {

    newBreadcrumbs: Array<Breadcrumb> = []

    breadcrumbs$ = this.router.events
            .pipe (
                    filter(event => event instanceof NavigationEnd),
                    map(event => this.buildBreadCrumb(this.activatedRoute.root))
                )

    constructor(private activatedRoute: ActivatedRoute,
                private router:  Router) {}

    ngOnInit() {}

    buildBreadCrumb(route: ActivatedRoute, url: string = '',
                        breadcrumbs: Array<Breadcrumb> = []): Array<Breadcrumb> {

        const label = route.routeConfig ? route.routeConfig.data[ 'breadcrumb' ] : 'Dasboard';
        const path = route.routeConfig ? route.routeConfig.path : '';

        const nextUrl = `${url}${path}/`;

        const breadcrumb = {
            label: label,
            url: nextUrl
        };

        if (nextUrl !== '//') {
            this.newBreadcrumbs = [ ...breadcrumbs, breadcrumb];
        }

        if (route.firstChild) {
            return this.buildBreadCrumb(route.firstChild, nextUrl, this.newBreadcrumbs);
        }

        return this.newBreadcrumbs;
    }

}
