import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ToasterService } from '../../toaster-service.service';
import { Router, ActivatedRoute } from '@angular/router';

import { Supplier } from '../../suppliers/supplier.model';
import { SupplierService } from '../../suppliers/supplier.service';
import { Channel } from '../channel.model';
import { ChannelService } from '../channel.service';
import { ModelComponent} from '../../model/model.component';
import { ModelService } from '../../model/model.service';
import { SelectModel } from '../../shared/select-uf/select.model';

import { ZipCodeService } from '../../shared/app-service-zipcode/zip-code.service'

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html'
})
export class ChannelComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    this.debounce.unsubscribe();
  }

  debounce: Subject<string> = new Subject<string>()

  channel: Channel
  actionOption: string;
  model: ModelComponent = new ModelComponent();
  models: ModelComponent[];
  suppliers: Supplier[];
  statusChannel: any[];
  ufs: any[];

  constructor(  private channelService: ChannelService,
                private modelService: ModelService,
                private supplierService: SupplierService,
                private router: Router,
                private activeRouter: ActivatedRoute,
                private toasterService: ToasterService,
                private zipCodeService: ZipCodeService ) { }

  ngOnInit() {

    this.channel = { _id:null, socialName:null, fantasyName:null, 
      cnpj:null, stateRegistration:null, email:null, address:null,
      number:null, complement:null, neighborhood:null, city:null, state:null,
      zipcode:null, phone:null, cellphone: null, createDate:null, active:null,
      responsible:null, additionalCellphone: null, additionalEmail:null, additionalPhone:null,
      description:null, copies:null, coverage:null, type:null, population:null, days:null, publicProfile:null,
      period:null }

    this.statusChannel = [
      {value: 'false', label: 'Inativo' },
      {value: 'true', label: 'Ativo'}
    ]

    // carrega a lista de UFs
    this.ufs = SelectModel

    this.actionOption = 'create';

    if (this.activeRouter.snapshot.params['id'] !== undefined) {

      this.channelService.channelById(this.activeRouter.snapshot.params['id'])
        .subscribe(channel => {

            this.getChannel(channel)
            this.model.type = channel.type;
            this.model.definedProgram = false;
            this.model.channel = channel._id;

            this.modelService
              .getByChannelId(channel._id)
              .subscribe(models => {
                this.models = models;
              });

            this.supplierService
              .supplier()
              .subscribe(result => {this.suppliers = result}
              );
          })
    }

    this.debounce
      .pipe(debounceTime(500))
      .subscribe( zipCode =>  {
        if(zipCode !== '' && zipCode.length > 7) {

          const newZipCode = this.formatZipCode(zipCode)
          this.channel.zipcode = newZipCode

          this.zipCodeService.findZipCode(this.channel.zipcode)
            .subscribe((result) => {
              this.channel.address = result.logradouro;
              this.channel.complement = result.complemento;
              this.channel.neighborhood = result.bairro;
              this.channel.city = result.localidade;
              this.channel.state = result.uf;
            })
      }
    })

  }


  formatZipCode(value) {

    let newValue = value.toString().replace(/\D/g,"")   //Remove tudo o que não é dígito
    newValue = newValue.replace(/(\d{5})(\d)/,"$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
    return newValue

  }


  success(message: string) {
    this.toasterService.success(message);
  }

  error(message?: string) {
    this.toasterService.error(`Ops!</br> ${message}`);
  }

  info() {
    this.toasterService.info('Empresa cadastrada com sucesso!')
  }

  warning() {
    this.toasterService.warning('Empresa', 'Ocorreram problemas.')
  }

  createChannel(formValue: Channel) {

    this.channelService.create(formValue)
      .subscribe((msg: any) => {

        this.success('Cadastro realizado com sucesso!');

        this.channelService.channelByCNPJ(formValue.cnpj)
        .subscribe(channels => {

          this.router.navigate(['channels', 'channel', channels[0]._id]);
        });
      })
  }

  createModel() {

    this.model.columns = (this.model.columns) ? this.model.columns.toString().trim().replace(',', '.') : null;
    this.model.height = (this.model.height) ? this.model.height.toString().trim().replace(',', '.') : null;
    this.model.width = (this.model.width) ? this.model.width.toString().trim().replace(',', '.') : null;

    console.log('teste')
    
    this.modelService.register(this.model)
      .subscribe(() => {

        this.success('Peça adicionada com sucesso!');

        this.model = new ModelComponent();
        this.model.channel = this.channel._id;
        this.model.type = this.channel.type;

        this.modelService
          .getByChannelId(this.channel._id)
          .subscribe(models => {
            this.models = models;
          });
    });
  }

  removeModel(id) {
    this.modelService.delete(id)
      .subscribe((msg: any) => {
        this.success('Peça removida com sucesso!');

        // atualiza os registros
        this.modelService.getByChannelId(this.channel._id)
          .subscribe(models => {
            this.models = models;
        });
      })
  }

  updateChannel() {
    this.channelService.update(this.channel)
      .subscribe((msg: any) => {
        this.success('Cadastro atualizado com sucesso!')
        this.router.navigate(['/channels'])
      })
  }

  getChannel(channel: Channel) {

    this.actionOption = 'edit';
    this.channel = channel

    const zipCode = this.formatZipCode(this.channel.zipcode) 
    this.channel.zipcode = zipCode
  }

  changeChannelType() {
    if (this.channel.type !== 'Outros') {
      this.model.type = this.channel.type;
    }
  }

  changeStatus($event) {

    if ($event === 'false') {
      this.channel.active = false
    } else {
      this.channel.active = true
    }

  }

  changeUf($event: string) {
    this.channel.state = $event
  }

  getColumns(columns) {

    this.model.columns = this.modelService.formatField(columns)
    this.getPriceUnit()
  }

  getPriceUnit() {

    this.modelService.calculateUnitPrice(this.model, this.model.type);
  }

  getWidth(width) {

    this.model.width = this.modelService.formatField(width)
  }

  getHeight(height) {

    this.model.height = this.modelService.formatField(height)
    this.getPriceUnit()
  }

  getPrice(price) {

    this.model.price = price
    this.getPriceUnit()
  }

  getMinimumCoverage(minimumCoverage) {

    this.model.minimumCoverage = minimumCoverage
    this.getPriceUnit()
  }

  

}
