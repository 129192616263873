<div class="row">
  <div class="col-lg-10 col-md-10">
    <div class="card">
        <div class="card-body">
            <nav aria-label="breadcrumb" role="navigation">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                      <a [routerLink] = "['/models']">Peças</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">Cadastrar</li>
                </ol>
              </nav>
        </div>
        
        <div class="card-header">
          <strong>Peça</strong>
          <small>Cadastro</small>
        </div>

      <div class="card-body">

        <form novalidate #form="ngForm"class="row" (submit)="register()" class="form-horizontal">
        
            <!-- <pre>{{model | json}}</pre> -->

          <div class="row">

            <div class=" form-group col-sm-4">
              <app-input errorMessage="Veículo inválido." label="Veículo" > 
                <select  name="channel" [(ngModel)]="model.channel" (change)="changeChannel(model.channel)" class=" form-control" required id="channel" name="channel">
                    <option *ngFor="let channel of channels" 
                      [value]="channel._id"> {{channel.fantasyName}}
                  </option>
                </select>
              </app-input>
            </div> 

            <div class="form-group col-sm-3">
              <app-input errorMessage="Campo obrigatório" label="Tipo">
                <select id="type" name="type" required [(ngModel)]="model.type" [disabled]="model.channelType != 'Outros'" class="form-control input-lg" size="1">
                  <option [ngValue]=""></option>
                  <option [ngValue]="'OOH'" [selected]="model.type == 'OOH'" >OOH</option>
                  <option [ngValue]="'Rádio'" [selected]="model.type == 'Rádio'" >Rádio</option>
                  <option [ngValue]="'Jornal'" [selected]="model.type == 'Jornal'" >Jornal</option>
                  <option [ngValue]="'Revista'" [selected]="model.type == 'Revista'" >Revista</option>
                </select>
              </app-input>
            </div> 
          </div>
          
          <!-- Rádio -->
          <div class="row" [hidden]="model.type != 'Rádio'">

            <div class="form-group col-sm-6" >
              <app-input errorMessage="Intervalo inválido." label="Intervalo" > 
                <input type="text" name="interval" [(ngModel)]="model.interval" id="interval" class="form-control" size="16" placeholder="Ex: 06h00 - 19h00" [required]="model.type == 'Rádio'" minlength="3" maxlength="256">
              </app-input>
            </div>

            <div class="form-group col-sm-2">
                <app-input errorMessage="Duração inválida." label="Duração" > 
                  <input type="number" name="duration" [(ngModel)]="model.duration" id="duration" class="form-control" size="16"  [required]="model.type == 'Rádio'" minlength="1" maxlength="1000">
                </app-input>
            </div>

            <div class="form-group col-sm-3">
                <app-input errorMessage="Intervalo inválido." label="Intervalo de duração" > 
                    <select id="durationType" name="durationType" [(ngModel)]="model.durationType" class="form-control input-lg" size="1" [required]="model.type == 'Rádio'">
                        <option [ngValue]="'Segundos'" [selected]="model.durationType == 'Segundos'" >Segundos</option>
                        <option [ngValue]="'Minutos'" [selected]="model.durationType == 'Minutos'" >Minutos</option>
                        <option [ngValue]="'Horas'" [selected]="model.durationType == 'Horas'" >Horas</option>
                      </select>
                </app-input>
            </div>

          </div>

          <!-- Jornal-->
          <div class="row" [hidden]="model.type != 'Jornal'">

            <div class="form-group col-sm-3" >
              <app-input errorMessage="Tiragem inválida." label="Tiragem" > 
                <input type="text" name="copies" [(ngModel)]="model.copies" id="copies" class="form-control" size="16" [required]="model.type == 'Jornal'" minlength="1">
              </app-input>
            </div>

            <div class="form-group col-sm-3" >
              <app-input errorMessage="Nº de colunas inválido." label="Colunas" > 
                <input type="text" name="columns" [(ngModel)]="model.columns" id="columns" class="form-control" size="16" [required]="model.type == 'Jornal'" minlength="1">
              </app-input>
            </div>

            <div class="form-group col-sm-3" >
              <app-input errorMessage="Largura inválida." label="Largura" > 
                <input type="text" name="width" [(ngModel)]="model.width" id="width" class="form-control" size="16" [required]="model.type == 'Jornal'" minlength="1">
              </app-input>                      
            </div>

            <div class="form-group col-sm-3" >
                <app-input errorMessage="Altura inválida." label="Altura" > 
                  <input type="text" name="height" [(ngModel)]="model.height" id="height" class="form-control" size="16" [required]="model.type == 'Jornal'" minlength="1">
                </app-input>                      
            </div>

          </div>

          <!-- Revista -->
          <div class="row" [hidden]="model.type != 'Revista'">

            <div class="form-group col-sm-3" >
              <app-input errorMessage="Nº de exemplares inválido." label="Número de exemplares" > 
                <input type="text" name="copies" [(ngModel)]="model.copies" id="copies" class="form-control" size="16" [required]="model.type == 'Revista'" minlength="1">
              </app-input>
            </div>

            <div class="form-group col-sm-3" >
              <app-input errorMessage="Nº de colunas inválido." label="Colunas" > 
                <input type="text" name="columns" [(ngModel)]="model.columns" id="columns" class="form-control" size="16" [required]="model.type == 'Revista'" minlength="1">
              </app-input>
            </div>

            <div class="form-group col-sm-3" >
              <app-input errorMessage="Largura inválida." label="Largura" > 
                <input type="text" name="width" [(ngModel)]="model.width" id="width" class="form-control" size="16" [required]="model.type == 'Revista'" minlength="1">
              </app-input>                      
            </div>

            <div class="form-group col-sm-3" >
                <app-input errorMessage="Altura inválida." label="Altura" > 
                  <input type="text" name="height" [(ngModel)]="model.height" id="height" class="form-control" size="16" [required]="model.type == 'Revista'" minlength="1">
                </app-input>                      
            </div>

          </div>

          <!-- OOH-->
          <div class="row" [hidden]="model.type != 'OOH'">

            <div class="form-group col-sm-2" >
              <app-input errorMessage="Medida inválido." label="Medida" > 
                <input type="text" name="size" [(ngModel)]="model.size" id="size" class="form-control" size="16" placeholder="Ex: 9 x 3" [required]="model.type == 'OOH'">
              </app-input>
            </div>

            <div class="form-group col-sm-3">
              <app-input errorMessage="Tipo inválido." label="Tipo de OOH" > 
                  <select id="OOHType" name="OOHType" [(ngModel)]="model.OOHType" class="form-control input-lg" size="1" [required]="model.type == 'OOH'" >
                      <option [ngValue]="'Outdoor'" [selected]="model.OOHType == 'Outdoor'" >Outdoor</option>
                      <option [ngValue]="'Frontlight'" [selected]="model.OOHType == 'Frontlight'" >Frontlight</option>
                      <option [ngValue]="'Backlight'" [selected]="model.OOHType == 'Backlight'" >Backlight</option>
                      <option [ngValue]="'Painel Luminoso'" [selected]="model.OOHType == 'Painel Luminoso'" >Painel Luminoso</option>
                      <option [ngValue]="'Empenas'" [selected]="model.OOHType == 'Empenas'" >Empenas</option>
                      <option [ngValue]="'Triedro'" [selected]="model.OOHType == 'Triedro'" >Triedro</option>
                    </select>
              </app-input>
            </div>
       
            
            <!-- <div class=" form-group col-sm-4">
              <app-input errorMessage="Fornecedor inválido." label="Fornecedor" > 
                <select  name="supplier" [(ngModel)]="model.supplier" class=" form-control" id="supplier" name="supplier">
                    <option *ngFor="let supplier of suppliers" 
                      [value]="supplier._id"> {{supplier.fantasyName}}
                  </option>
                </select>
              </app-input>
            </div> --> 
          

            <!-- <div class="form-group col-sm-3">
              <app-input errorMessage="Custo de produção inválido." label="Custo de produção" > 
                <input name="productionCost" currencyMask [(ngModel)]="model.productionCost" id="productionCost" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control" size="16" placeholder="Ex: 1.000,00" >
              </app-input>
            </div> -->

          </div>

          <div class="row">
            <div class="form-group col-sm-6">
                <app-input errorMessage="Formato da peça inválido." label="Nome" > 
                  <input type="text" name="name" [(ngModel)]="model.name" class="form-control" id="name" class="form-control" placeholder="Ex: Rotativo 30''"  required minlength="3" maxlength="256" >
                </app-input>
            </div>

            <div class="form-group col-sm-4">
              <app-input errorMessage="Valor inválido." label="Valor" > 
                <input name="price" currencyMask [(ngModel)]="model.price" id="price" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control" size="16" placeholder="Ex: 100,00" required minlength="3" maxlength="256">
              </app-input>
            </div>

          </div>
 
        <!-- <div class="form-actions">
          <button type="submit" [disabled]="!formModel.valid" class="btn btn-primary">Salvar</button>
          <a [routerLink]="['', 'models']" class="btn btn-default">Voltar</a>
        </div> -->
      </form>
      </div>
    </div>
  </div>
</div>