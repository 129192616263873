import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'appFilter'
})
export class AppFilterPipe implements PipeTransform {

  transform(array: any[], query: string): any {

    if (query) {
      return _.filter(array, row => Object.keys(row).some(k => {
        if(row[k] == null)
          return;
        return row[k].toString().toLowerCase().includes(query.toLowerCase())
      }))
        
    }
    return array;
  }
}
