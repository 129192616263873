import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { LoginService } from '../login/login.service';



@Injectable()
export class GuardRouter implements CanActivate, CanLoad {

    constructor(private router: Router, private loginService: LoginService) { }

    canActivate(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
      
      return this.validateRouter(activatedRoute.routeConfig.path)
    }

    canLoad(route: Route): Observable<boolean>|Promise<boolean>|boolean {
      
      return this.validateRouter(route.path)
    }

   
    validateRouter(path: string) {
      
      const token = this.loginService.islogged()
      
      if (!token) {
        console.log('token inválido')
        this.loginService.handleLogin(`/${path}`)
      }

      return token


      
    }

}
