import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { Customer } from './customer.model';
import { HOST_API } from '../app.api';

@Injectable()
export class CustomerService {

    customers: Customer[]

    constructor(private http: HttpClient) {}

    customer(): Observable<Customer[]> {

        return this.http.get<Customer[]>(`${HOST_API}/customers`)
    }

    create(customer: Customer): Observable<string> {

        return this.http.post<string>(`${HOST_API}/customers`, customer)
    }

    customerById(id: string): Observable<Customer> {

        return this.http.get<Customer>(`${HOST_API}/customers/${id}`)           
    }

    update(customer: Customer): Observable<string> {

        return this.http.put<string>(`${HOST_API}/customers/${customer._id}`, customer)
    }

    clear() {
       this.customers = []
    }

// tslint:disable-next-line:eofline
}