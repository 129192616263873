import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
   name : 'cnpjcpf'
})
export class CnpjCpfPipe implements PipeTransform {
   transform(base: string): string {
        
        const value = base.replace(/\D/g,"")

        if (value === undefined) {
            return
        }
        else if (value.length <= 11 ) {
            return value.replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4");
        } else if (value.length > 11) {
            return value.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
        }  
    }

}
