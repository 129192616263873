<app-header></app-header>
<div class="app-body">
  <app-sidebar></app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!--  <ol class="breadcrumb"> -->
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <!-- </ol> -->
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.conainer-fluid -->
  </main>
  <app-aside></app-aside>
</div>
<app-footer></app-footer>
