import { Component, OnInit } from '@angular/core';
import { ToasterService } from '../toaster-service.service';
import { Login } from './login.model';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  logo: any = 'assets/img/logo.png'
  login: Login = null

  constructor(  private router: Router,
                private loginService: LoginService,
                private toasterService: ToasterService ) { }

  ngOnInit() {
  }

  success(message: string) {
    this.toasterService.success(message);
  }

  error(message?: string) {
    this.toasterService.error(`Ops!</br> ${message}`);
  }

  warning() {
    this.toasterService.warning('Login', 'Ocorreram problemas ao tentar se autenticar.')
  }

  authUser(formValue: Login) {
      this.loginService.token(formValue)
        .subscribe((data: any) => {
          this.success('Login realizado com sucesso')
          this.router.navigate(['/agencies'])
      })
  }

}
