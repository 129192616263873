import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http';
import { ZipCode } from './zip-code.model'

@Injectable()
export class ZipCodeService {

  constructor(private http: HttpClient) { }

  findZipCode(zipCode: string): Observable<ZipCode> {

    zipCode = zipCode.replace(/\D/g, '');

    if (zipCode !='') {

      const validationZipCode = /^[0-9]{8}$/;
      
      if (validationZipCode.test(zipCode)){
        return this.http.get<ZipCode>(`//viacep.com.br/ws/${zipCode}/json`)
      }

      return null

    }

  }

}
