import { HOST_API } from '../app.api';
import { HttpClient} from '@angular/common/http';
import { UserComponent } from './user.component';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as jwt_decode from 'jwt-decode';


@Injectable()
export class UserService {
 

    constructor(private http: HttpClient) {}


    get(): Observable<UserComponent[]> {

        return this.http.get<UserComponent[]>(`${HOST_API}/users`)
    }

    getById(id: string):  Observable<UserComponent> {

        return this.http.get<UserComponent>(`${HOST_API}/users/${id}`)
    }

    register(user: UserComponent): Observable<string> {

        return this.http.post<string>(`${HOST_API}/users`, user);
    }

    update(user: UserComponent): Observable<string> {

        return this.http.put<string>(`${HOST_API}/users/${user._id}` , user);
    }

    changePassword(id: string, password: string): Observable<string> {

        return this.http.put<string>(`${HOST_API}/users/changePassword/${id}`, ({ password: password }));
    }

    changeStatus(id: string, status: boolean): Observable<string> {

        return this.http.put<string>(`${HOST_API}/users/changeStatus/${id}`, ({ active: !status }));
    }

    getUserByToken() {
        const token = localStorage.getItem('token')
        const user = jwt_decode(token)
        return user
    }

    logout() {
        localStorage.setItem('token', '')
    }
// tslint:disable-next-line:eofline
}