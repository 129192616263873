import { Component, OnInit } from '@angular/core';

import { Company } from './company.model';
import { CompanyService } from './company.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html'
})
export class CompaniesComponent implements OnInit {


  companies: Company[] = []
  filterQuery = '';

  constructor(private companyService: CompanyService ) {}

  ngOnInit() {
    this.companyService.company()
      .subscribe(companies => {this.companies = companies}, error => console.log(error))
  }

}
