<div class="row">
  <div class="form-group col-lg-12">                
    <app-input errorMessage="Campo obrigatório" label="(*) Selecione a agência">
      <select name="agency" required [ngModel]="agency?._id" (ngModelChange)="change($event)" class="form-control input-lg" size="1">
        <option [ngValue]="''">Selecione</option>
        <option *ngFor="let agency of agencies" [ngValue]="agency?._id">{{agency.fantasyName}}</option>
      </select>
    </app-input>
  </div>
</div>

<div class="row">
  <div class="form-group col-lg-3">               
    <app-input errorMessage="Campo obrigatório" label="Razão Social"> 
      <input type="text" class="form-control" name="razaoSocial" [ngModel]="agency?.socialName" autocomplete="off" placeholder="Razão social" [disabled]="true">              
    </app-input>
  </div>
  <div class="form-group col-lg-3">                
    <app-input errorMessage="Campo obrigatório" label="Cnpj"> 
      <input type="text" class="form-control" name="Cnpj" [ngModel]="agency?.cnpj" autocomplete="off" placeholder="digite o cnpj" [disabled]="true">              
    </app-input>
  </div>
  <div class="form-group col-lg-3">                
    <app-input errorMessage="Campo obrigatório" label="Inscrição Estadual">
      <input type="text" class="form-control" name="stateRegistration" [ngModel]="agency?.stateRegistration" autocomplete="off" placeholder="Isento" [disabled]="true">              
    </app-input>
  </div>
  <div class="form-group col-lg-3">                
    <app-input errorMessage="Campo obrigatório" label="Cidade">
      <input type="text" class="form-control" name="city" [ngModel]="agency?.city" autocomplete="off" placeholder="Cidade" [disabled]="true">              
    </app-input>
  </div>
</div>