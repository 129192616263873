import { ConfirmResetPassword } from './confirm-reset-password.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from '../toaster-service.service';

@Component({
  selector: 'app-confirmconfirm-reset-password',
  templateUrl: './confirm-reset-password.component.html',
  styleUrls: ['./confirm-reset-password.component.scss']
})
export class ConfirmResetPasswordComponent implements OnInit {

  password: any
  confirmedPassword: any

  logo: any = 'assets/img/logo.png'

  constructor(private router: Router,
              private toaster: ToasterService,
              private confirmResetPassword: ConfirmResetPassword ) { }

  ngOnInit() {}

  isPassword(form: any) {
    if (form.password !== form.confirmedPassword) {
      return false
    }
    return true
  }

  updatePassword(form: any) {

    if (this.isPassword(form)) {
      this.confirmResetPassword.confirmedPassword(form)
        .subscribe((msg: any) => {
          this.toaster.success('Senha alterada com sucesso.')
          localStorage.setItem('tokenConfirmedPassword', null)
          this.router.navigate(['login'])
        })
    } else {
      this.toaster.error('As senhas estão diferentes.')
      return
    }

  }

  success(message: string) {
    this.toaster.success(message);
  }

  error(message?: string) {
    this.toaster.error(`Ops!</br> ${message}`);
  }


}
