export enum typeChannel {
    Radio = 1,
    Jornal = 2,
    Revista = 3,
    OHH = 4
} 

export enum typeOrder {
    Comissionado = 1,
    Faturar = 2
}