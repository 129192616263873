'use strict'

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { Observable } from 'rxjs/Observable';

import {Order} from './order.model'
import { OrderPagination } from './order.pagination.model'
import { HOST_API } from '../app.api';
import { ModelComponent } from '../model/model.component';
import { Observation} from './observation.model';

import {AppFilterSearch} from './../shared/app-filter-search/app-filter-search.model'


import * as XLSX from 'xlsx'


@Injectable()
export class OrderService {

    orders: Order[]
    models: ModelComponent[]
    order: Order


    constructor(private httpClient: HttpClient) {}

    getObservation(): Observable<Observation> {
        return this.httpClient.get<Observation>(`${HOST_API}/observations`)
    }

    get(pages: number, rowsDisplayed: number, filter?:AppFilterSearch ): Observable<OrderPagination> {
  
        return this.httpClient.get<OrderPagination>(`${HOST_API}/orders/page/${pages}/rowsDisplayed/${rowsDisplayed}?${this.queryString(filter)}`)
    }

    getById(id: string): Observable<Order> {

        return this.httpClient.get<Order>(`${HOST_API}/orders/order/${id}`)
    }

    createPDF(id: string): Observable<Blob> {
         
        return this.httpClient.get<Blob>(`${HOST_API}/orders/order/${id}/pdf`, { responseType: 'blob' as 'json' })
    }

    create(order: Order): Observable<string> {
        
        return this.httpClient.post<string>(`${HOST_API}/orders`, order )
    }

    update(order: Order): Observable<string> {

        return this.httpClient.put<string>(`${HOST_API}/orders/${order._id}`, order)
    }

    report(filter?:AppFilterSearch): Observable<any> {

        return this.httpClient.get<any>(`${HOST_API}/orders/report?${this.queryString(filter)}`)
    }


    onGetTotalCommission(order: Order ) {

        if (order.type === 1) {
            return (order.vComissaoPercent / 100) * order.vTotalBruto
        } else {
            return (order.vComissaoPercent / 100) * order.vLiqFaturar
        }
    }

    onGetTotalNetValue(order: Order) {

        if (order.type === 1) {

            return order.vTotalBruto - order.vComissao
        } else {

            return order.vTotalBruto - order.vComissaoAgency - order.vComissao
        }
    }

    onGetAgencyTotalComission(order: Order) {

        return (order.vComissaoPercentAgency / 100) * order.vTotalBruto
    }

    onGetTotalValueInvoiced(order: Order) {

        return order.vTotalBruto - order.vComissaoAgency

    }

    getDaysOfMonth(year: number, month: number): any {

        return new Date(year, month, 0).getDate()
    }

    queryString(filter: AppFilterSearch) {

        return filter !== undefined ? Object.keys(filter).map(key => key = key + '=' + filter[key]).join('&'): ''  
    }

    onFormatDate(dateOn: string, dateEnd: string) { 

        if (dateOn === null && dateEnd === null) return {dateHourOn: null, dateHourEnd: null}

        const hourOn = " 00:00:00.000Z"
        const hourEnd = " 23:59:59Z"

        let dateHourOn = dateOn && dateOn.length > 0 ? dateOn + hourOn : dateEnd + hourOn
        let dateHourEnd = dateEnd && dateEnd.length > 0 ? dateEnd + hourEnd : dateOn + hourEnd

        return {dateHourOn: dateHourOn, dateHourEnd: dateHourEnd}
    }

    getFilterDate(date: Date) {

        let day = date.getUTCDate()
        let month = date.getUTCMonth() + 1
        let year = date.getUTCFullYear()
        let newDate = `${year}-${month}-${day}`
        
        return  newDate
    }

    onFormatHeaderFile(value: string ) {

        let header = ''

        switch (value) {

            case "numberOrder" : {
                header = "Pedido de inserção"
                break;
            }

            case "placementMonth" : {
                header = "Mês de veiculação"
                break;
            }

            case "placementYear": {
                header = "Ano de veiculação"
                break;
            }

            case "companyFantasyName": {
                header = "Empresa"
                break;
            }

            case "vComissao": {
                header = "Comissão da empresa"
                break;
            }

            case "vLiqRadio": {
                header = "Valor líquido"
                break;
            }

            case "vTotalBruto": {
                header = "Valor Bruto"
                break;
            }

            case "customerFantasyName": {
                header = "Cliente"
                break;
            }

            case "channelFantasyName": {
                header = "Veículo"
                break;
            }

            case "channelType": {
                header = "Tipo do veículo"
                break;
            }

            case "extraCommission": {
                header = "Comissão Extra"
                break;
            }

            case "billedCompany": {
                header = "Faturado pela Empresa"
                break;
            }

            case "vLiqFaturar": {
                header = "Valor a faturar"
                break;
            }
            
            case "vComissaoAgency": {
                header = "Comissão da agência"
                break;
            }

            case "expirationDate": {
                header = "Data de vencimento"
                break;
            }

            case "agencyFantasyName": {
                header = "Agência"
                break;
            }

            case "dateOfIssue" : {
                header = "Data de emissão"
                break;
            }

            case "type": {
                header = "Tipo de P.I"
                break;
            }

            case "customerCampaign": {
                header = "Campanha"
                break;
            }

            default: {

                break;
            }
        }

        return header

    }
    
    exportAsExcelFile(title, row): void {

        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        wb.Props = {
          Title: title,
          Subject: "Relatório de vendas",
          Author: "HubCom - Sistema",
          CreatedDate: new Date()
        };

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(row);
        
        var range = XLSX.utils.decode_range(ws['!ref']);
        for(var C = range.s.r; C <= range.e.r; ++C) {
        var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
            if(!ws[address]) continue;
            ws[address].v = this.onFormatHeaderFile(ws[address].v)
        }

        XLSX.utils.book_append_sheet(wb, ws, 'Hubcom - relatório de vendas');


        /* save to file */
        const saveDate = new Date()
        XLSX.writeFile(wb, `relatorio-pi${saveDate}.xlsx`);

    }

    
    
}
