import { Component } from "@angular/core";
import { UserComponent } from "./user.component";
import { UserService } from './user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../toaster-service.service';

@Component({
    selector: 'user-change-pass',
    templateUrl: './user-change-pass.component.html'
})

export class UserChangePassComponent{
    
    user: UserComponent = new UserComponent();
    userChangePassForm: FormGroup;
    service: UserService;
    route: ActivatedRoute;
    router: Router;

    constructor(    service: UserService, 
                    fb: FormBuilder, 
                    route: ActivatedRoute, 
                    router: Router, 
                    private toasterService: ToasterService) {

        this.service = service;
        this.route = route;
        this.router = router;

        this.userChangePassForm = fb.group({
            password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(12)])], 
            confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(12)])] 
        });
    }

    changePass(){
        
        this.route.params.subscribe(params => {

            this.service.changePassword(params['id'], this.user.password)
                .subscribe(()=> {
                    this.success();
                    this.user = new UserComponent();
                    this.router.navigate(['users']);

                }, error => this.error(JSON.parse(error._body).map(m => m.message).join('<br>')));
        });

    }

    success() {

        this.toasterService.success('', 'Senha de usuário alterada com sucesso!');
    }

    error(message?: string) {

        this.toasterService.error('', `Ops!</br> ${message}`);
    }

        
}