import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import * as XLSX from 'xlsx'

import { Channel } from './channel.model';
import { HOST_API } from '../app.api';

@Injectable()
export class ChannelService {

    channels: Channel[] = []

    constructor(private http: HttpClient) {}

    get(): Observable<Channel[]> {

        return this.http.get<Channel[]>(`${HOST_API}/channels`)
    }

    create(channel: Channel): Observable<string> {
        
        return this.http.post<string>(`${HOST_API}/channels`, channel)
    }

    channelById(id: string): Observable<Channel> {

        return this.http.get<Channel>(`${HOST_API}/channels/${id}`)
    }

    channelByCNPJ(cnpj: string): Observable<Channel> {
        
        return this.http.get<Channel>(`${HOST_API}/channels/cnpj/${cnpj}`)
    } 

    update(channel: Channel): Observable<string> {
        
        return this.http.put<string>(`${HOST_API}/channels/${channel._id}`, channel )           
    }

    channelByState(state: String): Observable<Channel[]> {
        
        return this.http.get<Channel[]>(`${HOST_API}/channels/state/${state}`)
    }

    clear() {
       this.channels = []
    }

    report(): Observable<any> {

        return this.http.get<Channel[]>(`${HOST_API}/channels/report`)
    }

    exportAsExcelFile(title, row): void {

        const wb: XLSX.WorkBook = XLSX.utils.book_new();

        wb.Props = {
          Title: title,
          Subject: "Relatório de veículos",
          Author: "HubCom - Sistema",
          CreatedDate: new Date()
        };

        row.forEach(item => {
            
            item.fantasyName = item.channel.fantasyName
            item.socialName = item.channel.socialName
            item.cnpj = this.onFormatMaskCnpj(item.channel.cnpj)
            item.responsible = item.channel.responsible 
            item.stateRegistration = item.channel.stateRegistration
            item.contact = item.channel.contact
            item.email = item.channel.email
            item.additionalEmail = item.channel.additionalEmail
            item.address = item.channel.address
            item.number = item.channel.numbet
            item.complement = item.channel.complement
            item.neighborhood = item.channel.neighborhood
            item.city = item.channel.city
            item.state = item.channel.state
            item.zipcode = this.onFormatMaskZipCode(item.channel.zipcode)
            item.phone = this.onFormatphone(item.channel.phone)
            item.additionalPhone = this.onFormatphone(item.channel.additionalPhone)
            item.cellphone = this.onFormatphone(item.channel.cellphone) 
            item.additionalCellphone = this.onFormatphone(item.channel.additionalCellphone) 
            item.description = item.channel.description
            item.coverage = item.channel.coverage
            item.type = item.channel.type
            item.population = item.channel.population
            item.publicProfile = item.channel.publicProfile
            item.copies = item.channel.copies
            item.period = item.channel.period
            item.days= item.channel.days
            
            item.definedProgram = item.definedProgram === true? "Sim": "Não"

            delete item.channel
            delete item._id

        })
        
        var report = JSON.parse(JSON.stringify(row, ["fantasyName", "socialName", "cnpj", "responsible", "stateRegistration", "contact", "phone", "additionalPhone", "cellphone", "additionalCellphone", "email", "additionalEmail", "address", "number", "complement", "neighborhood", "city", "state", "zipcode", "description", "coverage", "type", "population", "publicProfile", "copies", "period", "days", "name", "price", "unitPrice", "minimumCoverage", "period", "duration", "interval", "commercialType", "copies", "columns", "width", "height", "size", "OOHType", "week", "definedProgram", "radioProgram", "broadcaster", "broadcasterPayment", "observation"],4));

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(report);
        
        var range = XLSX.utils.decode_range(ws['!ref']);
        for(var C = range.s.r; C <= range.e.r; ++C) {
        var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
            if(!ws[address]) continue;
            ws[address].v = this.onFormatHeaderFile(ws[address].v)
        }

        XLSX.utils.book_append_sheet(wb, ws, 'Hubcom - relatório de Veículos');


        /* save to file */
        const saveDate = new Date()
        XLSX.writeFile(wb, `relatorio-veiculos${saveDate}.xlsx`);

    }

    onFormatHeaderFile(value: string ) {

        let header = ''

        switch (value) {

            case "interval": {
                header = "Intervalo"
                break;
            }

            case "duration": {
                header = "Duração"
                break;
            }

            case "commercialType": {
                header = "Tipo de comercial"
                break
            }

            case "name" : {
                header = "Peça"
                break;
            }

            case "unitPrice" : {
                header = "preço unitário"
                break;
            }

            case "price": {
                header = "Preço"
                break;
            }

            case "minimumCoverage": {
                header = "Cobertura mínima"
                break;
            }

            case "size": {
                header = "Tamanho"
                break;
            }

            case "OOHType": {
                header = "Tipo"
                break;
            }

            case "observation": {
                header = "Obervação"
                break;
            }

            case "definedProgram": {
                header = "Programa definido"
                break;
            }

            case "fantasyName": {
                header = "Nome fantasia"
                break;
            }

            case "socialName": {
                header = "Razão social"
                break;
            }

            case "cnpj": {
                header = "Cnpj"
                break;
            }

            case "responsible": {
                header = "Responsável"
                break;
            }

            case "stateRegistration": {
                header = "Inscrição estadual"
                break;
            }
            
            case "contact": {
                header = "Contato"
                break;
            }

            case "email": {
                header = "Email"
                break;
            }

            case "additionalEmail": {
                header = "Email adicional"
                break;
            }

            case "address" : {
                header = "Endereço"
                break;
            }

            case "number": {
                header = "número"
                break;
            }

            case "complement": {
                header = "Complemento"
                break;
            }

            case "neighborhood": {
                header = "Bairro"
                break;
            }

            case "city": {
                header = "Cidade"
                break;
            }

            case "state": {
                header = "Estado"
                break;
            }

            case "zipcode": {
                header = "Cep"
                break;
            }
            
            case "phone": {
                header = "Telefone"
                break
            }

            case "additionalPhone": {
                header = "Telefone adicional"
                break
            }

            case "cellphone": {
                header = "Celular"
                break;
            }

            case "additionalCellphone": {
                header = "Celular adicional"
                break
            }

            case "description": {
                header = "Descrição"
                break
            }

            case "coverage": {
                header = "Cobertura"
                break
            }

            case "type": {
                header = "Tipo de veículo"
                break
            }

            case "population": {
                header = "População"
                break
            }

            case "copies": {
                header = "Copias"
                break
            }

            case "period": {
                header = "Período"
                break
            }

            case "days": {
                header = "Dias"
                break
            }

            case "radioProgram": {
                header = "Nome do programa"
                break

            }
            
            case "broadcaster": {
                header = "Nome do loucutor"
                break
            }
            
            case "broadcasterPayment": {
                header = "Custo loucutor"
                break
            }

            case "columns": {
                header = "Colunas"
                break
            }

            case "width": {
                header = "Largura"
                break
            }

            case "height": {
                header = "Altura"
                break
            }

            case "week" : {
                header = "Semana"
                break
            }

            default: {

                break;
            }
        }

        return header

    }

    onFormatphone(value: string) {

        if (value) {
            let v = value.toString()
            v=v.replace(/\D/g,"")                 //Remove tudo o que não é dígito
            v=v.replace(/^(\d\d)(\d)/g,"($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
            v=v.replace(/(\d{4})(\d)/,"$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos

            return v

        } else {
            return value
        }

    }

    onFormatMaskCnpj(value: string) {

        if (value) {
            return value.replace(/\D/g,"").replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1 $2 $3/$4-$5");
        } else {
            return value
        }
    }

    onFormatMaskZipCode(value: string) {

        if (value) {

            let v = value.toString()
            v=v.replace(/\D/g,"")                 //Remove tudo o que não é dígito
            v=v.replace(/(\d{5})(\d)/,"$1-$2")    //Coloca hífen entre o quinto 
            return v
            
        } else {
            return value
        }
    }



}