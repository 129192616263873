<div class="row">
    <div class="form-group col-lg-2">
        <app-input errorMessage="Campo obrigatório" label="(*) Selecione o estado">
            <select name="state" required (ngModelChange)="changeUf($event)" [ngModel]="channel?.state" class="form-control input-lg" size="1">
                <option *ngFor="let s of states" [value]="s.label">
                    {{s.label}}
                </option>
            </select>
        </app-input>
    </div>
    <div class="form-group col-lg-10">                
        <app-input errorMessage="Campo obrigatório" label="(*) Selecione o veículo">
            <select name="channel" required [ngModel]="channel?._id" (ngModelChange)="changeChannel($event)" class="form-control input-lg" size="1">
                <option *ngFor="let channel of channels | async" [ngValue]="channel?._id">
                    {{channel?.fantasyName}}
                </option>
            </select>
        </app-input>
    </div>
</div>

<div class="row">
    <div class="form-group col-lg-8">
        <app-input errorMessage="Campo obrigatório" label="">
          <select name="model" required (ngModel)="model" (ngModelChange)="changeModel($event)" class="form-control input-lg" size="1">
              <option [ngValue]="''">Selecione</option>
              <option *ngFor="let model of models | async" [ngValue]="model">
                  Caracteristicas: {{model?.name}} 
                  {{model?.period ? '|' : ''}} 
                  {{model?.period}} {{model?.week}} 
                  {{model?.duration ? '|' : ''}} 
                  {{model?.duration}} 
                  {{model?.durationType}} {{model?.duration ? '-' : ''}} 
                  {{model?.interval}} </option>
          </select>
        </app-input>                
    </div>
    <div class="form-actions form-group col-lg-2">
        <button (click)="addModel()" 
            class="btn btn-primary button-wrapper">Adicionar</button>
    </div>
    <div class="form-actions form-group col-lg-2">    
        <button type="button" class="btn btn-success button-wrapper" 
            (click)="openModal(template)">Novo</button>
    </div>
</div>


<ng-template #template>
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Cadastro de peças</h4>
                <button type="button" class="close pull-right" 
                        aria-label="Close" (click)="modalRef.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body"> 
                <app-order-model-create 
                    [type]="type"
                    [channelId]="channelId"
                    (changeListModel)="changeListModel($event)" >
                </app-order-model-create> 
            </div>
        </div>
    </div>
</ng-template>

<div class="row table-wrapper">
    <fieldset class="form-group col-lg-12">
      <app-order-model 
        [models]="orderChannelService.listModels()"
        [type]="type"
        (onRemove) = removeModel($event)
        (onChangeName) = changeName($event)>
      </app-order-model>
    </fieldset>
</div>
