import { Component, Injectable } from "@angular/core";
import { UserComponent } from "./user.component";
import { UserService } from './user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../toaster-service.service';


@Component({
    selector: 'user-edit',
    templateUrl: './user-edit.component.html'
})


export class UserEditComponent {
    
    user: UserComponent = new UserComponent();
    userForm: FormGroup;
    service: UserService;
    route: ActivatedRoute;
    router: Router;

    constructor(service: UserService, fb: FormBuilder, route: ActivatedRoute, router: Router, private toasterService: ToasterService) {

        this.service = service;
        this.route = route;
        this.router = router;

        this.route.params.subscribe(params => {

            this.service
                .getById(params['id'])
                    .subscribe(result => {
                        this.user = result;
                        
                        }, error => this.error(JSON.parse(error._body).map(m => m.message).join('<br>'))
                    );
        });
    }

    edit(){
        this.service
            .update(this.user)
                .subscribe(result => {
                    this.success();
                    this.router.navigate(['users']);
                    }, error => this.error(JSON.parse(error._body).map(m => m.message).join('<br>'))
                );
    }


    success() {

        this.toasterService.success('', 'Cadastro atualizado com sucesso.');
    }

    error(message?: string) {

        this.toasterService.error('', `Ops!</br> ${message}`);
    }
}