import { ToasterService } from '../toaster-service.service'
import { ResetPasswordService } from './reset-password.service';
import { ResetPassword } from './reset-password.model';
import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})

@Injectable()
export class ResetPasswordComponent implements OnInit {

  logo: any = 'assets/img/logo.png'
  reset = <ResetPassword> {}

  constructor(private resetPasswordService: ResetPasswordService,
              private toasterService: ToasterService,
              private router: Router) { }

  ngOnInit() {}

  resetPassword(email: ResetPassword) {
      this.resetPasswordService
          .reset(email)
          .subscribe((data: any) => {
            this.success('Enviamos um link para seu e-mail cadastrado para que você defina uma nova senha.')
            this.router.navigate(['login'])
          })
  }

  success(message: string) {
    this.toasterService.success(message);
  }

  error(message?: string) {
    this.toasterService.error(`Ops!</br> ${message}`);
  }

  info() {
    this.toasterService.info('Empresa cadastrada com sucesso!')
  }

  warning() {
    this.toasterService.warning('Empresa', 'Ocorreram problemas.')
  }

}
