export let Years = [
    { label: new Date().getFullYear() -5 },
    { label: new Date().getFullYear() -4 },
    { label: new Date().getFullYear() -3 },
    { label: new Date().getFullYear() -2 },
    { label: new Date().getFullYear() -1 },
    { label: new Date().getFullYear() },
    { label: new Date().getFullYear() + 1}
]

export let Months = [
    { label: 'Janeiro', value: 1},
    { label: 'Fevereiro', value: 2},
    { label: 'Março', value: 3},
    { label: 'Abril', value: 4},
    { label: 'Maio', value: 5},
    { label: 'Junho', value: 6},
    { label: 'Julho', value: 7},
    { label: 'Agosto', value: 8},
    { label: 'Setembro', value: 9},
    { label: 'Outubro', value: 10},
    { label: 'Novembro', value: 11},
    { label: 'Dezembro', value: 12}
]

