import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { OrderService } from './order.service';
import { OrderPagination } from './order.pagination.model'

import { map } from 'rxjs/operators'


@Injectable()
export class OrdersResolver implements Resolve<Observable<OrderPagination>>  {

    constructor(private orderService: OrderService) {}

    resolve(): Observable<OrderPagination> {

        const data = this.orderService
            .get(1,10)
            .pipe( map(data => data))

        return data
    }

}
