import { Component, OnInit, Input, ContentChild, AfterContentInit } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html'
})
export class SelectComponent implements OnInit, AfterContentInit {

  select: any

  @ContentChild(NgModel, {static:true}) model: NgModel

  constructor() { }

  ngOnInit() {
  }


  ngAfterContentInit() {

    this.select = this.model
    if (this.select === undefined) {
      throw new Error('Este componente precisa ser usado como ngModel');
    }

  }

}
