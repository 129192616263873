import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router, NavigationEnd} from '@angular/router'

import { Observable } from 'rxjs/Observable';
import { filter, tap } from 'rxjs/operators'

import {Login} from './login.model'
import { HOST_API } from '../app.api';
import { empty } from 'rxjs';


@Injectable()
export class LoginService {

  login: Login
  lastUrl: string
  
  constructor(private http: HttpClient, private router: Router) {
    this.router.events
          .pipe( filter(e => e instanceof NavigationEnd) )
          .subscribe( (e: NavigationEnd) => this.lastUrl = e.url)
  }

  token(user): Observable<Login> {
    return this.http
        .post<Login>(`${HOST_API}/token`, user)
        .pipe (
          tap(login => {
            this.login = login
            localStorage.setItem('token', login.token)
          })
        )      
  }

  islogged() {

    const token = localStorage.getItem('token')
    return token !== '' ? true : false
  }

  getToken() {

    return localStorage.getItem('token')
  }

  handleLogin(path: string = this.lastUrl){

    this.router.navigate(['/login', btoa(path)])    
  }

}
