import { Component, OnChanges, Output, Input, EventEmitter, SimpleChanges } from '@angular/core';
import { CompanyService } from './../../../companies/company.service';
import { Company } from './../../../companies/company.model';

@Component({
  selector: 'app-order-company',
  templateUrl: './order-company.component.html',
})
export class OrderCompanyComponent implements OnChanges {

  companies: Company[]
  company: Company

  @Output() updateCompany = new EventEmitter<Company>()
  @Output() updateBilledCompany = new EventEmitter<boolean>()
  @Input() companyId: string
  @Input() billedCompany: boolean

  constructor(private companyService: CompanyService) {}

  ngOnChanges(change: SimpleChanges) {

    this.companyService
      .company()
      .subscribe(companies => {

        this.companies = companies
        if (change.companyId.currentValue) {
          this.getCompanyById(this.companyId)
        }

      })

  }

  change(id: string) {
    this.getCompanyById(id)
  }

  getCompanyById(id: string) {

     this.companies
      .filter(company => company._id === id)
      .map((company) => {
         this.company = company
         this.updateCompany.emit(company)
      })
  }

  changeBilledCompany(billedCompany: boolean) {
    this.billedCompany = billedCompany
    this.updateBilledCompany.emit(this.billedCompany)
  }

}
