import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-user',
    template: ''
})


export class UserComponent {

    _id: string;
    @Input() name: string;
    @Input() email: string;
    @Input() password:  string;
    @Input() confirmPassword: string;
    createDate: Date;
    active: boolean;
    role: string;
    token?: string;
}
