import { ToasterService } from '../../../toaster-service.service';
import { ModelComponent } from './../../../model/model.component';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModelService } from '../../../model/model.service';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-order-model-create',
  templateUrl: './order-model-create.component.html'
})
export class OrderModelCreateComponent implements OnInit {

  @Input() type: string
  @Input() channelId: string
  @Output() changeListModel = new EventEmitter()

  model: ModelComponent = new ModelComponent()

  constructor(private modelService: ModelService,
              private toasterService: ToasterService) { }

  ngOnInit() {

  }

  register(form: NgForm, model: ModelComponent) {
    model.channel = this.channelId
    model.unitPrice = this.model.unitPrice

    this.modelService.register(model)
      .subscribe(() => {
        this.changeListModel.emit('sucesso')
        this.success('Cadastro com sucesso')
    })

    form.control.reset()
    form.controls['type'].setValue(this.type)
  }

  getPrice(price) {

    this.model.price = price
    this.getPriceUnit()
  }

  getWidth(width) {

    this.model.width = this.modelService.formatField(width)
  }

  getHeight(height) {

    this.model.height = this.modelService.formatField(height)
    this.getPriceUnit()
  }

  getColumns(columns) {

    this.model.columns = this.modelService.formatField(columns)
    this.getPriceUnit()
  }

  getPriceUnit() {

     this.modelService.calculateUnitPrice(this.model, this.type);
  }

  getMinimumCoverage(minimumCoverage) {

    this.model.minimumCoverage = minimumCoverage
    this.getPriceUnit()
  }

  success(message: string) {

    this.toasterService.success(message);
  }

  error(message?: string) {
    this.toasterService.error(`Ops!</br> ${message}`);
  }

  info() {
    this.toasterService.info('Empresa cadastrada com sucesso!')
  }

  warning() {
    this.toasterService.warning('Empresa', 'Ocorreram problemas.')
  }

}
