import { ErrorHandler, Injectable, Injector, NgZone} from '@angular/core'
import {HttpErrorResponse} from '@angular/common/http'

import { ToasterService } from './toaster-service.service';
import { LoginService } from './login/login.service'


@Injectable()
export class ApplicationErrorHandler extends ErrorHandler {

    constructor( private ns: ToasterService, 
                 private injector: Injector, 
                 private zone: NgZone) {

                    super()
                 }

    handleError(errorResponse: HttpErrorResponse | any) {

        if (errorResponse instanceof HttpErrorResponse){
          
            const error = errorResponse.error
            const message = error.map( m => m.message)

            this.zone.run(() => {
              switch (errorResponse.status){
                case 400:
                  this.error(message || 'Ocorreu um problema ao enviar as informações.')
                  break;
                case 401:
                  this.error(message || 'Não autorizado')
                  this.injector.get(LoginService).handleLogin()
                  break;
                case 403:
                  this.error(message || 'Não autorizado.')
                  break;
                case 404:
                  this.error(message || 'Pagina não encontrada.')
                  break;
                case 422:
                  this.error(message || 'Ocorreu problemas ao filtrar o conteúdo.')
                  break;
                case 500:
                  this.error(message || 'Ocorreu um problema.')
                  break;
              }
            })
          }
          super.handleError(errorResponse)
    }

    error(message: string) {
        this.ns.error(message);
      }
}