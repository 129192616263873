import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-model',
    template: ''
})


export class ModelComponent {

    _id: string;

    // formato
    @Input() name: string;
    @Input() interval: string;
    @Input() duration: string;
    @Input() durationType: string;
    @Input() commercialType: string;
    @Input() definedProgram: boolean;
    @Input() copies: number;
    @Input() columns: any;
    @Input() width: any;
    @Input() height: any;
    @Input() size: string;
    @Input() OOHType: string;
    @Input() channel: string;
    @Input() price:  number;
    @Input() unitPrice: number;
    @Input() minimumCoverage: number;
    @Input() period: string;
    @Input() week: string;
    @Input() observation: string;
    @Input() calendar: any[];
    @Input() days: number;
    @Input() ordem: string;
    @Input() total: number;
    @Input() broadcasterPayment: number
    @Input() type: string;
    @Input() channelType: string;
    @Input() createDate: Date;
    @Input() discount: number;
    @Input() discountValue: number;
    active: boolean;
}
