
<div class="row">
  <div class="col-lg-10 col-md-10">
    <div class="card">
      <div class="card-body">
          <nav aria-label="breadcrumb" role="navigation">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a [routerLink] = "['/customers']">Clientes</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
              </ol>
            </nav>
      </div>
      <div class="card-header">
        <strong>Cliente</strong>
        <small> > Cadastro</small>
      </div>
      <div class="card-body">        
      
        <form novalidate #form="ngForm" >
          <div class="row">
            <div class="form-group col-sm-6">
              <app-input errorMessage="Campo obrigatório" label="Razão Social"> 
                <input type="text" class="form-control" [(ngModel)]="customer.socialName" name="socialName" placeholder="Preencha a razão social." required minlength="3" maxlength="256" autocomplete="off">
                
              </app-input> 
            </div>
            
            <div class="form-group col-sm-6">
              <app-input errorMessage="Campo obrigatório" label="Nome Fantasia">
                <input class="form-control" name="fantasyName" [(ngModel)]="customer.fantasyName" required minlength="3" maxlength="256" autocomplete="off" placeholder="Preencha o Nome fansatia.">
              </app-input>
            </div>
          </div>
          
          <div class="row">
            
            <div class="form-group col-sm-3">
              <app-input errorMessage="Campo obrigatório" label="CPF/CNPJ">
                <input class="form-control" name="document" [(ngModel)]="customer.document" required autocomplete="off" (blur)="onBlurMethod()" placeholder="">
              </app-input>
            </div>

            <div class="form-group col-sm-3">
              <app-input errorMessage="" label="Inscrição estadual">
                <input class="form-control" name="stateRegistration" [(ngModel)]="customer.stateRegistration" autocomplete="off" placeholder="Ex: Isento.">
              </app-input>
            </div>
            
            <div class="form-group col-sm-6">
              <app-input errorMessage="Campo obrigatório" label="E-mail">
                <input class="form-control" name="email" required [(ngModel)]="customer.email" minlength="3" autocomplete="off" pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$" placeholder="Ex: nome@empresa.com.br">
              </app-input>
            </div>
    
          </div>
          
          <div class="row">

            <div class="form-group col-sm-2">
              <app-input-zip-code label="CEP" errorMessage="">
                <input 
                  class="form-control" 
                  name="zipcode" 
                  [(ngModel)]="customer.zipcode" 
                  autocomplete="off" 
                  placeholder="Digite o Cep"
                  (keyup)="debounce.next($event.target.value)" />
              </app-input-zip-code>
            </div>
            
            <div class="form-group col-sm-6">
              <app-input label="Endereço" errorMessage="">
                <input class="form-control" name="address" [(ngModel)]="customer.address" autocomplete="off" placeholder="Ex: Avenida Paulista.">
              </app-input>
            </div>
            
            <div class="form-group col-sm-2">
              <label for="number">Número</label>
              <input type="text" class="form-control" name="number" [(ngModel)]="customer.number" autocomplete="off" placeholder="Ex: 100">
            </div>
            
            <div class="form-group col-sm-2">
              <label for="complement">Complemento</label>
              <input type="text" class="form-control" name="complement" [(ngModel)]="customer.complement" autocomplete="off" placeholder="Ex: apto 50">
            </div>
            
          </div>
          
          <div class="row">
            
            <div class="form-group col-sm-5">
              <label for="neighborhood">Bairro</label>
              <input type="text" class="form-control" name="neighborhood" [(ngModel)]="customer.neighborhood" autocomplete="off" placeholder="Ex: São Paulo">
            </div>
            
            <div class="form-group col-sm-5">
              <label for="city">Cidade</label>
              <input type="text" class="form-control" name="city" [(ngModel)]="customer.city" autocomplete="off" placeholder="Ex: São Paulo">
            </div>
            
            <div class="form-group col-sm-2">
              <app-select>
                  <select id="state" name="state" (ngModelChange)="changeUf($event)" [ngModel]="customer.state" class="form-control input-lg" size="1">
                      <option *ngFor="let uf of ufs" [value]="uf.label">{{uf.label}}</option>
                  </select>
              </app-select>   
            </div>

          </div>
          
          <div class="row">
            <fieldset class="form-group col-sm-5">
              <label>Telefone</label>
              <div class="input-group">
                <span class="input-group-prepend"><span class="input-group-text"><i class="fa fa-phone"></i></span></span>
                <input type="text" class="form-control" pattern="^[0-9]*$" [(ngModel)]="customer.phone" autocomplete="off" name="phone" mask="(99) 9999-9999 " placeholder="(99) 9999-9999">
              </div>
            </fieldset>
            <fieldset class="form-group col-sm-5">
              <label>Celular</label>
              <div class="input-group" >
                <span class="input-group-prepend"><span class="input-group-text"><i class="fa fa-phone"></i></span></span>
                <input type="text" class="form-control" [(ngModel)]="customer.cellphone" autocomplete="off" pattern="^[0-9]*$" name="cellphone" mask="(99) 9 9999-9999" placeholder="(99) 9 9999-9999">
              </div>
            </fieldset>

            <div class="form-group col-sm-2" *ngIf="actionOption === 'edit'">
                <label class="control-label" for="active">Status</label>
                <select id="active" name="active" (ngModelChange)="changeStatus($event)" [ngModel]="customer.active" class="form-control input-lg" size="1">
                    <option *ngFor="let s of statusCustomer" [value]="s.value">{{s.label}}</option>
                </select>
            </div>

          </div>
          
        </form>

        <div class="form-actions">
          <button title="Salvar cadastro" *ngIf="actionOption === 'create'" (click)="createCustomer(form.value)" [disabled]="!form.valid"  class="btn btn-primary">Salvar</button>
          <button title="Salvar cadastro" *ngIf="actionOption === 'edit'" (click)="updateCustomer()" [disabled]="!form.valid"  class="btn btn-primary">Salvar</button>


          <a [routerLink] = "['/customers']" class="btn btn-default">Voltar</a>
          
        </div>

      </div>
    </div>
  </div>
</div>