<div class="animated fadeIn">
    <!-- <ol class="breadcrumb">
      <li class="breadcrumb-item active" aria-current="page">
        Veículos
      </li>
    </ol> -->
  <div class="card">
    <div class="card-header">
      <i class="fa fa-edit"></i> Lista de Veículos 
      <div class="pull-right">
        <a [routerLink] = "['channel']" class="btn btn-primary ">Adicionar</a>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-4 offset-md-8">
          <div class="input-group center">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input type="text" class="form-control" [(ngModel)]="filterQuery" placeholder="Buscar..."/>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-success" style="margin-left: -15px" (click)="onReport()">
        <i class="fa fa-file" aria-hidden="true"></i> Exportar
      </button>
      
      <div class="row table-wrapper">
      <table class="table table-striped table-bordered datatable" [mfData]="channels | appFilter : filterQuery" #mf="mfDataTable" [mfRowsOnPage]="10">
        <thead>
          <tr>
            <th style="min-width:150px">
              <mfDefaultSorter by="socialName">Nome Fantasia</mfDefaultSorter>
            </th>
            <th style="min-width:150px">
              <mfDefaultSorter by="responsible">Reponsável</mfDefaultSorter>
            </th>
            <th style="min-width:150px">
              <mfDefaultSorter by="phone">Telefone</mfDefaultSorter>
            </th>
            <th style="min-width:150px">
              <mfDefaultSorter by="cellphone">Celular</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="email">E-mail</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="copies">Tiragem</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="period">Periodicidade</mfDefaultSorter>
            </th>
            <th style="min-width:100px">
              <mfDefaultSorter by="days">Dias</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="type">Tipo</mfDefaultSorter>
            </th>
            <th style="min-width:250px">
              <mfDefaultSorter by="address">Endereço</mfDefaultSorter>
            </th>
            <th>
              Status
            </th>
            <th>
              Opções
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let channel of mf.data">
            <td>{{channel.fantasyName}}</td>
            <td>{{channel.responsible}}</td>
            <td>{{channel.phone | telephone }}</td>
            <td>{{channel.cellphone | telephone }}</td>
            <td>{{channel.email}}</td>
            <td>{{channel.copies | number}}</td>
            <td>{{channel.period}}</td>
            <td>{{channel.days}}</td>
            <td>{{channel.type}}</td>
            <td>{{channel.address}}
                {{channel.number}} 
                {{channel.complement}} 
                {{channel.neighborhood}}
                {{channel.city}}
                {{channel.state}}
            </td>
            <td>
              <span *ngIf="channel.active===true" class="badge badge-success">Ativo</span>
              <span *ngIf="channel.active===false" class="badge badge-dark">Inativo</span>
            </td>
            <td>
              <a [routerLink]="['channel', channel._id]" title="Editar usuário" class="btn btn-info" href="#"><i class="fa fa-edit "></i></a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="12">
              <mfBootstrapPaginator [rowsOnPageSet]="[5,10,15]"></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
      </div>
    </div>
  </div>
</div>
