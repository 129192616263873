import { Component, OnInit, Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Company } from '../company.model';
import { CompanyService } from '../company.service';
import { ToasterService } from '../../toaster-service.service';
import { SelectModel } from '../../shared/select-uf/select.model';

import { ZipCodeService } from '../../shared/app-service-zipcode/zip-code.service'

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html'
})

@Injectable()
export class CompanyComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    this.debounce.unsubscribe();
  }

  debounce: Subject<string> = new Subject<string>()

  company: Company
  actionOption: string
  statusCompany: any[]
  ufs: any[]

  constructor(  private companyService: CompanyService,
                private router: Router,
                private activeRouter: ActivatedRoute,
                private toasterService: ToasterService,
                private zipCodeService: ZipCodeService) {}

  ngOnInit() {

    this.company = { _id:null, socialName:null, fantasyName:null, 
      cnpj:null, stateRegistration:null, email:null, address:null,
      number:null, complement:null, neighborhood:null, city:null, state:null,
      zipcode:null, phone:null, cellphone: null, createDate:null, active:null }

    this.statusCompany = [
      {value: 'false', label: 'Inativo' },
      {value: 'true', label: 'Ativo'}
    ]

    // carrega a lista de UFs
    this.ufs = SelectModel

    this.actionOption = 'create';

    if (this.activeRouter.snapshot.params['id'] !== undefined) {
      this.companyService.companyById(this.activeRouter.snapshot.params['id'])
        .subscribe(company => {
            this.getCompany(company)
          }, error => {
            this.error(error._body.map(m => m.message))
            console.log(error)
            this.router.navigate(['/companies'])
          })
      }
    
    this.debounce
      .pipe(debounceTime(500))
      .subscribe( zipCode =>  {
        if(zipCode !== '' && zipCode.length > 7) {

          const newZipCode = this.formatZipCode(zipCode)
          this.company.zipcode = newZipCode
          
          this.zipCodeService.findZipCode(this.company.zipcode)
            .subscribe((result) => {
              this.company.address = result.logradouro;
              this.company.complement = result.complemento;
              this.company.neighborhood = result.bairro;
              this.company.city = result.localidade;
              this.company.state = result.uf;
            }, error => console.error(error))
      }
    }, error => console.error(error))
  }

  formatZipCode(value) {

    let newValue = value.toString().replace(/\D/g,"")   //Remove tudo o que não é dígito
    newValue = newValue.replace(/(\d{5})(\d)/,"$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
    return newValue

  }

  success(message: string) {
    this.toasterService.success(message);
  }

  error(message?: string) {
    this.toasterService.error(`Ops!</br> ${message}`);
  }

  info() {
    this.toasterService.info('Empresa cadastrada com sucesso!')
  }

  warning() {
    this.toasterService.warning('Empresa', 'Ocorreram problemas.')
  }

  createCompany(formValue: Company) {

    this.companyService.create(formValue)
      .subscribe((msg: any) => {
        this.success('Cadastro realizado com sucesso!')
        this.router.navigate(['/companies'])
      }
    )
  }

  updateCompany() {

    this.companyService.update(this.company)
      .subscribe((response: any) => {
        this.success(response.message)
        this.router.navigate(['/companies'])
      }
    )
  }

  getCompany(company: Company) {
    this.actionOption = 'edit';
    this.company = company
    const zipCode = this.formatZipCode(this.company.zipcode) 
    this.company.zipcode = zipCode

    // this.company.zipcode = ('00000000' + this.company.zipcode).slice(-8)
  }

  changeStatus($event) {

    if ($event === 'false') {
      this.company.active = false
    } else {
      this.company.active = true
    }

  }

  changeUf($event: string) {
    this.company.state = $event
  }


// tslint:disable-next-line:eofline
}
