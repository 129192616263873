import { Channel } from './../../../channels/channel.model';
import { ModelComponent } from './../../../model/model.component';
import { Injectable } from '@angular/core';

@Injectable()
export class OrderChannelService {

    models: ModelComponent[] = []
    channel: Channel
    model: ModelComponent
    counter = 0
    alphaOrdem = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
                 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y', 'W', 'Z']

    constructor() {}


    getChannelType() {

        return this.channel.type
    }

    addChannel(channel: Channel) {

        if (this.channel !== undefined || this.channel !== channel) {

            this.channel = channel
            this.clearModels()
            this.clearCounter()
        }

    }

    clearModels() {

        this.models = []
    }

    addModel(model: ModelComponent) {

        this.model = Object.assign({}, model)
        this.models.push(this.model)
    }

    removeModel(model: ModelComponent) {

        this.models.splice(this.models.indexOf(model), 1)
    }

    listModels() {

        return this.models
    }

    addListModels(models: ModelComponent[]) {

        this.models = models
        
    }

    updateAlphaOrdem() {

        this.clearCounter()

        this.models.forEach(element => {
            element.ordem = this.alphaOrdem[this.counter]
            this.addCounter()
        })
    }

    addCounter() {

        this.counter ++
    }

    clearCounter() {

        this.counter = 0
    }

}
