import { Component, OnInit } from '@angular/core';

import { Supplier } from './supplier.model';
import { SupplierService } from './supplier.service';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html'
})
export class SuppliersComponent implements OnInit {

  suppliers: Supplier[] = []
  filterQuery: ''

  constructor(private supplierService: SupplierService) { }

  ngOnInit() {
      this.supplierService
        .supplier()
        .subscribe(suppliers => {this.suppliers = suppliers}, error => console.error(error)
      )
  }

}
