import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { Company } from './company.model';
import { HOST_API } from '../app.api';

@Injectable()
export class CompanyService {

    companies: Company[] = []

    constructor(private http: HttpClient) {}

    company(): Observable<Company[]> {

        return this.http.get<Company[]>(`${HOST_API}/companies`)
    }

    create(company: Company): Observable<string> {

        return this.http.post<string>(`${HOST_API}/companies`, company)
    }

    companyById(id: string): Observable<Company> {

        return this.http.get<Company>(`${HOST_API}/companies/${id}`)   
    }

    update(company: Company): Observable<string> {

        return this.http.put<string>(`${HOST_API}/companies/${company._id}`, company)
    }

    clear() {
       this.companies = []
    }
// tslint:disable-next-line:eofline
}