<div class="col-lg-12 col-md-12">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink] = "['/channels']">Veículos</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">
      Cadastro
    </li>
  </ol>
  <div class="card">
    
    <div class="card-header">
        <i class="fa fa-edit"></i>
        <strong>Cadastro de veículo </strong> 
        <small>
          <span class="pull-right">(*) Campos obrigatórios</span>
        </small>
    </div>

    <div class="card-body">     

      <form novalidate #form="ngForm" >

        <!-- Cadastro -->
        <div class="row">
          <div class="form-group col-sm-5">
            <app-input errorMessage="Campo obrigatório" label="* Razão Social"> 
              <input type="text" class="form-control" [(ngModel)]="channel.socialName" name="socialName" placeholder="Preencha a razão social." required minlength="3" maxlength="256" autocomplete="off">
            </app-input> 
          </div>
          
          <div class="form-group col-sm-4">
            <app-input errorMessage="Campo obrigatório" label="* Nome Fantasia">
              <input class="form-control" name="fantasyName" [(ngModel)]="channel.fantasyName" required minlength="3" maxlength="256" autocomplete="off" placeholder="Preencha o Nome fansatia.">
            </app-input>
          </div>
          <div class="form-group col-sm-3">
              <app-input errorMessage="Campo obrigatório" label="* CNPJ">
                <input class="form-control" name="cnpj" [(ngModel)]="channel.cnpj" required autocomplete="off" pattern="^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$" mask="99.999.999/9999-99" placeholder="Ex: 00.000.000/0001-00.">
              </app-input>
            </div>
        </div>
        
        <!-- Cadastro -->
        <div class="row">
          <div class="form-group col-sm-2">
            <app-input errorMessage="" label="Inscrição estadual">
              <input class="form-control" name="stateRegistration" [(ngModel)]="channel.stateRegistration" autocomplete="off" placeholder="Ex: Isento.">
            </app-input>
          </div>

          <div class="form-group " [ngClass]="channel.type === 'Rádio' ? 'col-sm-4' : 'col-sm-6'" >
            <app-input errorMessage="Campo obrigatório" label="* Responsável">
              <input class="form-control" name="responsible" [(ngModel)]="channel.responsible" required minlength="3" autocomplete="off" placeholder="Nome do Responsável">
            </app-input>
          </div>

          <div class="form-group col-sm-2">
            <app-input errorMessage="Campo obrigatório" label="* Tipo">
              <select id="type" name="type" required [(ngModel)]="channel.type"  (change)="changeChannelType()" class="form-control input-lg" size="1" [disabled]="actionOption == 'edit'" >
                <option [ngValue]="'OOH'" [selected]="channel.type == 'OOH'" >OOH</option>
                <option [ngValue]="'Rádio'" [selected]="channel.type == 'Rádio'" >Rádio</option>
                <option [ngValue]="'Jornal'" [selected]="channel.type == 'Jornal'" >Jornal</option>
                <option [ngValue]="'Revista'" [selected]="channel.type == 'Revista'" >Revista</option>
              </select>
            </app-input>
          </div>

          
          <div class="form-group col-sm-2">
              <app-input errorMessage="Campo obrigatório" label="População">
                  <input text class="form-control" name="population" [(ngModel)]="channel.population" currencyMask [options]="{ prefix: ' ', thousands: '.', decimal: ',' }" autocomplete="off">
              </app-input>
            </div>
  
            <div class="form-group col-sm-2" *ngIf="channel.type && channel.type === 'Rádio'" >
                <app-input errorMessage="Campo obrigatório" label="Perfil">
                    <input class="form-control" name="publicProfile" [(ngModel)]="channel.publicProfile" autocomplete="off" placeholder="Pop/Rock">
                  </app-input>
            </div>

        </div>
        
        <!-- E-mail -->
        <div class="row">

          <div class="form-group col-sm-6">
            <app-input errorMessage="Campo obrigatório" label="* E-mail">
              <input class="form-control" name="email" required [(ngModel)]="channel.email" minlength="3" autocomplete="off" pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$" placeholder="Ex: nome@empresa.com.br">
            </app-input>
          </div>

          <div class="form-group col-sm-6">
            <app-input errorMessage="" label="E-mail adicional">
              <input class="form-control" name="additionalEmail" [(ngModel)]="channel.additionalEmail" minlength="3" autocomplete="off" pattern="^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$" placeholder="Ex: nome@empresa.com.br">
            </app-input>
          </div>

        </div>

        <!-- Período -->  
        <div class="row">
          <div class="form-group col-sm-2">
            <app-input errorMessage="" label="Tiragem">
              <input type="text" class="form-control" name="copies" currencyMask [options]="{ prefix: ' ', thousands: '.', decimal: ',' }" [(ngModel)]="channel.copies" autocomplete="off">
            </app-input>
          </div>

          <div class="form-group col-sm-4">
            <app-input errorMessage="Campo obrigatório" label="Periodicidade">
              <select name="period" [(ngModel)]="channel.period"  class="form-control input-lg" size="1">                  
                <option [ngValue]="'Diario'" [selected]="channel.period == 'Diario'" >Diário</option>
                <option [ngValue]="'Semanal'" [selected]="channel.period == 'Semanal'" >Semanal</option>
                <option [ngValue]="'Bissemanal'" [selected]="channel.period == 'Bissemanal'" >Bissemanal</option>
                <option [ngValue]="'Trissemanal'" [selected]="channel.period == 'Trissemanal'" >Trissemanal</option>
                <option [ngValue]="'Mensal'" [selected]="channel.period == 'Mensal'" >Mensal</option>
                <option [ngValue]="'Bimestral'" [selected]="channel.period == 'Bimestral'" >Bimestral</option>
                <option [ngValue]="'Trimestral'" [selected]="channel.period == 'Trimestral'" >Trimestral</option>        
                <option [ngValue]="'Semestral'" [selected]="channel.period == 'Semestral'" >Semestral</option>
                <option [ngValue]="'Anual'" [selected]="channel.period == 'Anual'" >Anual</option>
              </select>
            </app-input>
          </div>

          <div class="form-group col-sm-6">
              <app-input errorMessage="" label="Dias" > 
                <input type="text" name="days" [(ngModel)]="channel.days"  class="form-control" placeholder="Ex: Seg. à Sex.''"  minlength="3" maxlength="256" >
              </app-input>
          </div>

        </div>

        <!-- Período -->

        <!-- Endereço -->
        <div class="row">
          <div class="form-group col-sm-2">

            <app-input-zip-code label="CEP" errorMessage="">
              <input 
                class="form-control" 
                name="zipcode" 
                [(ngModel)]="channel.zipcode" 
                autocomplete="off" 
                placeholder="Digite o Cep"
                (keyup)="debounce.next($event.target.value)" />
            </app-input-zip-code>

              <!-- <label for="zipcode">CEP</label>
              <input 
                type="text" 
                class="form-control" 
                name="zipcode" 
                pattern="^[0-9]*$" 
                [(ngModel)]="channel.zipcode" 
                autocomplete="off" 
                mask="99999-999" 
                placeholder="Ex: 06606-120"
                (keyup)="debounce.next($event.target.value)"> -->
          </div>
          
          <div class="form-group col-sm-6">
            <app-input label="Endereço" errorMessage="">
              <input class="form-control" name="address" [(ngModel)]="channel.address" autocomplete="off" placeholder="Ex: Avenida Paulista.">
            </app-input>
          </div>
          
          <div class="form-group col-sm-2">
            <label for="number">Número</label>
            <input type="text" class="form-control" name="number" [(ngModel)]="channel.number" autocomplete="off" placeholder="Ex: 100">
          </div>
          
          <div class="form-group col-sm-2">
            <label for="complement">Complemento</label>
            <input type="text" class="form-control" name="complement" [(ngModel)]="channel.complement" autocomplete="off" placeholder="Ex: apto 50">
          </div>
          
        </div>
        
        <!-- Endereço -->
        <div class="row">
          
          <div class="form-group col-sm-5">
            <label for="neighborhood">Bairro</label>
            <input type="text" class="form-control" name="neighborhood" [(ngModel)]="channel.neighborhood" autocomplete="off" placeholder="Ex: São Paulo">
          </div>
          
          <div class="form-group col-sm-5">
            <label for="city">Cidade</label>
            <input type="text" class="form-control" name="city" [(ngModel)]="channel.city" autocomplete="off" placeholder="Ex: São Paulo">
          </div>
          
          <div class="form-group col-sm-2">
            <app-select>
                <select id="state" name="state" (ngModelChange)="changeUf($event)" [ngModel]="channel.state" class="form-control input-lg" size="1">
                    <option *ngFor="let uf of ufs" [value]="uf.label">{{uf.label}}</option>
                </select>
            </app-select>   
          </div>

        </div>
        
        <!-- Telefones -->
        <div class="row">

          <fieldset class="form-group col-sm-3">
            <label>Telefone</label>
            <div class="input-group">
              <span class="input-group-prepend"><span class="input-group-text"><i class="fa fa-phone"></i></span></span>
              <input type="text" class="form-control" pattern="^[0-9]*$" [(ngModel)]="channel.phone" autocomplete="off" name="phone" mask="(99) 9999-9999" placeholder="(999) 9999-9999">
            </div>
          </fieldset>

          <fieldset class="form-group col-sm-3">
            <label>Celular</label>
            <div class="input-group" >
              <span class="input-group-prepend"><span class="input-group-text"><i class="fa fa-phone"></i></span></span>
              <input type="text" class="form-control" [(ngModel)]="channel.cellphone" autocomplete="off" pattern="^[0-9]*$" name="cellphone" mask="(99) 9 9999-9999" placeholder="(999) 9 9999-9999">
            </div>
          </fieldset>

          <fieldset class="form-group col-sm-3">
            <label>Telefone adicional</label>
            <div class="input-group">
              <span class="input-group-prepend"><span class="input-group-text"><i class="fa fa-phone"></i></span></span>
              <input type="text" class="form-control" pattern="^[0-9]*$" [(ngModel)]="channel.additionalPhone" autocomplete="off" name="additionalPhone" mask="(99) 9999-9999" placeholder="(99) 9999-9999">
            </div>
          </fieldset>
          
          <fieldset class="form-group col-sm-3">
            <label>Celular adicional</label>
            <div class="input-group" >
              <span class="input-group-prepend"><span class="input-group-text"><i class="fa fa-phone"></i></span></span>
              <input type="text" class="form-control" [(ngModel)]="channel.additionalCellphone" autocomplete="off" pattern="^[0-9]*$" name="additionalCellphone" mask="(99) 9 9999-9999" placeholder="(999) 9 9999-9999">
            </div>
          </fieldset>
          
        </div>   

        <!-- Observações -->
        <div class="row">
          <fieldset class="form-group col-sm-6">
            <label>Abrangência</label>
            <div class="input-group">
              <textarea class="form-control" [(ngModel)]="channel.coverage" name="coverage" id="coverage"  cols="60" rows="4"></textarea>
            </div>
          </fieldset>
          <fieldset class="form-group col-sm-6">
            <label>Observações</label>
            <div class="input-group">
              <textarea class="form-control" [(ngModel)]="channel.description" name="description" id="description"  cols="60" rows="4"></textarea>
            </div>
          </fieldset>
        </div>

        <!-- Status -->
        <div class="row">
          <div class="form-group col-sm-2" *ngIf="actionOption === 'edit'">
            <label class="control-label" for="active">Status</label>
            <select id="active" name="active" (ngModelChange)="changeStatus($event)" [ngModel]="channel.active" class="form-control input-lg" size="1">
                <option *ngFor="let s of statusChannel" [value]="s.value">{{s.label}}</option>
            </select>
          </div>
        </div>
        
      </form>

      <div class="form-actions">
        <button title="Salvar cadastro" *ngIf="actionOption === 'create'" (click)="createChannel(form.value)" [disabled]="!form.valid"  class="btn btn-primary">Salvar</button>
        <button title="Salvar cadastro" *ngIf="actionOption === 'edit'" (click)="updateChannel()" [disabled]="!form.valid"  class="btn btn-primary">Salvar</button>
        <a [routerLink] = "['/channels']" class="btn btn-default">Voltar</a>
      </div>

    </div>
  </div>

  

  <div class="card" *ngIf="actionOption && actionOption === 'edit'">
      <div class="card-header">
        <strong>Tabela de Preços</strong>
        <small>
          <span class="pull-right">(*) Campos obrigatórios</span>
        </small>
      </div>
      <div class="card-body">    
        
        <!-- Cadastro de Peças -->
        <form novalidate #formModel="ngForm"class="row" class="form-horizontal">

          <div class="row">

            <div class="form-group col-sm-4">
                <app-input errorMessage="Campo obrigatório" label="Tipo" >
                  <input type="text" name="model.type" [(ngModel)]="model.type" [disabled]="true" class="form-control"  class="form-control input-lg">
                </app-input>
            </div> 

            <div class="form-group col-sm-8">
                <app-input errorMessage="Campo obrigatório." label="* Nome" > 
                  <input type="text" name="name" required [(ngModel)]="model.name" class="form-control"  class="form-control" placeholder="Ex: Rotativo 30''"  minlength="3" maxlength="256" >
                </app-input>
            </div>

          </div>
            
          <!-- Rádio -->
          <div class="row" *ngIf="model.type && model.type === 'Rádio' " >

            <div class="form-group col-sm-3">
              <app-input errorMessage="Tipo do comercial inválido." label="* Tipo do comercial" > 
                  <select id="commercialType" name="commercialType" [(ngModel)]="model.commercialType" class="form-control input-lg" size="1" [required]="model.type == 'Rádio'">
                      <option [ngValue]="'Spot'" [selected]="model.commercialType == 'Spot'" >Spot</option>
                      <option [ngValue]="'Testemunhal'" [selected]="model.commercialType == 'Testemunhal'" >Testemunhal</option>
                      <option [ngValue]="'Blitz'" [selected]="model.commercialType == 'Blitz'" >Blitz</option>
                      <option [ngValue]="'Patrocinio'" [selected]="model.commercialType == 'Patrocinio'" >Patrocínio</option>
                    </select>
              </app-input>
          </div>

            <div class="form-group col-sm-3" >
                <app-input errorMessage="Intervalo inválido." label="* Intervalo" > 
                  <input type="text" name="interval" [(ngModel)]="model.interval" id="interval" class="form-control" size="16" placeholder="Ex: 06h00 - 19h00" [required]="model.type == 'Rádio'" minlength="3" maxlength="256">
                </app-input>
            </div>


            <div class="form-group col-sm-3">
                <app-input errorMessage="Duração inválida." label="* Duração" > 
                  <input type="text" name="duration" [(ngModel)]="model.duration" id="duration" class="form-control" size="16"  [required]="model.type == 'Rádio'" minlength="1" maxlength="1000">
                </app-input>
            </div>


            <div class="form-group col-sm-3">
              <app-input errorMessage="Valor inválido." label="* Valor" > 
                <input name="price" currencyMask [(ngModel)]="model.price" id="price" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" class="form-control" size="16" placeholder="Ex: 100,00" [required]="model.type == 'Rádio'" minlength="3" maxlength="256">
              </app-input>
            </div>

            <div class="form-group col-sm-2">
              <label class="form-check-label" style="padding-left:20px">
                <app-input errorMessage="" label="" > 
                    <input type="checkbox" class="form-check-input" id="definedProgram" name="definedProgram" [(ngModel)]="model.definedProgram" />
                    Programa definido
                </app-input>
              </label>
            </div>

              <div class="form-group col-sm-4" [hidden]="!model.definedProgram" >
                  <app-input errorMessage="Nome do programa inválido." label="* Nome do programa" > 
                    <input type="text" name="radioProgram" [(ngModel)]="model.radioProgram" id="radioProgram" class="form-control" size="16"  [required]="model.definedProgram">
                  </app-input>
              </div>

              <div class="form-group col-sm-4" [hidden]="!model.definedProgram" >
                  <app-input errorMessage="Nome do locutor inválido." label="* Nome do locutor" > 
                    <input type="text" name="broadcaster " [(ngModel)]="model.broadcaster " id="broadcaster " class="form-control" size="16"  [required]="model.definedProgram">
                  </app-input>
              </div>

              <div class="form-group col-sm-2" [hidden]="!model.definedProgram" >
                  <app-input errorMessage="Cachê do Locutor inválido." label="* Cachê do Locutor" > 
                    <input name="broadcasterPayment" currencyMask [(ngModel)]="model.broadcasterPayment" id="broadcasterPayment" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control" size="16" placeholder="Ex: 100,00" required="model.definedProgram">
                  </app-input>
              </div>

              

          </div>

          <!-- Jornal-->
          <div class="row" *ngIf="model.type && model.type === 'Jornal' ">

            <div class="form-group col-sm-2" >
              <app-input errorMessage="Nº de colunas inválido." label="* Colunas" > 
                <input type="text" name="columns" [(ngModel)]="model.columns" (ngModelChange)="getColumns($event)" id="columns" class="form-control" size="16" [required]="model.type == 'Jornal'" minlength="1">
              </app-input>
            </div>

            <div class="form-group col-sm-2" >
              <app-input errorMessage="Largura inválida." label="* Largura" > 
                <input type="text" name="width" [(ngModel)]="model.width" id="width" class="form-control" size="16" (ngModelChange)="getWidth($event)" [required]="model.type == 'Jornal'" minlength="1">
              </app-input>                      
            </div>

            <div class="form-group col-sm-2" >
                <app-input errorMessage="Altura inválida." label="* Altura" > 
                  <input type="text" name="height" [(ngModel)]="model.height" id="height" (ngModelChange)="getHeight($event)" class="form-control" size="16" [required]="model.type == 'Jornal'">
                </app-input>                      
            </div>

            <div class="form-group col-sm-3">
              <app-input errorMessage="Valor inválido." label="* R$ indeterminado" > 
                <input name="price" currencyMask [(ngModel)]="model.price" (ngModelChange)="getPrice($event)" id="price" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" class="form-control" size="16" placeholder="Ex: 100,00" [required]="model.type == 'Jornal'" >
              </app-input>
            </div>

            <div class="form-group col-sm-3">
              <app-input errorMessage="Valor Unitário." label="* Custo de (1) página" > 
                <input name="unitPrice" currencyMask [(ngModel)]="model.unitPrice" id="unitPrice" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control" size="16" placeholder="Ex: 100,00" [required]="model.type == 'Jornal'" readonly >
              </app-input>
            </div>

          </div>

          <!-- Revista -->
          <div class="row" *ngIf="model.type && model.type === 'Revista' ">

            <div class="form-group col-sm-4" >
              <app-input errorMessage="Largura inválida." label="* Largura" > 
                <input type="text" name="width" [(ngModel)]="model.width" (ngModelChange)="getWidth($event)" id="width" class="form-control" size="16" [required]="model.type == 'Revista'" minlength="1">
              </app-input>                      
            </div>

            <div class="form-group col-sm-4" >
                <app-input errorMessage="Altura inválida." label="* Altura" > 
                  <input type="text" name="height" [(ngModel)]="model.height" (ngModelChange)="getHeight($event)" id="height" class="form-control" size="16" [required]="model.type == 'Revista'" minlength="1">
                </app-input>                      
            </div>

            <div class="form-group col-sm-4">
              <app-input errorMessage="Valor inválido." label="* R$ Página indeterminada" > 
                <input name="price" currencyMask [(ngModel)]="model.price" id="price" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control" size="16" placeholder="Ex: 100,00" [required]="model.type == 'Revista'" minlength="3" maxlength="256">
              </app-input>
            </div>
          
          </div>

          <!-- OOH-->
          <div class="row" *ngIf="model.type && model.type === 'OOH' ">

            <div class="form-group col-sm-2" >
              <app-input errorMessage="Medida inválido." label="* Medida" > 
                <input type="text" name="size" [(ngModel)]="model.size" id="size" class="form-control" size="16" placeholder="Ex: 9 x 3" [required]="model.type == 'OOH'">
              </app-input>
            </div>

            <div class="form-group col-sm-4">
              <app-input errorMessage="Tipo inválido." label="* Opção de OOH" > 
                  <select id="OOHType" name="OOHType" [(ngModel)]="model.OOHType" class="form-control input-lg" size="1" [required]="model.type == 'OOH'" >
                      <option [ngValue]="'Outdoor'" [selected]="model.OOHType == 'Outdoor'" >Outdoor</option>
                      <option [ngValue]="'Frontlight'" [selected]="model.OOHType == 'Frontlight'" >Frontlight</option>
                      <option [ngValue]="'Backlight'" [selected]="model.OOHType == 'Backlight'" >Backlight</option>
                      <option [ngValue]="'Painel Luminoso'" [selected]="model.OOHType == 'Painel Luminoso'" >Painel Luminoso</option>
                      <option [ngValue]="'Empenas'" [selected]="model.OOHType == 'Empenas'" >Empenas</option>
                      <option [ngValue]="'Triedro'" [selected]="model.OOHType == 'Triedro'" >Triedro</option>
                      <option [ngValue]="'Led'" [selected]="model.OOHType == 'Led'" >Painel de Led</option>
                      <option [ngValue]="'Outros'" [selected]="model.OOHType == 'Outros'" >Outros</option>
                    </select>
              </app-input>
            </div>

            <div class="form-group col-sm-2">
              <app-input errorMessage="Cobertura mínima inválida." label="* Cobertura mínima" > 
                <input type="number" name="minimumCoverage" [(ngModel)]="model.minimumCoverage" (ngModelChange)="getMinimumCoverage($event)" id="minimumCoverage" class="form-control" size="16" placeholder="Ex: 15" minlength="3" required maxlength="1000">
              </app-input>
            </div>

            <div class="form-group col-sm-2">
              <app-input errorMessage="Valor inválido." label="Valor unitário" > 
                <input name="price" currencyMask [(ngModel)]="model.price" (ngModelChange)="getPrice($event)" id="price" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" class="form-control" size="16" placeholder="Ex: 100,00" required minlength="3" maxlength="256">
              </app-input>
            </div>

            <div class="form-group col-sm-2">
                <app-input errorMessage="Valor total mínimo." label="* Valor total mínimo" > 
                  <input name="unitPrice" currencyMask [(ngModel)]="model.unitPrice" id="unitPrice" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control" size="16" placeholder="Ex: 100,00" required minlength="3" readonly [disabled]="true" >
                </app-input>
              </div>

          </div>

           <!-- Todos -->
           <div class="row">
            <fieldset class="form-group col-sm-6">
                <label>Observações</label>
                <div class="input-group">
                  <textarea class="form-control" [(ngModel)]="model.observation" name="observation" id="observation"  cols="60" rows="5"></textarea>
                </div>
              </fieldset>
          </div>

          <div class="form-actions">
            <button (click)="createModel()" class="btn btn-primary">Adicionar item</button>
            <br><br>
          </div>
        </form>
        
        <!-- Tabela vazia-->
        <div class="row" *ngIf="models?.length === 0" >
          <div class="col-lg-12">
            <p>Não existem preços cadastrados.</p>
          </div>
        </div>
        
        <!-- Tabela de preços-->
        <div class="row table-wrapper" *ngIf="models?.length > 0"  >
          
            <fieldset class="form-group col-sm-12">

              <!-- Rádio -->
              <table *ngIf="model.type && model.type === 'Rádio'" class="table table-striped table-bordered datatable" [mfData]="models" #mf="mfDataTable" [mfRowsOnPage]="10">
                  <thead>
                    <tr>
      
                      <th style="width: 27%">
                        <mfDefaultSorter by="name">Nome</mfDefaultSorter>
                      </th>

                      <th style="width: 5%">
                        <mfDefaultSorter by="commercialType">Tipo</mfDefaultSorter>
                      </th>

                      <th style="width: 10%">
                        <mfDefaultSorter by="duration">Duração</mfDefaultSorter>
                      </th>

                      <th style="width: 5%">
                          <mfDefaultSorter by="price">Preço</mfDefaultSorter>
                      </th>

                      <th style="width: 5%">
                        <mfDefaultSorter by="definedProgram">Prog. definido</mfDefaultSorter>
                      </th>

                      <th style="width: 5%">
                        <mfDefaultSorter by="radioProgram">Programa</mfDefaultSorter>
                      </th>

                      <th style="width: 5%">
                        <mfDefaultSorter by="broadcaster">Locutor</mfDefaultSorter>
                      </th>

                      <th style="width: 5%">
                        <mfDefaultSorter by="broadcasterPayment">Cachê</mfDefaultSorter>
                      </th>

                      <th style="width: 7%">
                        <mfDefaultSorter by="observation">Observações</mfDefaultSorter>
                      </th>

                      <th style="width: 3%">Remover</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of mf.data">
                      <td>{{item.name}}</td>
                      <td>{{item.commercialType}}</td>
                      <td>{{item.duration}} | {{item.interval}}</td>
                      <td><input name="item.price" readonly currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="item.price"></td>
                      <td>{{ item.definedProgram == true ? 'Sim' :  'Não' }}</td>
                      <td>{{ item.radioProgram}}</td>
                      <td>{{ item.broadcaster }}</td>
                      <td><input name="item.broadcasterPayment" readonly currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="item.broadcasterPayment"></td>
                      <td>{{ item.observation }}</td>
                      <td>
                          <span class="btn btn-danger" title="Remover Peça" (click)="removeModel(item._id)"><i class="fa fa-remove"></i></span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="12">
                        <mfBootstrapPaginator [rowsOnPageSet]="[5,10,15]"></mfBootstrapPaginator>
                      </td>
                    </tr>
                  </tfoot>
              </table>

                <!-- Revista  -->
              <table *ngIf="model.type && model.type === 'Revista'" class="table table-striped table-bordered datatable" [mfData]="models" #mf="mfDataTable" [mfRowsOnPage]="10">
                <thead>
                  <tr>
    
                    <th style="width: 20%">
                      <mfDefaultSorter by="name">Nome</mfDefaultSorter>
                    </th>

                    <th style="width: 3%">
                        <mfDefaultSorter by="width">Larg.</mfDefaultSorter>
                    </th>

                    <th style="width: 3%">
                        <mfDefaultSorter by="height">Alt.</mfDefaultSorter>
                    </th>
                    
                    <th style="width: 5%">
                        <mfDefaultSorter by="price">R$ Página indeterminada</mfDefaultSorter>
                    </th>

                    <th style="width: 15%">
                        <mfDefaultSorter by="observation">Observações</mfDefaultSorter>
                    </th>

                    <th style="width: 5%">Remover</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of mf.data">
                    <td>{{item.name}}</td>
                    <td>{{item.width}} </td>
                    <td>{{item.height}} </td>
                    <td><input name="item.price" readonly currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="item.price" [disabled]="true"></td>
                    <td>{{item.observation}}</td>
                    <td>
                        <span class="btn btn-danger" title="Remover Peça" (click)="removeModel(item._id)"><i class="fa fa-remove"></i></span>
                      </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="12">
                      <mfBootstrapPaginator [rowsOnPageSet]="[5,10,15]"></mfBootstrapPaginator>
                    </td>
                  </tr>
                </tfoot>
              </table>
              
              <!-- Jornal -->
              <table *ngIf="model.type && model.type === 'Jornal'" class="table table-striped table-bordered datatable" [mfData]="models" #mf="mfDataTable" [mfRowsOnPage]="10">
                <thead>
                  <tr>
    
                    <th style="width: 30%">
                      <mfDefaultSorter by="name">Nome</mfDefaultSorter>
                    </th>

                    <th style="width: 3%">
                        <mfDefaultSorter by="columns">Col.</mfDefaultSorter>
                    </th>

                    <th style="width: 3%">
                        <mfDefaultSorter by="width">Larg.</mfDefaultSorter>
                    </th>

                    <th style="width: 3%">
                        <mfDefaultSorter by="height">Alt.</mfDefaultSorter>
                    </th>
                    
                    <th style="width: 3%">
                        <mfDefaultSorter by="price">R$ indeterminado</mfDefaultSorter>
                    </th>

                    <th style="width: 3%">
                      <mfDefaultSorter by="unitPrice">Custo de (1) página</mfDefaultSorter>
                    </th>

                    <th style="width: 25%">
                        <mfDefaultSorter by="observation">Observações</mfDefaultSorter>
                    </th>

                    <th style="width: 5%">Remover</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of mf.data">
                    <td>{{item.name}}</td>
                    <td>{{item.columns}} </td>
                    <td>{{item.width}} </td>
                    <td>{{item.height}} </td>
                    <td><input name="item.price" style="text-align: right;width: 110px;" readonly currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="item.price" [disabled] = "true"></td>
                    <td><input name="item.unitPrice" style="text-align: right;width: 110px;" readonly currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="item.unitPrice"></td>
                    <td>{{ item.observation }}</td>
                    <td>
                        <span class="btn btn-danger" title="Remover Peça" (click)="removeModel(item._id)"><i class="fa fa-remove"></i></span>
                      </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="12">
                      <mfBootstrapPaginator [rowsOnPageSet]="[5,10,15]"></mfBootstrapPaginator>
                    </td>
                  </tr>
                </tfoot>
              </table>

              <!-- OOH -->
              <table *ngIf="model.type && model.type === 'OOH'" class="table table-striped table-bordered datatable" [mfData]="models" #mf="mfDataTable" [mfRowsOnPage]="10">
              <thead>
                <tr>
  
                  <th style="width: 20%">
                    <mfDefaultSorter by="name">Nome</mfDefaultSorter>
                  </th>

                  <th style="width: 5%">
                      <mfDefaultSorter by="size">Medida</mfDefaultSorter>
                  </th>

                  <th style="width: 5%">
                      <mfDefaultSorter by="OOHType">Opção</mfDefaultSorter>
                  </th>

                  <th style="width: 5%">
                      <mfDefaultSorter by="minimumCoverage">Cobertura mínima</mfDefaultSorter>
                  </th>

                  <th style="width: 5%">
                      <mfDefaultSorter by="price">Valor unitário</mfDefaultSorter>
                  </th>

                  <th style="width: 5%">
                    <mfDefaultSorter by="unitPrice">Valor total mínimo</mfDefaultSorter>
                  </th>
                    
                  <th style="width: 5%">
                    <mfDefaultSorter by="observation">Observações</mfDefaultSorter>  
                  </th>
                 
                  <th style="width: 5%">Remover</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of mf.data">              
                  <td>{{item.name}}</td>
                  <td>{{item.size}} </td>
                  <td>{{item.OOHType}} </td>
                  <td>{{ item.minimumCoverage }} </td>
                  <td><input name="item.price" readonly currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" [(ngModel)]="item.price"></td>
                  <td><input name="item.unitPrice" readonly currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="item.unitPrice" [disabled]="true"></td>
                  <td> {{ item.observation }}</td>
                  <td>
                      <span class="btn btn-danger" title="Remover Peça" (click)="removeModel(item._id)"><i class="fa fa-remove"></i></span>
                    </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="12">
                    <mfBootstrapPaginator [rowsOnPageSet]="[5,10,15]"></mfBootstrapPaginator>
                  </td>
                </tr>
              </tfoot>
              </table>

            </fieldset>
        </div>

    </div>
  </div>

  
</div>
