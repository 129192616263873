import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ModelComponent } from './../../../model/model.component';



@Component({
  selector: 'app-order-model',
  templateUrl: './order-model.component.html'
})
export class OrderModelComponent implements OnInit {

  @Input() models: ModelComponent[]
  @Input() type: string
  @Output() onRemove = new EventEmitter<ModelComponent>()
  @Output() onChangeName = new EventEmitter<ModelComponent>()

  constructor() { }

  ngOnInit() {
  }

  emitRemove(item: ModelComponent) {
    this.onRemove.emit(item)
  }

  emitChangeName(item: ModelComponent) {
    this.onChangeName.emit(item)
  }

}
