import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from './containers';
import { UserListComponent } from './user/user-list.component';
import { UserRegisterComponent } from './user/user-register.component';
import { UserChangePassComponent } from './user/user-change-pass.component';
import { UserEditComponent } from './user/user-edit.component';
import { ModelListComponent } from './model/model-list.component';
import { ModelRegisterComponent } from './model/model-register.component';
import { ModelEditComponent } from './model/model-edit.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyComponent } from './companies/company/company.component';
import { ChannelsComponent } from './channels/channels.component';
import { ChannelComponent } from './channels/channel/channel.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomerComponent } from './customers/customer/customer.component';
import { AgenciesComponent } from './agencies/agencies.component';
import { AgencyComponent } from './agencies/agency/agency.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { SupplierComponent } from './suppliers/supplier/supplier.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ConfirmResetPasswordComponent } from './confirm-reset-password/confirm-reset-password.component';
import { OrdersComponent } from './orders/orders.component'
import { OrderComponent } from './orders/order/order.component';


// Guard routers
import {GuardRouter} from './guards/guard.router';
import {GuardRouterChild} from './guards/guard.router.child';
import {GuardRouterConfirmedPassword} from './guards/guard.router.confirmedPassword';

import { OrdersResolver } from './orders/order-resolver'

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {
    path: 'dashboard',
    component: FullLayoutComponent,
    canActivate: [GuardRouter],
    canActivateChild: [GuardRouterChild],
    data: {
      breadcrumb: ''
    },
  },
  { path: 'users', component: FullLayoutComponent,
    canActivate: [GuardRouter],
    canLoad: [GuardRouter],
    canActivateChild: [GuardRouterChild],
    children: [
      { path: '', component: UserListComponent},
      { path: 'register', component: UserRegisterComponent},
      { path: 'edit/:id', component: UserEditComponent},
      { path: 'change-password/:id', component: UserChangePassComponent}
    ]
  },
  { path: 'models', component: FullLayoutComponent,
    canActivate: [GuardRouter],
    canLoad: [GuardRouter],
    canActivateChild: [GuardRouterChild],
    children: [
      { path: '', component: ModelListComponent },
      { path: 'register', component: ModelRegisterComponent },
      { path: 'edit/:id', component: ModelEditComponent }
    ]
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: {
      breadcrumb: 'Dashboard'
    },
    canActivate: [GuardRouter],
    canLoad: [GuardRouter],
    canActivateChild: [GuardRouterChild],
    children: [
      { path: 'companies', component: CompaniesComponent, data: { breadcrumb: 'Empresas' }},
      { path: 'companies/company', component: CompanyComponent, data: { breadcrumb: 'Adicionar' }},
      { path: 'companies/company/:id', component: CompanyComponent}
    ]
  },
  { path: '', component: FullLayoutComponent,
    canActivate: [GuardRouter],
    canLoad: [GuardRouter],
    canActivateChild: [GuardRouterChild],
    children: [
      { path: 'channels', component: ChannelsComponent},
      { path: 'channels/channel', component: ChannelComponent},
      { path: 'channels/channel/:id', component: ChannelComponent}
    ]
  },
  { path: '', component: FullLayoutComponent,
    canActivate: [GuardRouter],
    canLoad: [GuardRouter],
    canActivateChild: [GuardRouterChild],
    children: [
      { path: 'customers', component: CustomersComponent},
      { path: 'customers/customer', component: CustomerComponent},
      { path: 'customers/customer/:id', component: CustomerComponent}
    ]
  },
  { path: '', component: FullLayoutComponent,
    canActivate: [GuardRouter],
    canLoad: [GuardRouter],
    canActivateChild: [GuardRouterChild],
    children: [
      { path: 'agencies', component: AgenciesComponent},
      { path: 'agencies/agency', component: AgencyComponent},
      { path: 'agencies/agency/:id', component: AgencyComponent}
    ]
  },
  { path: '', component: FullLayoutComponent,
    data: {
      breadcrumb: ''
    },
    canActivate: [GuardRouter],
    canLoad: [GuardRouter],
    canActivateChild: [GuardRouterChild],
    children: [
      {
        path: 'suppliers',
        component: SuppliersComponent,
        data: {
          breadcrumb: 'Fornecedores'
        }
      },
      {
        path: 'suppliers/supplier',
        component: SupplierComponent,
        data: {
          breadcrumb: 'Adicionar'
        }
      },
      {
        path: 'suppliers/supplier/:id',
        component: SupplierComponent,
        data: {
          breadcrumb: 'Editar'
        }
      }
    ]
  },
  { path: 'login', component: LoginComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'confirm-reset-password', component: ConfirmResetPasswordComponent, canActivate: [GuardRouterConfirmedPassword]},
  { path: '', component: FullLayoutComponent,
    canActivate: [GuardRouter],
    canLoad: [GuardRouter],
    canActivateChild: [GuardRouterChild],
    children: [
      {
        path: 'orders',
        component: OrdersComponent,
        resolve: {
          orders: OrdersResolver
        }
      },
      { path: 'orders/order', component: OrderComponent},
      { path: 'orders/order/:id', component: OrderComponent}
    ]
  }

];

@NgModule({
  imports: [ RouterModule.forRoot(routes)],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
