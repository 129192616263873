<div class="animated fadeIn">
  <div class="card">
    <div class="card-header">
      <i class="fa fa-edit"></i> Fornecedores 
      <div class="pull-right">
        <a [routerLink] = "['supplier']" class="btn btn-primary ">Adicionar</a>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-4 offset-md-8">
          <div class="input-group center">
            <span class="input-group-addon"><i class="fa fa-search"></i></span>
            <input type="text" class="form-control" [(ngModel)]="filterQuery" placeholder="Buscar..."/>
          </div>
        </div>
      </div>
      <table class="table table-striped table-bordered datatable" [mfData]="suppliers | appFilter : filterQuery" #mf="mfDataTable" [mfRowsOnPage]="10">
        <thead>
          <tr>
            <th>
              <mfDefaultSorter by="socialName">Razão social</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="createDate">Data de cadastro</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="document">CPF/CNPJ</mfDefaultSorter>
            </th>
            <th>
              <mfDefaultSorter by="email">E-mail</mfDefaultSorter>
            </th>
            <th>
              Status
            </th>
            <th>
              Opções
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let supplier of mf.data">
            <td>{{supplier.socialName}}</td>
            <td>{{supplier.createDate | date: 'dd/MM/yyyy'}}</td>
            <td>{{supplier.document}}</td>
            <td>{{supplier.email}}</td>
            <td>
              <span *ngIf="supplier.active===true" class="badge badge-success">Ativo</span>
              <span *ngIf="supplier.active===false" class="badge badge-dark">Inativo</span>
            </td>
            <td>
              <a [routerLink]="['supplier', supplier._id]" title="Editar usuário" class="btn btn-info" href="#"><i class="fa fa-edit "></i></a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6">
              <mfBootstrapPaginator [rowsOnPageSet]="[5,10,15]"></mfBootstrapPaginator>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>
