import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Order } from './order.model'
import { OrderService } from './order.service';

import {saveAs as importedSaveAs} from 'file-saver';
import {AppFilterSearch} from './../shared/app-filter-search/app-filter-search.model'

import * as moment from 'moment';

moment.locale('pt-BR');


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html'
})

export class OrdersComponent implements OnInit {

  url: any
  path: any
  orders: Order[] = []
  mfAmountOfRows: number
  rowsSet: number=10
  countPagination: number
  restOfDivision: number
  division: number
  ListPagination: Array<number> = []
  nextPage: number = 10
  prevPage:number = 1
  totalPagination: number = 10
  page: number = 1
  appFilterSearch: AppFilterSearch
  dateOfIssueOn: string = ''
  dateOfIssueEnd: string = ''
  values = {}

  vComissao: number = 0
  vTotalBruto: number = 0
  vComissaoAgency: number = 0
  vLiqRadio: number = 0


  constructor(  private activatedRoute: ActivatedRoute, 
                private orderService: OrderService) { }

  ngOnInit(): void {

    this.orders = this.activatedRoute.snapshot.data['orders'][0];
    this.mfAmountOfRows = this.activatedRoute.snapshot.data['orders'][1];
    this.values = this.activatedRoute.snapshot.data['orders'][2];
    
    this.onCalculationPages(this.prevPage,this.nextPage)
    this.onValues()
  
  }

  onValues = () => {

    this.vComissao = this.values['vComissao']
    this.vTotalBruto = this.values['vTotalBruto']
    this.vComissaoAgency = this.values['vComissaoAgency']
    this.vLiqRadio = this.values['vLiqRadio']
  }


  createPDF(id: string) {

    const fileName = `pi-${id}.pdf`
    this.orderService
      .createPDF(id)
      .subscribe( blob => {
        importedSaveAs(blob, fileName)
    })
  }

  onPageChange(event:number) {

    if ((this.dateOfIssueOn && this.dateOfIssueOn.length > 0) || (this.dateOfIssueEnd && this.dateOfIssueEnd.length > 0)) {

      const {dateHourOn, dateHourEnd} = this.orderService.onFormatDate(this.dateOfIssueOn, this.dateOfIssueEnd)
      this.appFilterSearch.dateOfIssueOn = dateHourOn
      this.appFilterSearch.dateOfIssueEnd = dateHourEnd
    }

    this.page = this.page !== event? event: this.page

    this.orderService
      .get(event,this.rowsSet, this.appFilterSearch).subscribe(data => {
          this.orders = data[0];
          this.mfAmountOfRows = data[1];
          this.values = data[2];
          
          this.onCalculationPages(this.prevPage, this.nextPage)
          this.onValues()
        })
    
  }

  onNextPage() {

    this.prevPage = this.nextPage + 1
    this.nextPage = this.nextPage + this.totalPagination
    this.nextPage = this.countPagination < this.nextPage ? this.countPagination : this.nextPage
    this.page = this.prevPage

    this.onPageChange(this.page)
    this.onCalculationPages(this.prevPage, this.nextPage)
  }

  onPrevPage() {

    if (this.nextPage === this.countPagination) {
      this.nextPage = this.prevPage - 1
      this.prevPage = this.prevPage - this.totalPagination
      
    } else {

      this.nextPage = this.nextPage - this.totalPagination
      this.prevPage = this.prevPage - this.totalPagination

    }
    
    this.page = this.nextPage
    
    this.onPageChange(this.page)
    this.onCalculationPages(this.prevPage, this.nextPage)
  }

  onCalculationPages(prev: number, next:number) {

    this.ListPagination = []
    this.restOfDivision = this.mfAmountOfRows%this.rowsSet
    this.division = Math.trunc(this.mfAmountOfRows / this.rowsSet) 
    this.countPagination = this.restOfDivision>0? this.division + 1 : this.division
    
    for(let i = prev; i<= this.countPagination; i ++) {
      if (i > next) break;
      this.ListPagination.push(i)
    }
    
  }

  onChangeFilter(event: AppFilterSearch) {
    
    this.dateOfIssueOn =  (event.dateOfIssueOn) ? this.orderService.getFilterDate(new Date(event.dateOfIssueOn)) : null
    this.dateOfIssueEnd =  (event.dateOfIssueEnd) ? this.orderService.getFilterDate(new Date(event.dateOfIssueEnd)) : null
    this.appFilterSearch = event
    
    this.getDefaultPaginator()
    this.onPageChange(this.page)

  }

  getDefaultPaginator() {

    this.page = 1
    this.rowsSet = 10
    this.nextPage = 10
    this.prevPage = 1

  }

  onReport() {
    

    this.orderService.report(this.appFilterSearch).subscribe(data => {
            
      var report = JSON.parse(JSON.stringify(data.result, ["companyFantasyName", "billedCompany", "customerCampaign", "numberOrder", "type", "placementMonth", "placementYear", "customerFantasyName","channelFantasyName", "channelType", "dateOfIssue", "expirationDate", "vTotalBruto", "vLiqRadio", "vComissaoAgency", "vComissao"],4));

      report.forEach(element => {
        
        element.billedCompany= element.billedCompany === true ? "Sim" : "Não"  

        element.type= element.type === 1 ? "Comissinado" : "A Faturar"  
        
        const newDateOfIssue = new Date(element.dateOfIssue) 
        element.dateOfIssue = newDateOfIssue

        const newExpirationDate = new Date(element.expirationDate) 
        element.expirationDate = newExpirationDate

        delete element._id // remove element _id with the file

      })

      this.orderService.exportAsExcelFile('HubCom - relatorio de vendas', report)
    })
    
  }


}

