import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { Agency } from './agency.model';
import { HOST_API } from '../app.api';


@Injectable()
export class AgencyService {

    agencies: Agency[] = []
    
    constructor(private http: HttpClient) {}

    agency(): Observable<Agency[]> {

        return this.http.get<Agency[]>(`${HOST_API}/agencies`)
    }

    create(agency: Agency): Observable<string> {

        return this.http.post<string>(`${HOST_API}/agencies`, agency)                
    }

    agencyById(id: string): Observable<Agency> {

        return this.http.get<Agency>(`${HOST_API}/agencies/${id}`)
    }

    update(agency: Agency): Observable<string> {

        return this.http.put<string>(`${HOST_API}/agencies/${agency._id}`, agency)
    }

    clear() {
       this.agencies = []
    }

}