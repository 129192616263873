import { Component, OnInit } from '@angular/core'

import { Channel } from './channel.model';
import { ChannelService } from './channel.service';

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html'
})
export class ChannelsComponent implements OnInit {

  channels: Channel[] = []
  filterQuery = '';

  constructor(private channelService: ChannelService ) {}

  ngOnInit() {
    this.channelService.get()
      .subscribe(channels => {
        this.channels = channels
      }, error => {
        console.log(error)
        localStorage.setItem('token', '')
      });
  }

  onReport() {
    
      this.channelService.report().subscribe(data => {
      this.channelService.exportAsExcelFile('HubCom - relatorio de veículo', data.result) 
    
    })
  }

}
