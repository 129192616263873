import { ResetPassword } from './reset-password.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { HOST_API } from '../app.api';


@Injectable()
export class ResetPasswordService {

  constructor(private http: HttpClient) { }

  reset(email: ResetPassword): Observable<string> {
    return this.http.post<string>(`${HOST_API}/token/requestPassword`, email)
      
  }

}
