import { Injectable } from '@angular/core';

declare var toastr: any

@Injectable()
export class ToasterService {

  constructor() { }

  success(title: string, message?: string) {
    toastr.success(title, message)
  }

  warning(title: string, message?: string) {
    toastr.warning(title, message)
  }

  info(title: string) {
    toastr.info(title)
  }

  error(title: string, message?: string) {
    toastr.error(title, message)
  }






}
