import { Injectable, Injector } from "@angular/core";
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http'
import {Observable} from 'rxjs/Observable'

import { LoginService } from '../login/login.service'
import { HOST_API } from '../app.api';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private injector: Injector) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      const loginService = this.injector.get(LoginService)      
      
      if (loginService.islogged() && request.url.indexOf(HOST_API)>= 0) {
            const authRequest = request.clone(
                {setHeaders: {'Authorization': `JWT ${loginService.getToken()}`}})
            return next.handle(authRequest)
        }else{
          return next.handle(request)
        }
        
      }


}