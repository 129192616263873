import { Component, Injectable } from '@angular/core';
import { ModelComponent } from './model.component';
import { ModelService } from './model.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InputComponent } from '../shared/input/input.component';
import { ToasterService } from '../toaster-service.service';


import { Channel } from '../channels/channel.model';
import { ChannelService } from '../channels/channel.service';


@Component({
    selector: 'app-model-edit',
    templateUrl: './model-edit.component.html'
})


export class ModelEditComponent {

    model: ModelComponent = new ModelComponent();
    service: ModelService;
    route: ActivatedRoute;
    router: Router;

    channelService: ChannelService
    channels: Channel[]

    constructor(service: ModelService,
        fb: FormBuilder, route:
        ActivatedRoute,
        router: Router,
        private toasterService: ToasterService,
        channelService: ChannelService) {

        this.service = service;
        this.route = route;
        this.router = router;
        this.channelService = channelService

        this.channelService
        .get()
        .subscribe(result => {
                this.channels = result;
            }, error => this.error(JSON.parse(error._body).map(m => m.message).join('<br>'))
        );

        this.route.params.subscribe(params => {

            this.service
                .getById(params['id'])
                    .subscribe(result => {
                        this.model = result;
                        console.log(this.model)
                    }, error => this.error(JSON.parse(error._body).map(m => m.message).join('<br>')));
        });

    }

    edit() {
        this.service
            .update(this.model)
                .subscribe(result => {

                    this.success();
                    this.router.navigate(['models']);

                    }, error => this.error(JSON.parse(error._body).map(m => m.message).join('<br>')));

    }

    changeStatus() {

        this.service
            .changeStatus(this.model._id, this.model.active)
                .subscribe(result => {
                    this.success();

                    }, error => this.error(JSON.parse(error._body).map(m => m.message).join('<br>')));
    }

    success() {

        this.toasterService.success('', 'Peça atualizada com sucesso.');
    }

    error(message?: string) {

        this.toasterService.error('', `Ops!</br> ${message}`);
    }

}
