import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ToasterService } from '../../toaster-service.service';
import { Router, ActivatedRoute } from '@angular/router';

import { AgencyService } from '../agency.service';
import { Agency } from '../agency.model';
import { SelectModel } from '../../shared/select-uf/select.model';

import { ZipCodeService } from '../../shared/app-service-zipcode/zip-code.service'

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html'
})
export class AgencyComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    this.debounce.unsubscribe();
  }

  debounce: Subject<string> = new Subject<string>()

  agency: Agency
  actionOption: string

  statusAgency: any[]
  ufs: any[]

  constructor(  private agencyService: AgencyService,
                private router: Router,
                private activeRouter: ActivatedRoute,
                private toasterService: ToasterService,
                private zipCodeService: ZipCodeService ) { }

  ngOnInit() {

    this.agency = { _id:null, socialName:null, fantasyName:null, 
      cnpj:null, stateRegistration:null, email:null, address:null,
      number:null, complement:null, neighborhood:null, city:null, state:null,
      zipcode:null, phone:null, cellphone: null, createDate:null, active:null }

    this.statusAgency = [
      {value: 'false', label: 'Inativo' },
      {value: 'true', label: 'Ativo'}
    ]

    // carrega a lista de UFs
    this.ufs = SelectModel

    this.actionOption = 'create';

    if (this.activeRouter.snapshot.params['id'] !== undefined) {
      this.agencyService.agencyById(this.activeRouter.snapshot.params['id'])
        .subscribe(agency => {
            this.getAgency(agency)
          })
      }

    this.debounce
      .pipe(debounceTime(500))
      .subscribe( zipCode =>  {
        if(zipCode !== '' && zipCode.length > 7) {

            const newZipCode = this.formatZipCode(zipCode)
            this.agency.zipcode = newZipCode

            this.zipCodeService.findZipCode (this.agency.zipcode)
              .subscribe((result) => {
                this.agency.address = result.logradouro;
                this.agency.complement = result.complemento;
                this.agency.neighborhood = result.bairro;
                this.agency.city = result.localidade;
                this.agency.state = result.uf;
              })
        }
    })

  }

  formatZipCode(value) {

    let newValue = value.toString().replace(/\D/g,"")   //Remove tudo o que não é dígito
    newValue = newValue.replace(/(\d{5})(\d)/,"$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
    return newValue

  }

  success(message: string) {
    this.toasterService.success(message);
  }

  error(message?: string) {
    this.toasterService.error(`Ops!</br> ${message}`);
  }

  info() {
    this.toasterService.info('Empresa cadastrada com sucesso!')
  }

  warning() {
    this.toasterService.warning('Empresa', 'Ocorreram problemas.')
  }

  createAgency(formValue: Agency) {

    this.agencyService.create(formValue)
      .subscribe((msg: any) => {
        this.success('Cadastro realizado com sucesso!')
        this.router.navigate(['/agencies'])
      })
  }

  updateAgency() {
    this.agencyService.update(this.agency)
      .subscribe((msg: any) => {
        this.success('Cadastro atualizado com sucesso!')
        this.router.navigate(['/agencies'])
      })
  }

  getAgency(agency: Agency) {
    this.actionOption = 'edit';
    this.agency = agency
    const zipCode = this.formatZipCode(this.agency.zipcode) 
    this.agency.zipcode = zipCode

    // this.agency.zipcode = ('00000000' + this.agency.zipcode).slice(-8)
  }

  changeStatus($event) {

    if ($event === 'false') {
      this.agency.active = false
    } else {
      this.agency.active = true
    }

  }

  changeUf($event: string) {
    this.agency.state = $event
  }

}
