  
<!-- Cadastro de Peças -->

<div class="row" *ngIf="type?.length === 0 || channelId?.length === 0" >
  <div class="form-group col-sm-12">
      <p>
          Nenhum veículo selecionado.
      </p>
  </div>
</div>

<form novalidate #formModel="ngForm" class="form-horizontal" *ngIf="type?.length > 0 && channelId?.length > 0" >

<div class="row">  
    <div class="form-group col-sm-2">
      <app-input errorMessage="Campo obrigatório" label="Tipo" >
        <input type="text" name="type" [ngModel]="type" [disabled]="true" class="form-control"  class="form-control input-lg">
      </app-input>
    </div>
    <div class="form-group col-sm-10">
      <app-input errorMessage="Campo obrigatório." label="Nome" > 
        <input type="text" name="name" required (ngModel)="model.name"  class="form-control" placeholder="Ex: Rotativo 30''"  minlength="3" maxlength="256" >
      </app-input>
    </div>
</div>  

<!--Radio-->
<div *ngIf="type === 'Rádio' ">

  <div class="row">
    <div class="form-group col-sm-3">
      <app-input errorMessage="Tipo do comercial inválido." label="Tipo do comercial" > 
          <select id="commercialType" name="commercialType" (ngModel)="model.commercialType" class="form-control input-lg" size="1">
            <option [ngValue]="'Blitz'" [selected]="commercialType == 'Blitz'" >Blitz</option>
            <option [ngValue]="'Flash'" [selected]="model.commercialType == 'Flash'" >Flash ao Vivo</option>
            <option [ngValue]="'Patrocinio'" [selected]="commercialType == 'Patrocinio'" >Patrocínio</option>
            <option [ngValue]="'Spot'" [selected]="commercialType == 'Spot'" >Spot</option>
            <option [ngValue]="'Testemunhal'" [selected]="commercialType == 'Testemunhal'" >Testemunhal</option>
          </select>
      </app-input>
    </div>  
  
    <div class="form-group col-sm-3" >
      <app-input errorMessage="Intervalo inválido." label="Intervalo" > 
        <input type="text" name="interval" (ngModel)="model.interval" id="interval" class="form-control" size="16" placeholder="06h00 - 19h00" minlength="3" maxlength="256">
      </app-input>
    </div>
    <div class="form-group col-sm-3">
      <app-input errorMessage="Duração inválida." label="Duração" > 
        <input type="text" name="duration" (ngModel)="model.duration" id="duration" class="form-control" size="16" placeholder="30 segundos" >
      </app-input>
    </div>
    <div class="form-group col-sm-3">
      <app-input errorMessage="Valor inválido." label="Valor" > 
        <input name="price" currencyMask (ngModel)="model.price" id="price" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" class="form-control" size="16" placeholder="Ex: 100,00" minlength="3" maxlength="256">
      </app-input>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-sm-2">
      <app-input errorMessage="Programa definido inválido." label="Programa definido" > 
          <input type="checkbox" id="definedProgram" name="definedProgram" (ngModel)="model.definedProgram" />
      </app-input>
    </div>
    <div class="form-group col-sm-10" >
      <app-input errorMessage="Nome do programa inválido." label="Nome do programa" > 
        <input type="text" name="radioProgram" required (ngModel)="model.radioProgram" id="radioProgram" class="form-control" size="16" required="definedProgram">
      </app-input>
    </div>
  </div>

  <div class="row">
    
    <div class="form-group col-sm-8">
        <app-input errorMessage="Nome do locutor inválido." label="Nome do locutor" > 
          <input type="text" name="broadcaster" required (ngModel)="model.broadcaster " id="broadcaster " class="form-control" size="16" required="definedProgram">
        </app-input>
    </div>

    <div class="form-group col-sm-4">
        <app-input errorMessage="Cachê do Locutor inválido." label="Cachê do Locutor" > 
          <input name="broadcasterPayment" required currencyMask (ngModel)="model.broadcasterPayment" id="broadcasterPayment" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control" size="16" placeholder="Ex: 100,00" required="definedProgram">
        </app-input>
    </div>
  </div>

</div>

<!--Jornal-->
<div *ngIf="type === 'Jornal' ">
  

  <div class="row">

    <div class="form-group col-sm-4" >
      <app-input errorMessage="Nº de colunas inválido." label="Colunas" > 
        <input type="text" name="columns" [(ngModel)]="model.columns" (ngModelChange)="getColumns($event)" id="columns" class="form-control" size="16" pattern="[\d.,?!]*" required minlength="1">
      </app-input>
    </div>
    
    <div class="form-group col-sm-4" >
      <app-input errorMessage="Largura inválida." label="Largura" > 
        <input type="text" name="width" [(ngModel)]="model.width" (ngModelChange)="getWidth($event)" id="width" class="form-control" size="16" minlength="1">
      </app-input>                      
    </div>
    
    <div class="form-group col-sm-4" >
        <app-input errorMessage="Altura inválida." label="Altura" > 
          <input type="text" name="height" [(ngModel)]="model.height" id="height" (ngModelChange)="getHeight($event)" class="form-control" size="16" required>
        </app-input>                      
    </div>

  </div>

  <div class="row">
    <div class="form-group col-sm-6" >
      <app-input errorMessage="Valor inválido." label="Valor base" > 
        <input name="price" currencyMask (ngModel)="model.price" (ngModelChange)="getPrice($event)" id="price" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" class="form-control" size="16" placeholder="Ex: 100,00" >
      </app-input>
    </div> 
    <div class="form-group col-sm-6">
      <label>Valor Unitário</label>
      <div class="input-group">
        <input name="unitPrice" currencyMask [ngModel]="model.unitPrice" id="unitPrice" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" class="form-control" size="16" placeholder="Ex: 100,00" [readonly]="true" [disabled]="true" >
      </div>
    </div>
  </div>

</div>

<!--Revista-->
<div class="row" *ngIf="type === 'Revista' ">

    <div class="form-group col-sm-4" >
      <app-input errorMessage="Largura inválida." label="Largura" > 
        <input type="text" name="width" [(ngModel)]="model.width" id="width" (ngModelChange)="getWidth($event)" class="form-control" size="16" minlength="1">
      </app-input>                      
    </div>

    <div class="form-group col-sm-4" >
        <app-input errorMessage="Altura inválida." label="Altura" > 
          <input type="text" name="height" [(ngModel)]="model.height" (ngModelChange)= "getHeight($event)" id="height" class="form-control" size="16" minlength="1">
        </app-input>                      
    </div>

    <div class="form-group col-sm-4">
      <app-input errorMessage="Valor inválido." label="Valor base" > 
        <input name="price" currencyMask (ngModel)="model.price" id="price" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" class="form-control" size="16" placeholder="Ex: 100,00" minlength="3" maxlength="256">
      </app-input>
    </div>
  
</div>

<!-- OOH -->
<div class="row" *ngIf="type === 'OOH' ">

    <div class="form-group col-sm-2" >
      <app-input errorMessage="Medida inválido." label="Medida" > 
        <input type="text" name="size" (ngModel)="model.size" id="size" class="form-control" size="16">
      </app-input>
    </div>

    <div class="form-group col-sm-4">
      <app-input errorMessage="Tipo inválido." label="Tipo de OOH" > 
          <select id="OOHType" name="OOHType" (ngModel)="model.OOHType" class="form-control input-lg" size="1" >
              <option [ngValue]="'Outdoor'" [selected]="model.OOHType == 'Outdoor'" >Outdoor</option>
              <option [ngValue]="'Frontlight'" [selected]="model.OOHType == 'Frontlight'" >Frontlight</option>
              <option [ngValue]="'Backlight'" [selected]="model.OOHType == 'Backlight'" >Backlight</option>
              <option [ngValue]="'Painel Luminoso'" [selected]="model.OOHType == 'Painel Luminoso'" >Painel Luminoso</option>
              <option [ngValue]="'Empenas'" [selected]="model.OOHType == 'Empenas'" >Empenas</option>
              <option [ngValue]="'Triedro'" [selected]="model.OOHType == 'Triedro'" >Triedro</option>
              <option [ngValue]="'Led'" [selected]="model.OOHType == 'Led'" >Painel de Led</option>
              <option [ngValue]="'Outros'" [selected]="model.OOHType == 'Outros'" >Outros</option>
            </select>
      </app-input>
    </div>

    <div class="form-group col-sm-2">
      <app-input errorMessage="Cobertura mínima inválida." label="Cobertura mínima" > 
        <input type="number" name="minimumCoverage" [(ngModel)]="model.minimumCoverage" (ngModelChange)="getMinimumCoverage($event)" id="minimumCoverage" class="form-control" size="16" placeholder="Ex: 15" minlength="3" maxlength="1000">
      </app-input>
    </div>

    <div class="form-group col-sm-2">
      <app-input errorMessage="Valor inválido." label="Valor unitário" > 
        <input name="price" currencyMask [(ngModel)]="model.price" (ngModelChange)="getPrice($event)" id="price" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', precision: 3 }" class="form-control" size="16" placeholder="Ex: 100,00" required minlength="3" maxlength="256">
      </app-input>
    </div>

    <div class="form-group col-sm-2">
      <label>Valor total</label>
      <div class="input-group">
        <input name="unitPrice" currencyMask [ngModel]="model.unitPrice" id="unitPrice" [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" class="form-control" size="16" placeholder="Ex: 100,00" [readonly]="true" [disabled]="true" >
      </div>    
    </div>

  </div>

<div class="row">
  <fieldset class="form-group col-sm-12">
      <label>Observações</label>
      <div class="input-group">
        <textarea class="form-control" required (ngModel)="model.observation" name="observation" id="observation"  cols="60" rows="2"></textarea>
      </div>
    </fieldset>
</div>

<div class="row">
  <div class="form-actions form-group col-lg-2">
    <button (click)="register(formModel,formModel.value);" class="btn btn-primary">Adicionar item</button>
  </div>
</div>

</form>


