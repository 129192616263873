import { Component, OnInit, Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ToasterService } from '../../toaster-service.service';
import { Customer } from '../customer.model';
import { CustomerService } from '../customer.service';
import { SelectModel } from '../../shared/select-uf/select.model';

import { ZipCodeService } from '../../shared/app-service-zipcode/zip-code.service'


@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html'
})

@Injectable()
export class CustomerComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    this.debounce.unsubscribe();
  }

  debounce: Subject<string> = new Subject<string>()

  customer: Customer
  actionOption: string
  ufs: any[]
  statusCustomer: any[]

  constructor(  private customerService: CustomerService,
                private router: Router,
                private activeRouter: ActivatedRoute,
                private toasterService: ToasterService,
                private zipCodeService: ZipCodeService) {}

  ngOnInit() {

    this.customer = { _id:null, socialName:null, fantasyName:null, 
      document:null, stateRegistration:null, email:null, address:null,
      number:null, complement:null, neighborhood:null, city:null, state:null,
      zipcode:null, phone:null, cellphone: null, createDate:null, active:null }

    this.statusCustomer = [
      {value: 'false', label: 'Inativo' },
      {value: 'true', label: 'Ativo'}
    ]

    // carrega as UFs
    this.ufs = SelectModel

    this.actionOption = 'create';

    if (this.activeRouter.snapshot.params['id'] !== undefined) {
      this.customerService.customerById(this.activeRouter.snapshot.params['id'])
        .subscribe(customer => {
            this.getCustomer(customer)
          }, error => {
            this.error(error._body.map(m => m.message))
            console.log(error)
            this.router.navigate(['/customers'])
          })
      }
    
    this.debounce
      .pipe(debounceTime(500))
      .subscribe( zipCode =>  {
        if(zipCode !== '' && zipCode.length > 7) {

          const newZipCode = this.formatZipCode(zipCode)
          this.customer.zipcode = newZipCode

          this.zipCodeService.findZipCode(this.customer.zipcode)
            .subscribe((result) => {
              this.customer.address = result.logradouro;
              this.customer.complement = result.complemento;
              this.customer.neighborhood = result.bairro;
              this.customer.city = result.localidade;
              this.customer.state = result.uf;
            }, error => console.error(error))
      }
    }, error => console.error(error))

  }

  success(message: string) {
    this.toasterService.success(message);
  }

  error(message?: string) {
    this.toasterService.error(`Ops!</br> ${message}`);
  }

  info() {
    this.toasterService.info('Empresa cadastrada com sucesso!')
  }

  warning() {
    this.toasterService.warning('Empresa', 'Ocorreram problemas.')
  }

  createCustomer(formValue: Customer) {

    this.customerService.create(formValue)
      .subscribe((msg: any) => {
        this.success('Cadastro realizado com sucesso!')
        this.router.navigate(['/customers'])
      }
    )
  }

  updateCustomer() {
    this.customerService.update(this.customer)
      .subscribe((msg: any) => {
        this.success('Cadastro atualizado com sucesso!')
        this.router.navigate(['/customers'])
      }
    )
  }

  formatZipCode(value) {

    let newValue = value.toString().replace(/\D/g,"")   //Remove tudo o que não é dígito
    newValue = newValue.replace(/(\d{5})(\d)/,"$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
    return newValue

  }


  getCustomer(customer: Customer) {
    this.actionOption = 'edit';
    this.customer = customer
    const zipCode = this.formatZipCode(this.customer.zipcode) 
    this.customer.zipcode = zipCode
    // this.customer.zipcode = ('00000000' + this.customer.zipcode).slice(-8)
  }

  changeStatus($event) {

    if ($event === 'false') {
      this.customer.active = false
    } else {
      this.customer.active = true
    }

  }

  changeUf($event) {
    this.customer.state = $event
  }


  onBlurMethod() {

    if (this.customer.document.length > 0) {
      // retira a formatação
      this.customer.document = this.customer.document.replace(/(\.|\/|\-)/g, '')

    if (this.customer.document.length <= 11) {
      this.customer.document = this.customer.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4')
    } else {
      this.customer.document = this.customer.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')
    }

    }
  }


// tslint:disable-next-line:eofline

}
