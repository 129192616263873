import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';

// Services
import { ToasterService } from '../../toaster-service.service';
import { UserService } from './../../user/user.service';

// Orders
import { Order } from '../order.model'

// Models
import { ModelService } from './../../model/model.service';
import { ModelComponent } from './../../model/model.component';
import { OrderService } from '../order.service';
import { Customer } from './../../customers/customer.model'
import { Channel } from './../../channels/channel.model';
import { Company } from './../../companies/company.model';
import { Agency } from './../../agencies/agency.model';
import { Months, Years} from './year.month.model';
import { Observation} from '../observation.model';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html'
})

export class OrderComponent implements OnInit {

  order: Order = new Order()
  models: ModelComponent[] = []
  model: ModelComponent
  numberMonth: number
  numberYear: number
  daysOfMonth: number
  months = Months
  years = Years
  edit: Boolean = false
  billedCompany: boolean
  user: any
  observations: Observation

  constructor(  private modelService: ModelService,
                private orderService: OrderService,
                private toasterService: ToasterService,
                private router: Router,
                private activeRouter: ActivatedRoute,
                private datePipe: DatePipe,
                private userService: UserService) {}


  ngOnInit() {

    console.log(this.years)
    this.user = this.userService.getUserByToken()

    if (this.activeRouter.snapshot.params['id'] !== undefined) {
      this.orderService.getById(this.activeRouter.snapshot.params['id'])
        .subscribe( order => {
          this.edit = true
          this.order = order
          this.order.dateOfIssue = this.transform(this.order.dateOfIssue)
          this.order.expirationDate = this.transform(this.order.expirationDate)
          this.numberMonth = this.order.placementMonth
          this.numberYear = this.order.placementYear
          this.models = order.models
          this.billedCompany = order.billedCompany
          this.addPlacementDate()

        })
    } else {

      this.orderService.
        getObservation()
        .subscribe( observations => {
          this.observations = observations
          const textPI = this.observations[0].description
          this.order.observation = textPI.toString()    
        })

//       const textFaturamento = `1 - Os pagamentos serão processados e efetuados, mediante o recebimento dos comprovantes de veiculação e Nota Fiscal em 2 (duas) vias em nome da HUB COMUNICAÇÃO pelo Valor Líquido especificando cada anúncio pelo título, data e número do PI. 
// 2 - Enviar faturas e comprovantes para HUB COMUNICAÇÃO com antecedência de 15 dias úteis antes da data do vencimento, caso contrário o pagamento ficará prorrogado automaticamente para 15 dias do vencimento normal.
// 3 - São considerados como não realizados os anúncios cujos comprovantes não sejam recebidos pela HUB COMUNICAÇÃO até 15 dias após o término da veiculação programada.`


      // this.order.revenuesObservation = textFaturamento 

    }
  }



  transform(valor: any) {

    const date = new Date(valor)
    return this.datePipe.transform(date.setHours(24), 'yyyy-MM-dd')
  }

  addType(type: string) {

      this.order.channelType = type
  }

  placementMonth(valor) {

    this.numberMonth = valor
    this.order.placementMonth = valor
    this.addPlacementDate()
  }

  placementYear(valor) {

    this.numberYear = valor
    this.order.placementYear = valor
    this.addPlacementDate()
  }

  addPlacementDate() {

    if (this.numberMonth > 0 && this.numberYear > 0) {

      this.daysOfMonth = this.orderService.getDaysOfMonth(this.numberYear, this.numberMonth)
    }

  }

  addModels(models: ModelComponent[]) {

    this.models = models
    this.createCalendar(this.daysOfMonth)
  }

  listModels() {

    return this.models
  }

  getType() {

    return this.order.channelType
  }

  createCalendar(days) {

    this.models.forEach(model => {
      const d = {day: 0}
      const calendar = []

      for (let i = 1; i <= days; i++ )  {
        calendar.push(d)
      }

      const newCalendar = calendar.map(day => Object.assign({}, day)) // clona o objeto
      model.calendar = newCalendar
      model.days = 0
      model.total = 0

    })

  }

  removeTableModel(model: ModelComponent) {

    this.onUpdateTotalValue(model.total)
    this.onChangevLiq()
  }

  onUpdateTotalValue(totalItem: number) {

      this.order.vTotalBruto -= totalItem
  }

  updateModels(models: ModelComponent[]) {

    this.models = models
    this.order.vTotalBruto = this.modelService.onGetTotalValue(this.models)
    this.onChangevLiq()
  }

  onChangevLiq() {

    if (this.order.type > 0) {

      this.order.vComissaoAgency = this.orderService.onGetAgencyTotalComission(this.order)

      if (this.order.type === 2) {
        this.order.vLiqFaturar = this.orderService.onGetTotalValueInvoiced(this.order)
      }

      this.order.vComissao = this.orderService.onGetTotalCommission(this.order)
      this.order.vLiqRadio = this.orderService.onGetTotalNetValue(this.order)
    }

  }

  createOrder() {

    this.order.models = this.models
    this.order.userId = this.user.id
    this.orderService.create(this.order)
      .subscribe((response: any) => {
        this.success(response.message);
        this.router.navigate(['/orders'])
      })
  }

  updateOrder() {

    this.order.userId = this.user.id
    this.order.models = this.models
    this.orderService.update(this.order)
      .subscribe((response: any) => {
          this.success(response.message);
          this.router.navigate(['/orders'])
      })
  }

  updateDaysOfMonth(valor) {

    this.daysOfMonth = valor
    this.createCalendar(this.daysOfMonth)
  }

  updateNumberMonth(valor) {

    this.numberMonth = valor
  }

  updateNumberYear(valor) {

    this.numberYear = valor
  }

  updateChannel(channel: Channel) {

    this.updateObservation(channel.type)
    this.order.channelFantasyName = channel.fantasyName
    this.order.channelId = channel._id
  }

  updateObservation(type:string) {
    

    if (this.observations) {

      switch(type) {

        case 'Rádio':
          this.order.revenuesObservation = this.observations[2].description.toString() 
          break;
        case 'OOH':
          this.order.revenuesObservation = this.observations[3].description.toString()
          break;
        case 'Jornal':
          this.order.revenuesObservation = this.observations[1].description.toString()
          break;
        case 'Revista':
          this.order.revenuesObservation = this.observations[4].description.toString()
          break;
      }

    }
  }

  updateAgency(agency: Agency) {

    this.order.agencyCnpj = agency.cnpj
    this.order.agencyId = agency._id
    this.order.agencyFantasyName = agency.fantasyName
  }

  updateCompany(company: Company) {

    this.order.companyId = company._id
    this.order.companyFantasyName = company.fantasyName
  }

  updateBilledCompany (billedCompany: boolean) {

    this.order.billedCompany = billedCompany
  }

  updateCustomer(customer: Customer) {

    this.order.customerCnpj = customer.document
    this.order.customerId = customer._id
    this.order.customerFantasyName = customer.fantasyName
  }

  changeNameModel(model: ModelComponent){
    this.models.find(m => m.ordem == model.ordem).name = model.name
  }

  success(message: string) {
    this.toasterService.success(message);
  }

  error(message?: string) {
    this.toasterService.error(`Ops!</br> ${message}`);
  }

  info() {
    this.toasterService.info('Pedido cadastrado com sucesso!')
  }

  warning() {
    this.toasterService.warning('Pedidos', 'Ocorreram problemas.')
  }


}
