import { Component } from '@angular/core';
import { ModelService } from './model.service';
import { ModelComponent } from './model.component';
import { ToasterService } from '../toaster-service.service';


@Component({
    selector: 'app-model-list',
    templateUrl: './model-list.component.html'
})

export class ModelListComponent {

    models: ModelComponent[] = [];
    service: ModelService;

    filterQuery = '';

    constructor(service: ModelService, private toasterService: ToasterService) {

        this.service = service;

        this.service.get().subscribe(res => {
            this.models = res;
        }, error => {
            this.error(JSON.parse(error._body).map(m => m.message).join('<br>'))
            localStorage.setItem('token', '')
        });

    }

    success() {
        this.toasterService.success('', 'Cadastro atualizado com sucesso.');
    }

    error(message?: string) {
        this.toasterService.error('', `Ops!</br> ${message}`);
    }

// tslint:disable-next-line:eofline
}