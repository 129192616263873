import { Router } from '@angular/router';
import { Component, Injectable, OnInit } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { User } from './user.model';


@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html'
})

@Injectable()
export class AppHeaderComponent implements OnInit {

  user: User

  constructor(private route: Router) {}


  ngOnInit() {

    this.clearUser()
    this.getUser()
  }

  getUser() {
    const token = localStorage.getItem('token');

    if (token) {
      this.user = jwt_decode(token)
    }
  }

  clearUser() {
    this.user = null
  }

  logout() {
    localStorage.setItem('token', '')
    this.clearUser()
    this.route.navigate(['login'])
  }

}
