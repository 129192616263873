
  <div class="col-sm-4" style="margin: 0 auto;padding-top: 10%">
    <div class="card">
      <div class="card-header">
        <img [src]="logo" class="figure-img img-fluid rounded" alt="Logo do sistema.">
      </div>
      <div class="card-body">
        <form novalidate #form="ngForm">
          <div class="form-group">
            <label for="email">E-mail</label>
            <div class="input-group">
              <input type="email" id="email" [ngModel]="reset.email" required name="email" class="form-control">
              <div class="input-group-append">
                <span class="input-group-text"><i class="fa fa-envelope"></i></span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <button title="Redefinir senha" (click)="resetPassword(form.value)" [disabled]="!form.valid" class="btn btn-lg btn-primary form-control">Redefinir senha</button>
          </div>
        </form>
        <div class="row">
          <a [routerLink] = "['/login']" class="btn btn-default">Voltar para o login</a>
        </div>
      </div>
    </div>
  </div>
  