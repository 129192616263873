<!--Rádip-->
<table *ngIf="type.length > 0 && type === 'Rádio' && models.length > 0 " class="table table-striped table-bordered datatable" [mfData]="models" #mf="mfDataTable" [mfRowsOnPage]="10"> 
  <thead>
    <tr>
      <th>Peça(s)</th>
      <th style="width: 15%">Nome</th>
      <th style="width: 22%">Intervalo</th>
      <th>Tipo</th>
      <th>Preço</th>
      <th>Prog. definido</th>
      <th>Programa</th>
      <th>Locutor</th>
      <th>Cachê</th>
      <th style="width: 25%">Observações</th>
      <th>Remover</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of mf.data">
      <td>{{item?.ordem}}</td>
      <td><input name="item.name" [(ngModel)]="item.name"></td>
      <td>{{item?.duration}} {{item?.durationType}} | {{item?.interval}} </td>
      <td>{{item?.commercialType}}</td>
      <td>{{item?.price | currency:'BRL':'symbol-narrow'}}</td>
      <td>{{item?.definedProgram == true ? 'Sim' :  'Não'}}</td>
      <td>{{item?.radioProgram}}</td>
      <td>{{item?.broadcaster}}</td>
      <td>{{item?.broadcasterPayment | currency: 'BRL': 'symbol-narrow'}}</td>
      <td>{{ item?.observation }}</td>
      <td>
        <span class="btn btn-danger" title="Remover Peça" (click)="emitRemove(item)"><i class="fa fa-remove"></i></span>
        <span class="btn btn-info" title="Atualizar Peça" (click)="emitChangeName(item)"><i class="fa fa-refresh"></i></span>
      </td>
    </tr>
  </tbody>
</table>

<!--Jornal-->
<table *ngIf="type.length > 0 && type === 'Jornal' && models.length > 0 " class="table table-striped table-bordered datatable" [mfData]="models" #mf="mfDataTable" [mfRowsOnPage]="10"> 
  <thead>
    <tr>
      <th>Peça(s)</th>
      <th style="width: 15%">Nome</th>
      <th>Coluna</th>
      <th>Largura</th>
      <th>Altura</th>
      <th>Valor Base</th>
      <th>Valor Unitário</th>
      <th style="width: 25%">Observações</th>
      <th>Remover</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of mf.data">
      <td>{{item?.ordem}}</td>
      <td><input name="item.name" [(ngModel)]="item.name"></td>
      <td>{{item?.columns}}</td>
      <td>{{item?.width}}</td>
      <td>{{item?.height}}</td>
      <td>{{item?.price | currency:'BRL':'symbol-narrow'}}</td>
      <td>{{item?.unitPrice | currency:'BRL':'symbol-narrow'}}</td>
      <td>{{ item?.observation }}</td>
      <td>
        <span class="btn btn-danger" title="Remover Peça" (click)="emitRemove(item)"><i class="fa fa-remove"></i></span>
        <span class="btn btn-info" title="Atualizar Peça" (click)="emitChangeName(item)"><i class="fa fa-refresh"></i></span>
      </td>
    </tr>
  </tbody>
</table>

<!-- Revista  -->
<table *ngIf="type.length > 0 && type === 'Revista' && models.length > 0 " class="table table-striped table-bordered datatable" [mfData]="models" #mf="mfDataTable" [mfRowsOnPage]="10"> 
  <thead>
    <tr>
      <th>Peça(s)</th>
      <th style="width: 15%">Nome</th>
      <th>Larg. </th>
      <th>Alt.</th>
      <th>Valor Base</th>
      <th style="width: 25%">Observações</th>
      <th>Remover</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of mf.data">
      <td>{{item?.ordem}}</td>
      <td><input name="item.name" [(ngModel)]="item.name"></td>
      <td>{{item?.width}} </td>
      <td>{{item?.height}}</td>
      <td>{{item?.price | currency: 'BRL': 'symbol-narrow'}}</td>
      <td>{{ item?.observation }}</td>
      <td>
        <span class="btn btn-danger" title="Remover Peça" (click)="emitRemove(item)"><i class="fa fa-remove"></i></span>
        <span class="btn btn-info" title="Atualizar Peça" (click)="emitChangeName(item)"><i class="fa fa-refresh"></i></span>
      </td>
    </tr>
  </tbody>
</table>

<!-- OOH  -->
<table *ngIf="type.length > 0 && type === 'OOH' && models.length > 0 " class="table table-striped table-bordered datatable" [mfData]="models" #mf="mfDataTable" [mfRowsOnPage]="10"> 
    <thead>
      <tr>
        <th>Peça(s)</th>
        <th style="width: 15%">Título</th>

        <th>Medida</th>
        <th>Tipo</th>
        <th>Cobertura Mínima</th>
        <th>Valor base</th>
        <th>Valor</th>
        <th style="width: 25%">Observações</th>
        <th>Remover</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of mf.data">
        <td>{{item?.ordem}}</td>
        <td><input name="item.name" [(ngModel)]="item.name"></td>
        <td>{{item?.size}}</td>
        <td>{{item?.OOHType}} </td>
        <td>{{item?.minimumCoverage}}</td>
        <td>{{item?.price | currency:'BRL':'symbol-narrow'}}</td>
        <td>{{item?.unitPrice | currency:'BRL':'symbol-narrow'}}</td>
        <td>{{item?.observation }}</td>
        <td>
          <span class="btn btn-danger" title="Remover Peça" (click)="emitRemove(item)"><i class="fa fa-remove"></i></span>
          <span class="btn btn-info" title="Atualizar Peça" (click)="emitChangeName(item)"><i class="fa fa-refresh"></i></span>
        </td>
      </tr>
    </tbody>
  </table>


<div *ngIf="type.length === 0 || (type !== 'Rádio' && type !== 'Jornal' && type !== 'Revista' && type !== 'OOH') || models.length === 0">
    Tabela de preço não cadastrada.
</div>
