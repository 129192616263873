
  <div class="animated fadeIn">

  <div class="card">
    <div class="card-header">
      <i class="fa fa-edit"></i> Lista de Pedidos 
      <div class="pull-right">
        <a [routerLink] = "['order']" class="btn btn-primary ">Adicionar</a>
      </div>
    </div>
    <div class="card-body">
      <app-filter-search
          class="app-filter-search" 
          (changeFilter)="onChangeFilter($event)">
      </app-filter-search>

      <div class="row">
        <div class="col-6 col-lg-3">
          <app-report-card 
            label="Valor total"
            value="{{ vTotalBruto }}"
            colorIcon="bg-primary"> </app-report-card>
        </div>
        <div class="col-6 col-lg-3">
          <app-report-card 
            label="Valor Líquido"
            value="{{ vLiqRadio }}"
            colorIcon="bg-success"> </app-report-card>
        </div>
        <div class="col-6 col-lg-3">
          <app-report-card 
            label="Comissão Empresa"
            value="{{ vComissao }}"
            colorIcon="bg-warning"> </app-report-card>
        </div>
        <div class="col-6 col-lg-3">
          <app-report-card 
            label="Comissão Agência"
            value="{{ vComissaoAgency }}"
            colorIcon="bg-danger"> </app-report-card>
        </div>
        
      </div>
      
      <button type="button" class="btn btn-success" style="margin-left: -15px" (click)="onReport()">
        <i class="fa fa-file" aria-hidden="true"></i> Exportar
      </button>
      
      <div class="row table-wrapper">
        <table class="table table-striped table-bordered datatable" 
          #mf="mfDataTable"  
          [mfData]="orders" 
          [mfRowsOnPage]="10">
          <thead>
            <tr>
              <th style="min-width:115px">
                Opções
              </th>
              <th>
                  <mfDefaultSorter by="createDate">Cadastro</mfDefaultSorter>
              </th>
              <th>
                <mfDefaultSorter by="numberOrder">PI</mfDefaultSorter>
              </th>
              <th style="min-width:110px">
                  <mfDefaultSorter  by="orderType">Tipo de PI</mfDefaultSorter>
              </th>
              <th style="min-width:350px">
                  <mfDefaultSorter by="customerCampaign">Campanha</mfDefaultSorter>
              </th>
              <th>
                  <mfDefaultSorter by="vComissaoAgency">C. Agência</mfDefaultSorter>
              </th>
              <th>
                  <mfDefaultSorter by="vLiqFaturar">V. a Faturar</mfDefaultSorter>
              </th>
              <th style="min-width:100px">
                  <mfDefaultSorter by="vComissao">C. Empresa</mfDefaultSorter>
              </th>
              <th>
                  <mfDefaultSorter by="vTotalBruto">V. Total</mfDefaultSorter>
              </th>
              <th>
                  <mfDefaultSorter by="vLiqRadio">V. Líquido</mfDefaultSorter>
              </th>
              <th>
                  <mfDefaultSorter by="placementDate">D. Veiculação</mfDefaultSorter>
              </th>
              <th style="min-width:300px">
                <mfDefaultSorter by="companyFantasyName">Empresa </mfDefaultSorter>
              </th>
              <th>
                <mfDefaultSorter by="agencyFantasyName">Agência</mfDefaultSorter>
              </th>
              <th style="min-width:300px">
                <mfDefaultSorter by="customerFantasyName">Cliente</mfDefaultSorter>
              </th>
              <th style="min-width:300px">
                <mfDefaultSorter by="channelFantasyName">Veículo</mfDefaultSorter>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of mf.data">
              <td>
                <button (click)="createPDF(order._id)" title="Gerar arquivo" class="btn btn-info ">
                  <i class="fa fa-download"></i>
                </button>
                
                <a [routerLink]="['order', order._id]" title="Editar" class="btn btn-success ml-1" href="#">
                    <i class="fa fa-edit"></i>
                </a>
              </td>
              
              <td>{{order.createDate | date: 'dd/MM/yyyy'}}</td>
              <td>{{order.numberOrder}}</td>
              <td>{{order?.type === 1 ? 'Comissionado' : 'Faturar'}}</td>
              <td>{{order.customerCampaign}}</td>
              <td>{{order?.vComissaoAgency | currency:'BRL':'symbol-narrow'}}</td>
              <td>{{order?.vLiqFaturar | currency:'BRL':'symbol-narrow'}}</td>
              <td>{{order.vComissao | currency:'BRL':'symbol-narrow'}}</td>
              <td>{{order.vTotalBruto | currency:'BRL':'symbol-narrow'}}</td>
              <td>{{order.vLiqRadio | currency:'BRL':'symbol-narrow'}}</td>
              <td>{{order.placementMonth}}/{{order.placementYear }}</td>
              <td>{{order.companyFantasyName}}</td>
              <td>{{order.agencyFantasyName}}</td>
              <td>{{order.customerFantasyName}}</td>
              <td>{{order.channelFantasyName}}</td>
            </tr>
          </tbody>
        </table>
        <nav>
          <ul class="pagination" *ngIf="countPagination > 1">
            <li class="page-item">
                 
                <button *ngIf="prevPage > 1" class="page-link" (click)="onPrevPage()"> << </button>
                <span *ngIf="prevPage === 1" class="page-link" > << </span>
            </li>
            
            <li class="page-item"  *ngFor="let p of ListPagination; let i = index">
              <button [ngClass]="(p === page)?'active page-link':'page-link'" (click)="onPageChange(p)">{{p}}</button> 
            </li>
            
            <li class="page-item">
                <button *ngIf="countPagination > nextPage" class="page-link" (click)="onNextPage()"> >> </button> 
                <span *ngIf="countPagination === nextPage" class="page-link"> >> </span> 
            </li>
          </ul>
        </nav>
      </div>

       <!-- Tabela vazia-->
       <div class="row" *ngIf="orders?.length === 0" >
        <div class="col-lg-12">
          <p>Não foram encontrados pedidos de inserção.</p>
        </div>
      </div>

    </div>
  </div>
</div>
