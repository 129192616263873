
<div class="row">
  <div class="col-lg-12 col-md-12">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink] = "['/orders']">Lista de Pedidos</a></li>
      <li class="breadcrumb-item active" aria-current="page">Cadastro</li>
    </ol>
    

    <form novalidate #form="ngForm">
      
      <!-- Pedido de inserção -->
      <div class="card">
        <div class="card-body">

            <!-- Campos obrigatórios Marcação -->
            <div class="row">
              <div class="form-group col-lg-12">                
                <span class="pull-right">(*) campos obrigatórios</span>
              </div>
            </div>    
          
            <!-- Pedido de inserção -->
            <div class="card">
              <div class="card-body">  
                <div class="row">
                  <div class="form-group col-lg-3">
                    <app-input errorMessage="Campo obrigatório" label="(*) Tipo de PI">
                      <select id="type" name="type" required [(ngModel)]="order.type" (ngModelChange)= "onChangevLiq()" class="custom-select" size="1">
                        <option [ngValue]="null">Selecione</option>
                        <option [ngValue]="1">P.I. - Comissionado</option>
                        <option [ngValue]="2">P.I. - Faturar</option>
                      </select>
                    </app-input>
                  </div>
                  <div class="form-group col-lg-9">                
                    <app-input errorMessage="Campo obrigatório" label="(*) Campanha">
                      <input class="form-control" name="campaign" required [(ngModel)]="order.customerCampaign" autocomplete="off" placeholder="digite o nome da Campanha">              
                    </app-input>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-lg-2">                 
                    <app-input errorMessage="Campo obrigatório e somente número" label="(*) Pedido de inserção">
                      <input type="text" class="form-control" name="numberOrder" required [(ngModel)]="order.numberOrder" autocomplete="off">              
                    </app-input>
                  </div>
                  <div class="form-group col-lg-3">    
                    <app-input errorMessage="Campo obrigatório" label="(*) Data de emissão">             
                      <input type="date" class="form-control" name="dateOfIssue" required 
                        [(ngModel)]="order.dateOfIssue" autocomplete="off" placeholder="dd/mm/aaaa">              
                    </app-input>
                  </div>
                  <div class="form-group col-lg-3">  
                    <app-input errorMessage="Campo obrigatório" label="(*) Data de vencimento">               
                      <input type="date" class="form-control" name="expirationDate" required [(ngModel)]="order.expirationDate" autocomplete="off" placeholder="30/01/2018">              
                    </app-input>
                  </div>
                  <div class="form-group col-lg-2">      
                    <app-input errorMessage="Campo obrigatório" label="(*) Mês de veiculação">
                      <select id="months" name="months" required [ngModel]="numberMonth" (ngModelChange)="placementMonth($event)" class="custom-select" size="1">
                          <option selected="selected" [ngValue]='null'>Selecione</option>
                          <option *ngFor= "let month of months" [ngValue]="month.value">{{month.label}}</option>
                        </select>
                    </app-input>
                  </div>
                  <div class="form-group col-lg-2">      
                    <app-input errorMessage="Campo obrigatório" label="(*) Ano de veiculação">
                      <select id="years" name="years" required [ngModel]="numberYear" (ngModelChange)="placementYear($event)" class="custom-select" size="1">
                          <option selected="selected" [ngValue]='null'>Selecione</option>
                          <option *ngFor= "let year of years" [ngValue]="year.label">{{year.label}}</option>
                        </select>
                    </app-input>
                  </div>
                </div>
              </div>
            </div>

            <!-- Empresa -->
            <div class="card">
              <div class="card-body"> 
                <app-order-company 
                  [companyId]="order.companyId" 
                  [billedCompany]="billedCompany" 
                  (updateCompany)="updateCompany($event)"
                  (updateBilledCompany)="updateBilledCompany($event)">
                </app-order-company>
              </div>
              
            </div>

            <!-- Cliente -->
            <div class="card">
              <div class="card-body">
                <app-order-customer
                  [customerId]= "order.customerId"
                  (updateCustomer)="updateCustomer($event)">
                </app-order-customer>
              </div>
            </div>

            <!-- Agencia -->      
            <div class="card">
              <div class="card-body">  
                <app-order-agency
                  [agencyId]= "order.agencyId" 
                  (updateAgency)="updateAgency($event)">
                </app-order-agency>
              </div>
            </div>

            <!-- Veículos -->
            <div class="card">
              <div class="card-body">  
                  <app-order-channel
                    [orderId]= "order._id"
                    (increaseModel)= "addModels($event)"
                    (decreaseModel)= "removeTableModel($event)"
                    (changeNameModel)= "changeNameModel($event)"
                    (getType)="addType($event)"
                    (updateChannel)="updateChannel($event)">
                  </app-order-channel>
              </div>
            </div>

            <!--Calendário-->
            <div class="card" *ngIf="models.length > 0" >
              <div class="card-body">
                <app-order-calendar 
                  [models]="listModels()"
                  [type]="getType()"
                  [numberMonth]="numberMonth"
                  [numberYear]="numberYear"
                  [daysOfMonth]="daysOfMonth"
                  (updateModels)="updateModels($event)"
                  (updateDaysOfMonth)="updateDaysOfMonth($event)"
                  (updateNumberMonth)="updateNumberMonth($event)"
                  (updateNumberYear)="updateNumberYear($event)">
                </app-order-calendar>
              </div>
            </div>

        </div>
      </div> 
      
     <!--Calendeario-->

      <!-- Valores finais -->
      <div class="card" *ngIf="order?.type && order?.type === 1" >
        <div class="card-body">  
          <div class="row">            
            <div class="form-group col-md-4">                
              <label>(*) Valor Bruto</label>  
              <input class="form-control" name="vTotalBruto" required currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="order.vTotalBruto" readonly [disabled]="true" >                            
            </div>
            <div class="form-group col-md-2">
              <label for="appendedInputAgency">(*) C. Agência</label>
              <div class="controls">
                <div class="input-group">
                  <input id="appendedInputAgency" type="text" id="appendedInputAgency" class="form-control" name="vComissaoPercentAgency" required [(ngModel)]="order.vComissaoPercentAgency" (ngModelChange)="onChangevLiq(order.vComissaoPercentAgency)" autocomplete="off">
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group col-md-2">
              <label for="appendedInput">(*) Comissão</label>
              <div class="controls">
                <div class="input-group">
                  <input id="appendedInput" type="text" id="appendedInput" class="form-control" name="vComissaoPercent" required [(ngModel)]="order.vComissaoPercent" autocomplete="off" (ngModelChange)="onChangevLiq(order.vComissaoPercent)" >
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group col-md-4">                
              <label>(*) Valor Líquido</label>
              <input class="form-control" name="vLiqRadio" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="order.vLiqRadio" readonly [disabled]="true">                            
            </div>
          </div>
        </div>
      </div>

      <!-- Valores finais -->
      <div class="card" *ngIf="order?.type && order?.type === 2">
          <div class="card-body">  
            <div class="row">            
              <div class="form-group col-md-2">                
                <label>Comissão Extra</label>  
                <input class="form-control" name="extraCommission" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="order.extraCommission">                            
              </div>
              <div class="form-group col-md-2">                
                <label>(*) Valor Bruto Veículo</label>  
                <input class="form-control" name="vTotalBruto" required currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="order.vTotalBruto" readonly [disabled]="true" >                            
              </div>
              
              <div class="form-group col-md-2">
                <label for="appendedInputAgency">(*) C. Agência</label>
                <div class="controls">
                  <div class="input-group">
                    <input id="appendedInputAgency" type="text" id="appendedInputAgency" class="form-control" name="vComissaoPercentAgency" required [(ngModel)]="order.vComissaoPercentAgency" (ngModelChange)="onChangevLiq(order.vComissaoPercentAgency)" autocomplete="off">
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-2">                
                <label>(*) L. a fatura</label>  
                <input class="form-control" name="vLiqFaturar" required currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="order.vLiqFaturar" readonly [disabled]="true" >                            
              </div>
              <div class="form-group col-md-2">
                <label for="appendedInput">(*) C. Empresa</label>
                <div class="controls">
                  <div class="input-group">
                    <input id="appendedInput" type="text" id="appendedInput" class="form-control" name="vComissaoPercent" required [(ngModel)]="order.vComissaoPercent" autocomplete="off" (ngModelChange)="onChangevLiq()" >
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group col-md-2">                
                <label>(*) Valor Líquido.</label>
                <input class="form-control" name="vLiqRadio" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" [(ngModel)]="order.vLiqRadio" readonly [disabled]="true">                            
              </div>
            </div>
          </div>
      </div>

      <!-- Sem valores -->
      <div class="card" *ngIf="!order?.type">
        <div class="card-body">
          <div style="text-align:center;vertical-align: middle" class="col-lg-12">                
            <p>Selecione um tipo de PI para visualizar os valores finais.</p>
          </div>
        </div>
      </div>

      <!-- observações -->
      <div class="card">
        <div class="card-body">  
          <div class="row">
            <fieldset class="form-group col-sm-6">
              <label>Observações do faturamento</label>
              <div class="input-group">
                <textarea class="form-control" [(ngModel)]="order.revenuesObservation" name="observation1" id="observation1"  cols="60" rows="5"></textarea>
              </div>
            </fieldset>
            <fieldset class="form-group col-sm-6">
              <label>Observações do PI</label>
              <div class="input-group">
                <textarea class="form-control" [(ngModel)]="order.observation" name="observation2" id="observation2"  cols="60" rows="5"></textarea>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </form>

    <div class="card-body">
      <div class="form-actions">
        <button *ngIf="!edit" title="Salvar cadastro" (click)="createOrder()" [disabled]="!form.valid" class="btn btn-primary">Salvar</button>
        <button *ngIf="edit" title="Editar cadastro" (click)="updateOrder()" [disabled]="!form.valid" class="btn btn-primary">Atualizar</button>
        <a [routerLink] = "['/orders']" class="btn btn-default">Voltar</a>
      </div>
    </div>
  </div>
</div>