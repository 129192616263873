import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { HOST_API } from '../app.api';

@Injectable()
export class ConfirmResetPassword {

    tokenConfirmed: string

    constructor(private httpClient: HttpClient) {

        this.tokenConfirmed = localStorage.getItem('tokenConfirmedPassword')
    }

    confirmedPassword(form: any): Observable<any> {

        const httpOptions = { headers: new HttpHeaders({'Authorization': 'JWT ' + this.tokenConfirmed})};
        return this.httpClient.post<any>(`${HOST_API}/token/confirmedPassword`, form, httpOptions)
            
    }

}

