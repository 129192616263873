import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
   name : 'telephone'
})
export class TelephonePipe implements PipeTransform {
   transform(base: string): string {

        return this.formatePhone(base)         
   }

   formatePhone(phone: string) {
    
        if (phone){
            let v = phone.toString()
            v=v.replace(/\D/g,"")                 //Remove tudo o que não é dígito
            v=v.replace(/^(\d\d)(\d)/g,"($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
            v=v.replace(/(\d{4})(\d)/,"$1-$2")    //Coloca hífen entre o quarto e o quinto dígitos
            return v
        } else
        {
            return phone
        }
   }
}
