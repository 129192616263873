import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-report-card',
  templateUrl: './app-report-card.component.html',
  styleUrls: ['./app-report-card.component.scss']
})
export class AppReportCardComponent implements OnInit {

  @Input() label: string
  @Input() value: string
  @Input() colorIcon: string

  constructor() { }

  ngOnInit() {
  }

}
