import { ModelService } from './../../../model/model.service';
import { OrderService } from './../../order.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModelComponent } from '../../../model/model.component'
import { Months, Years} from '../year.month.model'

@Component({
  selector: 'app-order-calendar',
  templateUrl: './order-calendar.component.html'
})
export class OrderCalendarComponent implements OnInit {

  @Input() models: ModelComponent[]
  @Input() type: string
  @Output() updateModels = new EventEmitter<ModelComponent[]>()
  @Input() daysOfMonth: number
  @Input() numberMonth: number
  @Input() numberYear: number

  @Output() updateDaysOfMonth = new EventEmitter()
  @Output() updateNumberMonth = new EventEmitter()
  @Output() updateNumberYear = new EventEmitter()


  dayOfWeek: string
  months = Months
  years = Years

  constructor( private orderService: OrderService, private modelService: ModelService) { }

  ngOnInit() {

    this.daysOfMonth = this.orderService.getDaysOfMonth(this.numberYear, this.numberMonth)
  }

  getDayOfWeek(day: number): string {

    const date = new Date(this.numberYear, this.numberMonth - 1, day)
    const weekday = new Array(7);
    weekday[0] = 'Dom';
    weekday[1] = 'Seg';
    weekday[2] = 'Ter';
    weekday[3] = 'Qua';
    weekday[4] = 'Qui';
    weekday[5] = 'Sex';
    weekday[6] = 'Sab';

    return weekday[date.getDay()]

  }

  onQuantity(model: ModelComponent) {

    model.days = this.modelService.onUpdateCalendar(model)
    model.discountValue = this.modelService.onGetDiscountValue(this.type, model)
    model.total = this.modelService.onGetModelTotal(this.type, model)
    this.updateModels.emit(this.models)
  }

  createTableHeader() {

    const items: number[] = [];
    for (let i = 1; i <= this.daysOfMonth; i++ )  {
       items.push(i);
    }
    return items;
  }

  createTableBody() {
    const items: number[] = [];
    for (let i = 1; i <= this.daysOfMonth; i++ )  {
       items.push(i);
    }
    return items;
  }

  placementMonth(valor) {

    this.numberMonth = valor
    this.addPlacementDate()
  }

  placementYear(valor) {

    this.numberYear = valor
    this.addPlacementDate()
  }

  addPlacementDate() {

    if (this.numberMonth > 0 && this.numberYear > 0) {

      this.daysOfMonth = this.orderService.getDaysOfMonth(this.numberYear, this.numberMonth)
      this.updateDaysOfMonth.emit(this.daysOfMonth)
      this.updateNumberMonth.emit(this.numberMonth)
      this.updateNumberYear.emit(this.numberYear)
    }

  }

}
