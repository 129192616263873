import { Component } from '@angular/core';
import { ModelComponent } from './model.component';
import { ModelService } from './model.service';
import { Channel } from '../channels/channel.model';
import { ChannelService } from '../channels/channel.service';
import { SupplierService } from '../suppliers/supplier.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../toaster-service.service';
import { Supplier } from '../suppliers/supplier.model';

@Component({
    selector: 'app-model-register',
    templateUrl: './model-register.component.html'
})

export class ModelRegisterComponent {

    model: ModelComponent = new ModelComponent();
    channels: Channel[];
    suppliers: Supplier[];
    userForm: FormGroup;
    service: ModelService;
    channelService: ChannelService;
    supplierService: SupplierService;
    route: ActivatedRoute;
    router: Router;

    constructor(service: ModelService, channelService: ChannelService, supplierService: SupplierService,
                fb: FormBuilder, route: ActivatedRoute, router: Router, private toasterService: ToasterService) {

        this.service = service;
        this.route = route;
        this.router = router;
        this.channelService = channelService;
        this.supplierService = supplierService;

        this.channelService
        .get()
        .subscribe(result => {
            this.channels = result;
            }, error => this.error(JSON.parse(error._body).map(m => m.message).join('<br>'))
        );

        this.supplierService
        .supplier()
        .subscribe(result => {
            this.suppliers = result;
            }, error => this.error(JSON.parse(error._body).map(m => m.message).join('<br>'))
        );
    }

    register() {

        this.service.register(this.model)
            .subscribe(() => {
                this.success();
                this.model = new ModelComponent();
                this.router.navigate(['models']);
            }, error => this.error(JSON.parse(error._body).map(m => m.message).join('<br>')));

    }

    changeChannel(id: string) {

        this.channelService.channelById(id)
        .subscribe((channel) => {
            this.model.type = '';
            if (channel.type !== 'Outros') {
                this.model.channelType = channel.type;
                this.model.type = channel.type;
            } else {
                this.model.channelType = channel.type;
            }
        });
    }

    success() {

        this.toasterService.success('', 'Peça cadastrada com sucesso!');
    }

    error(message?: string) {

        this.toasterService.error('', `Ops!</br> ${message}`);
    }
}