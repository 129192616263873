import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class GuardRouterChild implements CanActivateChild {

    constructor(private router: Router) {}

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        const token = localStorage.getItem('token')

        if (token) {
            return true;
        }

        this.router.navigate(['login']);
        return false;
    }
}
