export const navigation = [
  {
    name: 'Cadastros',
    url: '#',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Agências',
        url: '/agencies',
        icon: 'icon-docs',
        badge: { variant: 'info'}
      },
      {
        name: 'Clientes',
        url: '/customers',
        icon: 'icon-user-follow',
        badge: { variant: 'info'}
      },
      {
        name: 'Empresas',
        url: '/companies',
        icon: 'icon-notebook',
        badge: { variant: 'info'}
      },
      {
        name: 'Fornecedores',
        url: '/suppliers',
        icon: 'icon-people',
        badge: {variant: 'info'}
      },
      {
        name: 'Veículos',
        url: '/channels',
        icon: 'icon-screen-tablet',
        badge: { variant: 'info'}
      }
    ]
  },
  {
    divider: true
  },
  {
    title: true,
    name: 'Mídia',
    wrapper: {
      element: 'span'
    },
    class: 'text-center'
  },
  {
    name: 'PI - Pedidos de Inserção',
    url: '/orders',
    icon: 'icon-people',
    badge: {variant: 'info'}
  },
  {
    divider: true
  },
  {
    title: true,
    name: 'Administração',
    wrapper: {
      element: 'span'
    },
    class: 'text-center'
  },
  {
    name: 'Usuários',
    url: '/users',
    icon: 'icon-people',
    badge: {variant: 'info'}
  }
];
