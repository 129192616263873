<div class="row">
  <div class="form-group col-lg-12">                
    <app-input errorMessage="Campo obrigatório" label="(*) HubCom">
      <select name="company" required [ngModel]="company?._id" (ngModelChange)="change($event)" class="form-control input-lg" size="1">
        <option [ngValue]="''">Selecione</option>
        <option *ngFor="let company of companies" [ngValue]="company?._id">{{company?.socialName}}</option>
      </select>
    </app-input>
  </div>
</div>

<div class="row">  
  <div class="form-group col-lg-6">
    <app-input errorMessage="Campo obrigatório" label="Nome fantasia">                
      <input type="text" class="form-control" name="fantasyName" [ngModel]="company?.fantasyName" autocomplete="off" [disabled]="true">              
    </app-input>
  </div>
  <div class="form-group col-lg-6">                
    <app-input errorMessage="Campo obrigatório" label="CNPJ">
      <input type="text" class="form-control" name="cnpj" [ngModel]="company?.cnpj" autocomplete="off" placeholder="digite o cnpj" [disabled]="true" >              
    </app-input>
  </div>
</div>

<div class="row">
  <div class="form-group col-md-12">
    <label class="form-check-label" style="padding-left:20px">                
      <app-input errorMessage="" label="">
          <input type="checkbox" class="form-check-input" id="billedCompany" name="billedCompany" [ngModel]="billedCompany" (ngModelChange)="changeBilledCompany($event)" />
          Faturar pela empresa
      </app-input>
    </label>
  </div>
</div>