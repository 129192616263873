<div class="row">
    <div class="col-lg-10 col-md-10">
      <div class="card">
          <div class="card-body">
              <nav aria-label="breadcrumb" role="navigation">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink] = "['/users']">Usuários</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Cadastrar</li>
                  </ol>
                </nav>
          </div>
          
          <div class="card-header">
            <strong>Usuário</strong>
            <small>Cadastro</small>
          </div>

        <div class="card-body">

          <form novalidate #form="ngForm"class="row" (submit)="register()" class="form-horizontal">
          
            <div class="row">
              <div class="form-group col-sm-6">
                  <app-input errorMessage="Nome inválido." label="Nome completo" > 
                    <input type="text" name="name" [(ngModel)]="user.name" class="form-control" id="name" class="form-control" placeholder="Preencha o nome completo." required minlength="3" maxlength="256" >
                  </app-input>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-sm-6">
                <app-input errorMessage="E-mail inválido." label="E-mail" > 
                  <input type="text" name="email" [(ngModel)]="user.email" id="email" class="form-control" size="16" placeholder="Ex: nome@email.com.br" required minlength="3" maxlength="256">
                </app-input>
              </div>
            </div>
            
            <div class="row">
              <div class=" form-group col-sm-6">
                <app-input errorMessage="Perfil inválido." label="Perfil" > 
                  <select  name="role" [(ngModel)]="user.role" class="col-md-6 form-control" required id="role" name="role">
                      <option value="Padrão">Padrão</option>
                      <option value="Administrador">Administrador</option>
                  </select>
                </app-input>
              </div>        
            </div>

            <div class="row">
              <div class="form-group col-sm-6">
                <app-input errorMessage="Senha inválida." label="Senha" > 
                  <input name="password" [(ngModel)]="user.password" id="password" class="col-md-6 form-control" size="16" type="password" placeholder="Preencha a senha." required minlength="6" maxlength="12">
                </app-input>
              </div>
            </div>
            
            <div class="row">
              <div class="form-group col-sm-6">
                <app-input errorMessage="Confirmação de senha inválida." label="Confirmação de senha"  > 
                  <input type="password" [(ngModel)]="user.confirmPassword" name="confirmPassword" id="confirmPassword" class="col-md-6 form-control" size="16" placeholder="Confirme a senha." required minlength="6" maxlength="12">
                </app-input>
              </div>
            </div>

          <div class="form-actions">
            <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Salvar</button>
            <a [routerLink]="['', 'users']" class="btn btn-default">Voltar</a>
          </div>
        </form>
        </div>
      </div>
    </div>
  </div>