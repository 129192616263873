<div class="row">
  <div class="form-group col-lg-12">                
    <app-input errorMessage="Campo obrigatório" label="(*) Selecione o cliente">
      <select name="customer" required [ngModel]="customer?._id" (ngModelChange)="change($event)" class="form-control input-lg" size="1">
        <option [ngValue]="''">Selecione</option>
        <option *ngFor="let customer of customers" [ngValue]="customer?._id">{{customer.socialName}}</option>
      </select>
    </app-input>
  </div>
</div>
<div class="row">
  <div class="form-group col-lg-3">                
    <app-input errorMessage="Campo obrigatório" label="Nome Fantasia">
      <input type="text" class="form-control" name="fantasyName" [ngModel]="customer?.fantasyName" autocomplete="off" [disabled]="true">              
    </app-input>      
  </div>
  <div class="form-group col-lg-3">  
    <app-input errorMessage="Campo obrigatório" label="Documento">              
      <input type="text" class="form-control" name="document" [ngModel]="customer?.document" autocomplete="off" placeholder="digite o cnpj" [disabled]="true" >              
    </app-input>
  </div>
  <div class="form-group col-lg-3">                
    <app-input errorMessage="Campo obrigatório" label="Inscrição Estadual">
      <input type="text" class="form-control" name="ie" [ngModel]="customer?.stateRegistration" autocomplete="off" placeholder="Isento" [disabled]="true">             
    </app-input>
  </div>
  <div class="form-group col-lg-3">
    <app-input errorMessage="Campo obrigatório" label="Cidade">
      <input type="text" class="form-control" name="city" [ngModel]="customer?.city" autocomplete="off" placeholder="Cidade" [disabled]="true">              
    </app-input>
  </div>
</div>