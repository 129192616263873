<header class="app-header navbar">
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler d-md-down-none h-100 b-r-1 px-3" type="button" appSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>
  <!-- <ul class="nav navbar-nav d-md-down-none">
    <form class="form-inline px-4">
      <i class="fa fa-search"></i>
      <input class="form-control" type="text" placeholder="Buscar...">
    </form>
  </ul> -->
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link " data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <!-- <img src="assets/img/avatars/default.png" class="img-avatar" alt="admin@admin.com"/> -->
        <!-- <span class="navbar-toggler-icon"></span> -->
        <p class="mr-5">{{user?.name}}</p>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Opções</strong></div>
        <button class="dropdown-item" (click)="logout()"><i class="fa fa-lock"></i> Logout</button>
      </div>
    </li>

  </ul>
</header>
