import { Component, OnInit, Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { ToasterService } from '../../toaster-service.service';
import { Supplier } from '../supplier.model';
import { SupplierService} from '../supplier.service';
import { SelectModel } from '../../shared/select-uf/select.model';

import { ZipCodeService } from '../../shared/app-service-zipcode/zip-code.service'

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html'
})

@Injectable()
export class SupplierComponent implements OnInit, OnDestroy {

  ngOnDestroy(): void {
    this.debounce.unsubscribe();
  }

  debounce: Subject<string> = new Subject<string>()

  supplier: Supplier
  actionOption: string
  statusSupplier: any[]
  ufs: any[]

  constructor(  private supplierService: SupplierService,
                private router: Router,
                private activeRouter: ActivatedRoute,
                private toasterService: ToasterService,
                private zipCodeService: ZipCodeService) { }

  ngOnInit() {

    this.supplier= { _id:null, socialName:null, fantasyName:null, 
      document:null, stateRegistration:null, email:null, address:null,
      number:null, complement:null, neighborhood:null, city:null, state:null,
      zipcode:null, phone:null, cellphone: null, createDate:null, active:null }

    this.statusSupplier = [
      {value: 'false', label: 'Inativo' },
      {value: 'true', label: 'Ativo'}
    ]

    // carrega as ufs
    this.ufs = SelectModel

    this.actionOption = 'create';

    if (this.activeRouter.snapshot.params['id'] !== undefined) {
      this.supplierService.supplierById(this.activeRouter.snapshot.params['id'])
        .subscribe(supplier => {
          this.getSupplier(supplier)
          }, error => {
            this.error(error._body.map(m => m.message))
            console.log(error)
            this.router.navigate(['/suppliers'])
          })
      }

    this.debounce
      .pipe(debounceTime(500))
      .subscribe( zipCode =>  {
        if(zipCode !== '' && zipCode.length > 8) {
          this.zipCodeService.findZipCode(zipCode)
            .subscribe((result) => {
              this.supplier.address = result.logradouro;
              this.supplier.complement = result.complemento;
              this.supplier.neighborhood = result.bairro;
              this.supplier.city = result.localidade;
              this.supplier.state = result.uf;
            }, error => console.error(error))
      }
    }, error => console.error(error))

  }

  success(message: string) {
    this.toasterService.success(message);
  }

  error(message?: string) {
    this.toasterService.error(`Ops!</br> ${message}`);
  }

  info() {
    this.toasterService.info('Empresa cadastrada com sucesso!')
  }

  warning() {
    this.toasterService.warning('Empresa', 'Ocorreram problemas.')
  }

  createSupplier(formValue: Supplier) {
    this.supplierService.create(formValue)
      .subscribe((msg: any) => {
        this.success('Cadastro realizado com sucesso!')
        this.router.navigate(['/suppliers'])
      }, error => {

        this.error(JSON.parse(error._body).map(m => m.message).join('<br>'))
      }
    )
  }

  updateSupplier() {
    this.supplierService.update(this.supplier)
      .subscribe((msg: any) => {
        this.success('Cadastro atualizado com sucesso!')
        this.router.navigate(['/suppliers'])
      }, error => {
        this.error(JSON.parse(error._body).map(m => m.message).join('<br>'))
      }
    )
  }

  getSupplier(supplier: Supplier) {
    this.actionOption = 'edit';
    this.supplier = supplier
    this.supplier.zipcode = ('00000000' + this.supplier.zipcode).slice(-8)
  }

  changeStatus($event) {

    if ($event === 'false') {
      this.supplier.active = false
    } else {
      this.supplier.active = true
    }

  }

  changeUf($event) {
    this.supplier.state = $event
  }

  onBlurMethod() {

    if (this.supplier.document.length > 0) {
      // retira a formatação
      this.supplier.document = this.supplier.document.replace(/(\.|\/|\-)/g, '')

    if (this.supplier.document.length <= 11) {
      this.supplier.document = this.supplier.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4')
    } else {
      this.supplier.document = this.supplier.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')
    }

    }
  }

// tslint:disable-next-line:eofline


}
