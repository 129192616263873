import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

import { LocationStrategy, HashLocationStrategy, DatePipe} from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { CurrencyMaskModule } from "ng2-currency-mask";

//import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

registerLocaleData(ptBr)



// Import containers
import {
  FullLayoutComponent
} from './containers';

const APP_CONTAINERS = [
  FullLayoutComponent
]

// Import components
import {
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV
} from './shared';

const APP_COMPONENTS = [
  AppAsideComponent,
  AppBreadcrumbsComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppSidebarComponent,
  AppSidebarFooterComponent,
  AppSidebarFormComponent,
  AppSidebarHeaderComponent,
  AppSidebarMinimizerComponent,
  APP_SIDEBAR_NAV
]

// Import directives
import {
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
} from './directives';

const APP_DIRECTIVES = [
  AsideToggleDirective,
  NAV_DROPDOWN_DIRECTIVES,
  ReplaceDirective,
  SIDEBAR_TOGGLE_DIRECTIVES
]

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DataTableModule } from '@pascalhonegger/ng-datatable';

  
// Import components
import { CompaniesComponent } from './companies/companies.component';
import { CompanyComponent } from './companies/company/company.component';
import { ChannelsComponent } from './channels/channels.component';
import { ChannelComponent } from './channels/channel/channel.component';
import { UserComponent } from './user/user.component';
import { UserListComponent } from './user/user-list.component';
import { UserRegisterComponent } from './user/user-register.component';
import { UserEditComponent } from './user/user-edit.component';
import { UserChangePassComponent } from './user/user-change-pass.component';
import { AppFilterPipe } from './shared/app-filter/app-filter.pipe';
import { ModelComponent } from './model/model.component';
import { ModelListComponent } from './model/model-list.component';
import { ModelRegisterComponent } from './model/model-register.component';
import { ModelEditComponent } from './model/model-edit.component';
import { CustomersComponent } from './customers/customers.component';
import { CustomerComponent } from './customers/customer/customer.component';
import { AgenciesComponent } from './agencies/agencies.component';
import { AgencyComponent } from './agencies/agency/agency.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { InputComponent } from './shared/input/input.component';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SupplierComponent } from './suppliers/supplier/supplier.component';
import { SelectComponent } from './shared/select-uf/select.component';
import { ConfirmResetPasswordComponent } from './confirm-reset-password/confirm-reset-password.component';
import { OrderComponent } from './orders/order/order.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderModelComponent } from './orders/order/order-model/order-model.component';
import { SimpleLayoutComponent } from './containers/simple-layout/simple-layout.component';


// Import services
import { CompanyService } from './companies/company.service';
import { ToasterService } from './toaster-service.service';
import { UserService } from './user/user.service';
import { ModelService } from './model/model.service';
import { ChannelService } from './channels/channel.service';
import { CustomerService } from './customers/customer.service';
import { AgencyService } from './agencies/agency.service';
import { SupplierService } from './suppliers/supplier.service';
import { LoginService } from './login/login.service';
import { GuardRouter } from './guards/guard.router';
import { GuardRouterChild } from './guards/guard.router.child';
import { GuardRouterConfirmedPassword } from './guards/guard.router.confirmedPassword';
import { ResetPasswordService } from './reset-password/reset-password.service';
import { ConfirmResetPassword } from './confirm-reset-password/confirm-reset-password.service';
import { BreadcrumbComponent } from './shared/app-breadcrumbs/breadcrumb/breadcrumb.component';
import { OrderService } from './orders/order.service';
import { OrderAgencyComponent } from './orders/order/order-agency/order-agency.component';
import { OrderCalendarComponent } from './orders/order/order-calendar/order-calendar.component';
import { OrderChannelComponent } from './orders/order/order-channel/order-channel.component';
import { OrderChannelService } from './orders/order/order-channel/order-channel.service';
import { OrderCompanyComponent } from './orders/order/order-company/order-company.component';
import { OrderCustomerComponent } from './orders/order/order-customer/order-customer.component';
import { OrderModelCreateComponent } from './orders/order/order-model-create/order-model-create.component';
import { ZipCodeService } from './shared/app-service-zipcode/zip-code.service'

import { OrdersResolver } from './orders/order-resolver';
import { AuthInterceptor } from './guards/auth.interceptor';

import { TelephonePipe }  from './pipe/telephone.pipe';
import { CnpjCpfPipe} from './pipe/cnpjcpf.pipe';

import { ApplicationErrorHandler } from './app.error-handle';
import { AppFilterSearchComponent } from './shared/app-filter-search/app-filter-search.component';
import { AppReportCardComponent } from './shared/app-report-card/app-report-card.component';
import { InputZipCodeComponent } from './shared/input-zip-code/input-zip-code.component'


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    HttpClientModule,
    FormsModule,
    CurrencyMaskModule,
    ReactiveFormsModule,
    DataTableModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    ...APP_COMPONENTS,
    ...APP_DIRECTIVES,
    CompaniesComponent,
    CompanyComponent,
    InputComponent,
    UserComponent,
    UserListComponent,
    UserRegisterComponent,
    UserEditComponent,
    UserChangePassComponent,
    AppFilterPipe,
    ModelListComponent,
    ModelRegisterComponent,
    ModelEditComponent,
    ChannelsComponent,
    ChannelComponent,
    CustomersComponent,
    CustomerComponent,
    AgenciesComponent,
    AgencyComponent,
    SuppliersComponent,
    SupplierComponent,
    SelectComponent,
    LoginComponent,
    ResetPasswordComponent,
    ConfirmResetPasswordComponent,
    BreadcrumbComponent,
    OrderComponent,
    OrdersComponent,
    OrderModelComponent,
    OrderAgencyComponent,
    OrderCalendarComponent,
    OrderChannelComponent,
    OrderCompanyComponent,
    OrderCustomerComponent,
    OrderModelCreateComponent,
    ModelComponent,
    SimpleLayoutComponent,
    TelephonePipe,
    CnpjCpfPipe,
    AppFilterSearchComponent,
    AppReportCardComponent,
    InputZipCodeComponent
  ],
  providers: [GuardRouterConfirmedPassword,
              ResetPasswordService,
              GuardRouterChild,
              GuardRouter,
              SupplierService,
              AgencyService,
              CustomerService,
              ChannelService,
              UserService,
              CompanyService,
              ModelService,
              ToasterService,
              LoginService,
              ConfirmResetPassword,
              DatePipe,
              OrderService,
              OrderChannelService,
              ZipCodeService,
            { provide: LocationStrategy, useClass: HashLocationStrategy },
            { provide: LOCALE_ID, useValue: 'pt-PT' },
            { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
            { provide: ErrorHandler, useClass: ApplicationErrorHandler},
            OrdersResolver
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {}
