import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { CustomerService } from './../../../customers/customer.service';
import { Customer } from './../../../customers/customer.model';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-order-customer',
  templateUrl: './order-customer.component.html'
})
export class OrderCustomerComponent implements OnChanges {

  customers: Customer[]
  customer: Customer
  @Output() updateCustomer = new EventEmitter<Customer>()
  @Input() customerId: string

  constructor(private customerSerivce: CustomerService) { }

  ngOnChanges(change: SimpleChanges) {

    this.customerSerivce
      .customer()
      .subscribe(customers => {

        this.customers = customers
        if ( change.customerId.currentValue) {
          this.getCustomerById(this.customerId)
        }

      })

  }

  change(id: string) {

    this.getCustomerById(id)
  }

  getCustomerById(id: string) {

    this.customers
      .filter(customer => customer._id === id)
      .map( (customer) => {
        this.customer = customer
        this.updateCustomer.emit(customer)
      })
  }

}
